// ** Redux Imports
// import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import { http } from "common/api/http";

export const fetchPosition = async (id: string) => {
  const response = await http.get(`/positions/${id}`);
  return response;
};

export const fetchPositionApplicationPageContent = async (id: string) => {
  const response = await http.get(`/positions/${id}/public`);
  return response;
};

export const fetchPositionContents = async (id: string) => {
  const response = await http.get(`/tests/${id}/contents`);
  return response;
};

export const fetchTestOptions = async () => {
  const response = await http.get(`/tests?limit=100&page=1&state=published`);
  return response;
};

export const duplicateTest = async (id: string) => {
  const response = await http.post(`/tests/${id}/duplicate`);
  return response;
};

export const getTestDemoQuestions = async (testId: string) => {
  const response = await http.get(`/tests/${testId}/questions?isDemo=true`);
  return response;
};
