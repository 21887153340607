import { type FC, lazy } from "react";
import { lazyRetry } from "common/utility/Utils";
import { type routeType } from "router/routes/types";

const PositionSimulationApplicationWrapper: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () =>
        await import(
          "../../../application/wrappers/PositionSimulationApplicationWrapper/PositionSimulationApplicationWrapper"
        )
    )
);
const ApplicationModulePreview: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () =>
        await import(
          "views/features/position/moduleSpecificComponents/ApplicationModulePreview/ApplicationModulePreview"
        )
    )
);

const companyApplicationRoutes: routeType[] = [
  {
    path: ":companyId/position/:id/simulation",
    element: <PositionSimulationApplicationWrapper />,
    meta: {
      layout: "blank",
      type: "page",
      public: true,
    },
    children: [
      {
        path: "apply/edit",
        element: <ApplicationModulePreview editable={true} preview={true} />,
        permissions: "UPDATE_POSITION",
        meta: {
          title: "Application Content Edit",
          layout: "blank",
          type: "page",
          private: true,
        },
      },
      {
        path: "apply/preview",
        element: <ApplicationModulePreview editable={false} preview={true} />,
        meta: {
          title: "Job Post Preview",
          layout: "blank",
          type: "page",
          private: true,
        },
      },
    ],
  },
];
export default companyApplicationRoutes;
