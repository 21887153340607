import { lazy, type FC } from "react";
import { lazyRetry } from "common/utility/Utils";
import { type routeType } from "router/routes/types";

const ApplicantsWrapper: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () => await import("../../applicants/wrappers/ApplicantsWrapper")
    )
);

const ApplicantsTable: FC | any = lazy(
  async () =>
    await lazyRetry(async () => await import("../pages/ApplicantsTable"))
);

const applicantRoutes: routeType[] = [
  {
    path: "/applicants",
    element: <ApplicantsWrapper />,
    permissions: "GET_APPLICANTS",
    meta: {
      layout: "app",
      title: "Applicants",
      type: "page",
      private: true,
      roles: ["zenhire"],
    },
    children: [
      {
        path: "",
        element: <ApplicantsTable />,
        meta: {
          title: "Applicants",
          type: "component",
          private: true,
        },
      },
    ],
  },
];

export default applicantRoutes;
