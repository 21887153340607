import { getInitials, renderFileUrl } from "common/helpers/functions.helpers";
import { ImagePreview } from "../FilePreview/ImagePreview";
import {useAppSelector} from "../../../redux/reduxTypes";

const Avatar = ({
  content,
  avatar,
  size,
  containerClass,
  imageClass,
}: {
  content?: string;
  avatar?: string;
  size?: number;
  containerClass?: string;
  imageClass?: string;
}) => {

  const companyOverview = useAppSelector(
      (state) => state.company.companyOverview?.value
  );

  return (
    <div
      className={`flex items-center justify-center rounded-full text-white ${!avatar ? "bg-main-color" : "bg-white"} ${containerClass}`}
      style={{
        width: size ? `${size}px` : "42px",
        height: size ? `${size}px` : "42px",
      }}
    >
      {avatar ? (
        <ImagePreview
          src={renderFileUrl(avatar)}
          alt={"ico"}
          className={`rounded-full object-cover select-none ${
            imageClass ? imageClass : ""
          }`}
          style={{
            width: size ? `${size}px` : "42px",
            height: size ? `${size}px` : "42px",
          }}
        />
      ) : content ? (
        <p
          style={{
            fontSize: size ? `${size / 2.5}px` : "21px",
          }}
          className="select-none mt-[2px]"
        >
          {getInitials(content)}
        </p>
      ) : (
        ""
      )}
    </div>
  );
};

export default Avatar;
