import {type FC, lazy, ReactNode} from "react";
import { lazyRetry } from "common/utility/Utils";
import { type routeType } from "router/routes/types";
const UsersWrapper: FC | any = lazy(
    async () =>
        await lazyRetry(
            async () => await import("../../../users/wrappers/UsersWrapper/UsersWrapper")
        )
);
const Users: FC | any = lazy(
    async () => await lazyRetry(async () => await import("../../../users/pages/Users/Users"))
);

const UsersWizard: FC | any = lazy(
    async () =>
        await lazyRetry(
            async () => await import("../../../users/pages/UsersWizard/UsersWizard")
        )
);

const companyTeamRoutes: routeType[] = [
  {
    path: ":companyId/team",
    element: <UsersWrapper />,
    permissions: "GET_USERS",
    meta: {
      layout: "app",
      type: "page",
      private: true,
    },
    children: [
      {
        path: "",
        element: <Users state={""} type={"client"} />,
        meta: {
          title: "Our Team",
          type: "component",
          private: true,
        },
      }
    ],
  },
  {
    path: ":companyId/add-user",
    element: <UsersWizard />,
    permissions: "CREATE_USER",
    meta: {
      layout: "app",
      title: "Users Wizard",
      type: "page",
      private: true,
    },
  },
];
export default companyTeamRoutes;
