import { createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import { http } from "common/api/http";
import { setToast } from "redux/alert";
import { createUserType } from "../users.types";
import { RootState } from "redux/store";
import { fetchUsers } from "./users.services";
import { errorRequestType } from "common/types/data.types";
import {getPositionOverview} from "../../position/reducer/position.actions";

export const getUsers = createAsyncThunk<
  any,
  { page: number; state: string; limit: number, companyId?: string,location?:string,filters?:any,search?:any },
  { state: RootState }
>("users/getUsers", async ({ page, state, limit, companyId,filters,search }, { getState }) => {
  try {

    const params = {
      ...(filters?.role && {
        role: filters.role.value,
      }),
      ...(filters?.status && {
        status: filters.status.value.toUpperCase(),
      }),
      ...(search && {
        search: search,
      }),
      ...(filters?.sort && {
        sort: filters.sort,
      }),
      ...(filters?.permissions && {
        permissions: `${filters.permissions.map((item:{enum:string}) => item.enum.toUpperCase())}`,
      }),
      ...(filters?.company && {
        company: filters.company.value,
      }),
    }

    const response = await fetchUsers({
      page: page,
      state: state,
      ...params,
      limit: limit,
      ...(companyId && {
        company:companyId
      })
    });
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
});
export const createAdminUser = createAsyncThunk<
  any,
  createUserType,
  {
    rejectValue: errorRequestType;
  }
>(
  "users/createAdminUser",
  async (
    { name, middleName, surname, email, password,permissions,role,company }: createUserType,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await http.post("/users", {
        name,
        middleName,
        surname,
        email,
        password,
        permissions,
        role: {name:role},
        ...(company && {
          company: company
        })
      });
      dispatch(
        setToast({
          message:
            "Successfully created a new user. A verification email will be sent to their email address",
          type: "success",
        })
      );
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        isError: true,
        message: err.data.message,
        code: "invalid",
      } as errorRequestType);
    }
  }
);

type typeActivateDeactivate = {
  id: string,
  type: string
}
export const activateDeactivateUser = createAsyncThunk<
    any,
    typeActivateDeactivate,
    {
      rejectValue: errorRequestType;
      state: RootState
    }
      >(
    "users/activateDeactivateUser",
    async ({id,type}, { dispatch,rejectWithValue,getState }) => {
      try {
        const response = await http.patch(`/users/${id}/${type}`);
        dispatch(
            setToast({
              message: `User successfully ${type}d.`,
              type: "success",
            })
        );

        const values = getState().users.usersList.values?.data;
        return  values?.map(user =>
            user._id === response.data._id ? { ...user, userStatus: type === "activate" ? "ACTIVE" : "DEACTIVED"} : user
        );
      } catch (err: any) {
        dispatch(
            setToast({
              type: "error",
              message: "An error occured. Please try again later.",
            })
        );
        return rejectWithValue({
          isError: true,
          message: err.data.message,
          code: "invalid",
        } as errorRequestType);
      }
    }
);

type typeUpdateUser = {
  email: string,
  permissions: string[]
}
export const updateUserPermissions = createAsyncThunk<
    any,
    typeUpdateUser,
    {
      rejectValue: errorRequestType;
      state: RootState
    }
>(
    "users/updateUser",
    async ({email,permissions}, { dispatch,rejectWithValue,getState }) => {
      try {
        const response:any = await http.patch(`/users/admins/permissions`,{email,permissions});
        dispatch(
            setToast({
              message: `User successfully updated`,
              type: "success",
            })
        );
        const values = getState().users.usersList.values?.data;

        return  values?.map(user =>
           user._id === response.data._id ? { ...user, permissions: response.data.permissions} : user
        );
      } catch (err: any) {
        dispatch(
            setToast({
              type: "error",
              message: "An error occured. Please try again later.",
            })
        );
        return rejectWithValue({
          isError: true,
          message: err.data.message,
          code: "invalid",
        } as errorRequestType);
      }
    }
);