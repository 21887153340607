import { http } from "common/api/http";
import { usersParamsType } from "../users.types";
export async function fetchUsers({
  page,
  state, // state = "" (all) | "clients" | "admins" <- passed thru router
  search,
  limit,
  sort,
  role, // role = "" (all) | "clients" | "admins" <- same as state, but passed thru filters. (role === true IF state === false)
  status,
  company,
  companyId,
  permissions
}: usersParamsType) {

  const params = {
    limit: limit ? limit : 10,
    page: page ? page : 1,
    ...(state && {
      roleType: state,
    }),
    ...(search && {
      search: search,
    }),
    ...(sort && {
      sort: sort,
    }),
    ...(role && !state && {
      role: role,
    }),
    ...(status && {
      status: status,
    }),
    ...(company && {
      company: company,
    }),
    ...(companyId && {
      companyId: companyId
    }),
    ...(permissions && {
      permissions: permissions
    })
  }

  return await http.get(
    `/users`, {params}
  );
}
