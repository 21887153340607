import { createAsyncThunk } from "@reduxjs/toolkit";
import { publicHttp } from "common/api/public-http";
import { testCandidateHttp } from "common/api/testCandidate-http";
import { errorRequestType } from "common/types/data.types";
import { setToast } from "redux/alert";
import { setCandidateValue, setIsTestDemo } from "./application.reducer";
import { AppDispatch, RootState } from "redux/store";
import { storeTestCandidateKey } from "common/api/token";
import { fetchPositionApplicationPageContent } from "./application.services";
import { http } from "common/api/http";
import {
  formatedAnswerType,
  qualifyingQuestionsAnswersType,
} from "../application.types";
import { checkIsFinished } from "../common/application.functions";

export const getTestSimulationInfo = createAsyncThunk<
  any,
  { id: string; isDemo?: boolean },
  {
    rejectValue: errorRequestType;
  }
>(
  "application/getTestSimulationInfo",
  async ({ id, isDemo }, { rejectWithValue, dispatch }) => {
    try {
      const response = await publicHttp.get(`/tests/${id}/simulation/overview`);
      dispatch(setIsTestDemo(isDemo || false));
      return response.data;
    } catch (err: any) {
      console.log("error", err);
      return rejectWithValue({
        isError: true,
        message: err.data.message,
        code: "invalid",
      } as errorRequestType);
    }
  }
);

export const getPositionSimulationInfo = createAsyncThunk<
  any,
  { id: string; isDemo?: boolean },
  {
    rejectValue: errorRequestType;
  }
>(
  "application/getPositionSimulationInfo",
  async ({ id, isDemo }, { rejectWithValue, dispatch }) => {
    try {
      const response = await fetchPositionApplicationPageContent(id);
      return response.data;
    } catch (err: any) {
      console.log("error", err);
      return rejectWithValue({
        isError: true,
        message: err.data.message,
        code: "invalid",
      } as errorRequestType);
    }
  }
);

export const updatePositionSimulationInfo = createAsyncThunk(
  "application/updatePositionSimulationInfo",
  async (
    {
      id,
      positionBanner,
      recruitingProcessDescription,
      offeringDescription,
      lookingForDescription,
    }: {
      id: string;
      positionBanner?: string;
      recruitingProcessDescription?: string;
      offeringDescription?: string;
      lookingForDescription?: string;
    },
    { dispatch }
  ) => {
    try {
      const response = await http.patch(`/positions/${id}`, {
        positionBanner,
        recruitingProcessDescription,
        offeringDescription,
        lookingForDescription,
      });
      dispatch(
        setToast({
          message: "Successfully updated the position.",
          type: "success",
        })
      );
      const testSingle = await fetchPositionApplicationPageContent(id);
      return testSingle.data;
    } catch (err: any) {
      dispatch(
        setToast({
          message: "Error updating the positon. Please try again later.",
          type: "error",
        })
      );
      return err.response.data;
    }
  }
);

export const submitApplication = createAsyncThunk<
  { applicant: any; id: string },
  {
    id: string;
    name: string;
    surname: string;
    middleName?: string;
    email: string;
    phoneNumber?: string;
    shouldSendResultToEmail?: boolean;
    acceptedPrivacyPolicy: boolean;
    nativeSpeakingLanguage: string;
    qualifyingQuestionAnswers?: formatedAnswerType[];
  },
  {
    dispatch: AppDispatch;
    rejectValue: errorRequestType;
  }
>(
  "application/submitApplication",
  async (
    {
      id,
      name,
      surname,
      middleName,
      email,
      phoneNumber,
      shouldSendResultToEmail,
      acceptedPrivacyPolicy,
      nativeSpeakingLanguage,
      qualifyingQuestionAnswers,
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = (await publicHttp.post(`/application/test/${id}`, {
        name,
        surname,
        middleName,
        email,
        phoneNumber,
        shouldSendResultToEmail,
        acceptedPrivacyPolicy,
        nativeSpeakingLanguage,
        qualifyingQuestionAnswers,
      })) as { data: { applicant: any; id: string } };

      dispatch(setCandidateValue(response.data.applicant));
      localStorage.setItem("lastApplicationId", id);

      return response.data as { applicant: any; id: string };
    } catch (err: any) {
      console.log("error", err);
      return rejectWithValue({
        isError: true,
        message: err.data.message,
        code: "invalid",
      } as errorRequestType);
    }
  }
);

export const submitPositionApplication = createAsyncThunk<
  { applicant: any; id: string; alreadyApplied?: boolean },
  {
    id: string;
    name: string;
    surname: string;
    middleName?: string;
    email: string;
    CV: string;
    coverLetter?: string;
    coverLetterType?: string;
    phoneNumber?: string;
    emailNotifications: boolean;
    acceptedPrivacyPolicy: boolean;
    qualifyingQuestionAnswers?: formatedAnswerType[];
    source?: string;
  },
  {
    dispatch: AppDispatch;
    rejectValue: errorRequestType;
  }
>(
  "application/submitPositionApplication",
  async (
    {
      id,
      name,
      surname,
      middleName,
      email,
      CV,
      coverLetter,
      phoneNumber,
      emailNotifications,
      acceptedPrivacyPolicy,
      qualifyingQuestionAnswers,
      coverLetterType,
      source,
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      // console.log({
      //   name,
      //   surname,
      //   middleName,
      //   email,
      //   CV,
      //   phoneNumber,
      //   emailNotifications,
      //   acceptedPrivacyPolicy,
      //   qualifyingQuestionAnswers,
      //     coverLetter
      // });

      const response = (await publicHttp.post(`/application/position/${id}`, {
        name,
        surname,
        middleName,
        email,
        CV,
        ...(coverLetter && {
          coverLetter: coverLetter,
        }),
        ...(coverLetterType && {
          coverLetterType: coverLetterType,
        }),
        phoneNumber,
        emailNotifications,
        acceptedPrivacyPolicy,
        qualifyingQuestionAnswers,
        ...(source && {
          source,
        }),
      })) as { data: { applicant: any; id: string; alreadyApplied?: boolean } };

      dispatch(setCandidateValue(response.data.applicant));
      // localStorage.setItem("lastApplicationId", id);
      dispatch(
        setToast({
          message: "Application successfully sent.",
          type: "success",
        })
      );
      return response.data as { applicant: any; id: string };
    } catch (err: any) {
      console.log("error", err);
      return rejectWithValue({
        isError: true,
        message: err.data.message,
        code: "invalid",
      } as errorRequestType);
    }
  }
);

export const getTestApplicant = createAsyncThunk<
  any,
  { id: string },
  {
    rejectValue: errorRequestType;
  }
>("application/getTestApplicant", async ({ id }, { rejectWithValue }) => {
  try {
    const response = await testCandidateHttp.get(
      `/application/${id}/applicant`
    );
    return response.data;
  } catch (err: any) {
    console.log("error", err);
    return rejectWithValue({
      isError: true,
      message: err.data.message,
      code: "invalid",
    } as errorRequestType);
  }
});

export const getApplication = createAsyncThunk<
  any,
  { id?: string | undefined },
  {
    rejectValue: errorRequestType;
  }
>(
  "application/getApplication",
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      const response = await testCandidateHttp.get(
        id ? `/application/${id}` : `/application`
      );

      if (response.data._id) {
        dispatch(
          getTestApplicant({
            id: response.data._id,
          })
        );
        if (!checkIsFinished(response.data?.hiringFlow?.state)) {
          dispatch(getActiveModule({}));
        }
      }
      return response.data;
    } catch (err: any) {
      console.log("error", err);
      return rejectWithValue({
        isError: true,
        message: err.data.message,
        code: "invalid",
      } as errorRequestType);
    }
  }
);

export const verifyApplication = createAsyncThunk<
  any,
  {
    verificationToken: string;
    applicationId: string;
  },
  {
    rejectValue: errorRequestType;
  }
>(
  "application/verifyApplication",
  async (
    { verificationToken, applicationId },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = (await publicHttp.post(
        `/application/${applicationId}/email-verify`,
        {
          verificationToken,
        }
      )) as { data: any };

      if (!response.data.alreadyVerified) {
        dispatch(
          setToast({
            message: "E-mail verification success.",
            type: "success",
          })
        );
      }
      storeTestCandidateKey({ access_token: response.data.access_token });

      dispatch(
        getApplication({
          id: applicationId,
        })
      );
      return response.data;
    } catch (err: any) {
      dispatch(
        setToast({
          message: "Error verifying email.",
          type: "error",
        })
      );
      return rejectWithValue({
        isError: true,
        message: err.data.message,
        code: "invalid",
      } as errorRequestType);
    }
  }
);

export const getActiveModule = createAsyncThunk<
  any,
  {
    id?: string;
  },
  {
    rejectValue: errorRequestType;
  }
>("application/getActiveModule", async ({ id }, { rejectWithValue }) => {
  try {
    const response = await testCandidateHttp.get(
      id ? `/application/step/${id}` : `/application/step`
    );
    return {
      ...response.data,
      //  ...response.data.userData.tests?{userData: {
      //     ...response.data.userData,
      //     tests: response.data.userData.tests.filter(
      //       (v: any) =>
      //         v.appliedState !== "COMPLETED" && v.appliedState !== "UNSCORED"
      //     ),
      //   }}:{}
    };
  } catch (err: any) {
    return rejectWithValue({
      isError: true,
      message: err.data.message,
      code: "invalid",
    } as errorRequestType);
  }
});

export const getTestSimulationResults = createAsyncThunk<
  any,
  {
    applicationId?: string;
    hiringFlowId?: string;
    stepId?: string;
    testId: string;
  },
  {
    rejectValue: errorRequestType;
    state: RootState;
  }
>(
  "testSimulation/getTestSimulationResults",
  async (
    { applicationId, hiringFlowId, stepId, testId },
    { rejectWithValue, getState }
  ) => {
    try {
      const response = await publicHttp.get(
        `/application/${applicationId}/hiring-flows/${hiringFlowId}/step/${stepId}/test/${testId}/results`
      );
      var data = response.data;
      const vocabModel: any = data?.results?.raw?.jobs.length
        ? data.results.raw.jobs.find((j: any) => j.jobType === "VOCAB_MODEL")
            ?.results?.body?.model_info
        : null;
      const accentModel: any = data?.results?.raw?.jobs.length
        ? data.results.raw.jobs.find((j: any) => j.jobType === "ACCENT_MODEL")
            ?.results?.body?.model_info
        : null;
      const fluencyModel: any = data?.results?.raw?.jobs.length
        ? data.results.raw.jobs.find((j: any) => j.jobType === "FLUENCY_MODEL")
            ?.results?.body?.model_info
        : null;
      if (data.results) {
        data = {
          ...data,
          results: {
            ...data.results,
            fluencyModel: fluencyModel,
            vocabModel: vocabModel,
            accentModel: accentModel,
          },
        };
      }
      return data;
    } catch (err: any) {
      return rejectWithValue({
        isError: true,
        message: err.data.message,
        code: "invalid",
      } as errorRequestType);
    }
  }
);

export const getTestSimulationPublicResults = createAsyncThunk<
  any,
  {
    applicationId?: string;
    hiringFlowId?: string;
    stepId?: string;
    testId: string;
  },
  {
    rejectValue: errorRequestType;
    state: RootState;
  }
>(
  "testSimulation/getTestSimulationPublicResults",
  async (
    { applicationId, hiringFlowId, stepId, testId },
    { rejectWithValue }
  ) => {
    try {
      const response = await publicHttp.get(
        `/application/${applicationId}/hiring-flows/${hiringFlowId}/step/${stepId}/test/${testId}/results/public`
      );

      console.log(response.data);

      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        isError: true,
        message: err.data.message,
        code: "invalid",
      } as errorRequestType);
    }
  }
);

export const getApplicationFeedback = createAsyncThunk<
  any,
  { id: string },
  {
    rejectValue: errorRequestType;
  }
>("application/getFeedback", async ({ id }, { rejectWithValue, dispatch }) => {
  try {
    const response = await testCandidateHttp.get(`/application/${id}/feedback`);
    return response.data;
  } catch (err: any) {
    return rejectWithValue({
      isError: true,
      message: err.data.message,
      code: "invalid",
    } as errorRequestType);
  }
});
