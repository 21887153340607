import {
  createAction,
  createAsyncThunk,
  createReducer,
} from "@reduxjs/toolkit";
import { publicHttp } from "common/api/public-http";
import { testCandidateHttp } from "common/api/testCandidate-http";
import { errorRequestType } from "common/types/data.types";

export const sendRecordingStartNotification = createAsyncThunk<
  any,
  {
    applicationId: string;
    hiringFlowId: string;
    stepId: string;
    testId: string;
    questionId: string;
    fileExtension: string;
    fileCodecs: string;
  },
  {
    rejectValue: errorRequestType;
  }
>(
  "testSimulation/sendRecordingStartNotification",
  async (
    {
      applicationId,
      hiringFlowId,
      stepId,
      testId,
      questionId,
      fileCodecs,
      fileExtension,
    },
    { rejectWithValue }
  ) => {
    // Send a request to the backend to notify about recording start
    try {
      const response = await testCandidateHttp.post(
        `/application/${applicationId}/hiring-flows/${hiringFlowId}/step/${stepId}/preinterview-test/${testId}/question/${questionId}/answer`,
        {
          questionId: questionId,
          testId: testId,
          fileCodecs: fileCodecs.includes(",")
            ? fileCodecs.split(",")[0].toUpperCase()
            : fileCodecs.toUpperCase(),
          fileExtension: fileExtension.toUpperCase(),
        }
      );
      console.log(
        "Recording start notification sent to the backend.",
        response
      );
    } catch (error) {
      console.error("Error sending recording start notification:", error);
    }
  }
);

export const sendRecordingStopNotification = createAsyncThunk<
  any,
  {
    applicationId: string;
    hiringFlowId: string;
    stepId: string;
    testId: string;
    questionId: string;
  },
  { rejectValue: errorRequestType }
>(
  "testSimulation/sendRecordingStopNotification",
  async (
    { applicationId, hiringFlowId, stepId, testId, questionId },
    { rejectWithValue }
  ) => {
    // Send a request to the backend to notify about recording stop
    try {
      console.log("Send recording stop notifitaction...");

      const response = await testCandidateHttp.post(
        `/application/${applicationId}/hiring-flows/${hiringFlowId}/step/${stepId}/preinterview-test/${testId}/question/${questionId}/answer/complete`,
        {
          questionId: questionId,
          testId: testId,
          filename: "test.webm",
        }
      );
      console.log("Recording stop notification sent to the backend.", response);
    } catch (error: any) {
      console.error("Error sending recording stop notification:", error);
      return rejectWithValue({
        isError: true,
        message: error.data.message,
        code: "invalid",
      } as errorRequestType);
    }
  }
);
