import { type ReactNode, useEffect, Fragment, useState } from "react";
import { useAppSelector } from "../../../redux/reduxTypes";
import { useLocation } from "react-router";

interface TitleWrapperType {
  title?: string | undefined;
  children: ReactNode;
}

const TitleWrapper = ({ title, children }: TitleWrapperType) => {
  const companyOverview = useAppSelector(
    (state) => state.company.companyOverview.value
  );

  const applicant = useAppSelector((state) => state.applicant?.profile?.value);
  const position = useAppSelector(
    (state) => state.position.positionOverview?.value
  );
  const application = useAppSelector(
    (state) => state.application.positionSimulationInfo?.value
  );
  const testOverview = useAppSelector(
    (state) => state.testSingle.testSingleOverview?.value
  );

  const location = useLocation();
  const [defaultTitle, setDefaultTitle] = useState("");
  const [name, setName] = useState("");

  useEffect(() => {
    if (applicant) {
      setName(applicant.name + " " + applicant.surname);
    } else if (position) {
      setName(position.name);
    } else if (testOverview) {
      setName(testOverview?.name);
    } else if (application?.name) {
      setName(application?.name);
    } else {
      setName("");
    }
  }, [applicant, location, position, testOverview]);

  useEffect(() => {
    if (companyOverview) {
      setDefaultTitle(companyOverview.name);
    } else {
      setDefaultTitle("ZenHire");
    }
  }, [companyOverview]);

  useEffect(() => {
    if (title) {
      document.title = name + " " + title + " | " + defaultTitle;
    } else {
      document.title = defaultTitle;
    }
  }, [title, defaultTitle, name, location.pathname]);

  return <Fragment>{children}</Fragment>;
};

export default TitleWrapper;
