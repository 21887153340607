import {createSlice} from "@reduxjs/toolkit";
import {companyDashboardType} from "views/features/company/companyDashboard/companyDashboard.types";
import {
    getCompanyDashboardApplicants,
    getCompanyDashboardPositions,
} from "views/features/company/companyDashboard/reducer/companyDashboard.actions";

interface companyDashboardState {
    companyDashboard: companyDashboardType;
}

const initialState: companyDashboardState = {
    companyDashboard: {
        values: {
            positions: [],
            applicants: []
        },
        error: false,
        loading: false,
    },
};

export const companyDashboard = createSlice({
    name: "companyDashboard",
    initialState,
    reducers: {
        clearCompanyDashboardPositions: (state) => {
            state.companyDashboard.values.positions = []
        },
        clearCompanyDashboardApplicants: (state) => {
            state.companyDashboard.values.applicants = []
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCompanyDashboardPositions.pending, (state) => {
                state.companyDashboard.loading = true;
            })
            .addCase(getCompanyDashboardPositions.fulfilled, (state, {payload}) => {
                state.companyDashboard.values.positions = payload;
                state.companyDashboard.loading = false;
            })
            .addCase(getCompanyDashboardPositions.rejected, (state) => {
                state.companyDashboard.error = true;
                state.companyDashboard.loading = false;
            })
            .addCase(getCompanyDashboardApplicants.pending, (state) => {
                state.companyDashboard.loading = true;
            })
            .addCase(getCompanyDashboardApplicants.fulfilled, (state, {payload}) => {
                state.companyDashboard.values.applicants = payload;
                state.companyDashboard.loading = false;
            })
            .addCase(getCompanyDashboardApplicants.rejected, (state) => {
                state.companyDashboard.error = true;
                state.companyDashboard.loading = false;
            })
    },
});

export const {clearCompanyDashboardPositions, clearCompanyDashboardApplicants} = companyDashboard.actions;

export default companyDashboard.reducer;
