import {createSlice} from "@reduxjs/toolkit";
import {
    getApplicantApplication,
    getApplicantApplicationModule,
    getApplicantApplications,
    getApplicantProfile,
    getApplicantTestResults, handleFinishScoringLiveInterview, handleLiveInterviewScoring,
    handleSubmitFinishScoring,
    handleSubmitMicrodimensionScore, refreshApplicantApplicationModule,
    refreshApplicantTestResults,
} from "./applicant.actions";
import {
    applicantApplicationModuleReducerType,
    applicantApplicationReducerType,
    applicantApplicationsReducerType,
    applicantProfileReducerType,
    applicantTestResultsReducerType,
} from "../applicant.types";

interface reducerType {
    profile: applicantProfileReducerType;
    applications: applicantApplicationsReducerType;
    application: applicantApplicationReducerType;
    module: applicantApplicationModuleReducerType;
    testResults: applicantTestResultsReducerType;
    liveInterviewScore: applicantTestResultsReducerType;
    applicantScreeningIds: any
}

const initialState: reducerType = {
    profile: {
        value: null,
        loading: false,
        error: {isError: false, type: "", message: ""},
    },
    applications: {
        value: null,
        loading: false,
        error: false,
    },
    application: {
        value: null,
        loading: false,
        error: {isError: false, type: "", message: ""},
    },
    module: {
        value: null,
        loading: false,
        error: false,
    },
    testResults: {
        value: null,
        loading: false,
        processing: false,
        error: {isError: false, type: "", message: ""},
    },
    liveInterviewScore: {
        value: null,
        loading: false,
        processing: false,
        error: {isError: false, type: "", message: ""},
    },
    applicantScreeningIds: []
};

export const applicant = createSlice({
    name: "applicant",
    initialState: initialState,
    reducers: {
        setApplicantApplicationModule: (state, {payload}) => {
            state.module.value = payload;
        },
        clearApplicant: () => initialState,

        clearApplicantApplications: (state) => {
            state.applications = initialState.applications;
        },
        clearApplicantApplication: (state) => {
            state.application = initialState.application;
        },
        clearApplicantApplicationModule: (state) => {
            state.module = initialState.module;
        },
        clearApplicantTestResults: (state) => {
            state.testResults = initialState.testResults;
        },
        setApplicantScreeningIds: (state, {payload}) => {
            state.applicantScreeningIds = payload;
        },
        clearApplicantScreeningIds: (state) => {
            state.applicantScreeningIds = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getApplicantProfile.pending, (state) => {
                state.profile.loading = true;
            })
            .addCase(getApplicantProfile.fulfilled, (state, {payload}) => {
                state.profile.value = payload;
                state.profile.loading = false;
            })
            .addCase(getApplicantProfile.rejected, (state, {payload}) => {
                state.profile.error = {
                    ...state.profile.error,
                    isError: true,
                    message: payload,
                };
                state.profile.loading = false;
            })
            .addCase(getApplicantApplications.pending, (state) => {
                state.applications.loading = true;
            })
            .addCase(getApplicantApplications.fulfilled, (state, {payload}) => {
                state.applications.value = payload;
                state.applications.loading = false;
            })
            .addCase(getApplicantApplications.rejected, (state) => {
                state.applications.error = true;
                state.applications.loading = false;
            })
            .addCase(getApplicantApplication.pending, (state) => {
                state.application.loading = true;
            })
            .addCase(getApplicantApplication.fulfilled, (state, {payload}) => {
                state.application.value = payload;
                state.application.loading = false;
            })
            .addCase(getApplicantApplication.rejected, (state, {payload}) => {
                state.application.error = {
                    isError: true,
                    message: payload?.message ? payload.message : "",
                    type: payload?.type ? payload.type : "",
                };
                state.application.loading = false;
            })
            .addCase(getApplicantApplicationModule.pending, (state) => {
                state.module.loading = true;
            })
            .addCase(
                getApplicantApplicationModule.fulfilled,
                (state, {payload}) => {
                    state.module.value = payload;
                    state.module.loading = false;
                }
            )
            .addCase(getApplicantApplicationModule.rejected, (state) => {
                state.module.error = true;
                state.module.loading = false;
            })
            .addCase(getApplicantTestResults.pending, (state) => {
                state.testResults.loading = true;
            })
            .addCase(getApplicantTestResults.fulfilled, (state, {payload}) => {
                state.testResults.value = payload;
                state.testResults.loading = false;
            })
            .addCase(getApplicantTestResults.rejected, (state) => {
                state.testResults.loading = false;
                state.testResults.error = {...state.testResults.error, isError: true};
            })

            .addCase(refreshApplicantTestResults.fulfilled, (state, {payload}) => {
                state.testResults.value = payload;
            })
            .addCase(refreshApplicantTestResults.rejected, (state) => {
                state.testResults.error = {...state.testResults.error, isError: true};
            })
            .addCase(handleSubmitMicrodimensionScore.pending, (state) => {
                state.testResults.processing = true;
            })
            .addCase(
                handleSubmitMicrodimensionScore.fulfilled,
                (state, {payload}) => {
                    state.testResults.processing = false;
                }
            )
            .addCase(handleSubmitMicrodimensionScore.rejected, (state) => {
                state.testResults.processing = false;
                state.testResults.error = {...state.testResults.error, isError: true};
            })
            .addCase(handleSubmitFinishScoring.pending, (state) => {
                state.testResults.processing = true;
            })
            .addCase(handleSubmitFinishScoring.fulfilled, (state, {payload}) => {
                state.testResults.processing = false;
            })
            .addCase(handleSubmitFinishScoring.rejected, (state) => {
                state.testResults.processing = false;
                state.testResults.error = {...state.testResults.error, isError: true};
            })
            .addCase(handleLiveInterviewScoring.pending, (state) => {
                state.liveInterviewScore.processing = true;
            })
            .addCase(
                handleLiveInterviewScoring.fulfilled,
                (state, {payload}) => {
                    state.liveInterviewScore.processing = false;
                }
            )
            .addCase(handleLiveInterviewScoring.rejected, (state) => {
                state.liveInterviewScore.processing = false;
                state.liveInterviewScore.error = {...state.testResults.error, isError: true};
            })
            .addCase(
                refreshApplicantApplicationModule.fulfilled,
                (state, {payload}) => {
                    state.module.value = payload;
                }
            )
            .addCase(refreshApplicantApplicationModule.rejected, (state) => {
                state.module.error = true;
            })
            .addCase(handleFinishScoringLiveInterview.pending, (state) => {
                state.testResults.processing = true;
            })
            .addCase(handleFinishScoringLiveInterview.fulfilled, (state, {payload}) => {
                state.testResults.processing = false;
            })
            .addCase(handleFinishScoringLiveInterview.rejected, (state) => {
                state.testResults.processing = false;
                state.testResults.error = {...state.testResults.error, isError: true};
            })
    },
});

export const {
    clearApplicant,
    clearApplicantApplications,
    clearApplicantApplication,
    clearApplicantApplicationModule,
    clearApplicantTestResults,
    setApplicantApplicationModule,
    setApplicantScreeningIds,
    clearApplicantScreeningIds
} = applicant.actions;

export default applicant.reducer;
