import {type FC, lazy, ReactNode} from "react";
import { lazyRetry } from "common/utility/Utils";
import { type routeType } from "router/routes/types";
const CompanyWrapper: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () => await import("../../companyDashboard/wrappers/DashboardWrapper")
    )
);
const CompanyOverview: FC | any = lazy(
    async () =>
        await lazyRetry(
            async () => await import("../../companyDashboard/pages/CompanyOverviewPage")
        )
);
const CompanySuspended: FC | any = lazy(
    async () =>
        await lazyRetry(
            async () => await import("../../CompanySuspended/CompanySuspended")
        )
);

const companyDashboardRoutes: routeType[] = [
  {
    path: ":companyId",
    element: <CompanyWrapper />,
    meta: {
      layout: "app",
      type: "page",
      private: true,
    },
    children: [
      {
        path: "",
        element: <CompanyOverview />,
        meta: {
          title: "Dashboard",
          type: "component",
          private: true,
        },
      },
    ],
  },
  {
    path: ":companyId/suspended",
    element: <CompanySuspended />,
    meta: {
      layout: "blank",
      type: "page",
      title: "Company Suspended",
      private: true,
    },
  },
];
export default companyDashboardRoutes;
