import {
  answerInputType,
  closedConfigType,
  openConfigType,
  preinterviewConfigType,
  statementScaleTestConfigType,
  testQuestionType,
} from "./testSingle.types";
import { getRandomId } from "common/utility/Utils";

export const initAnswerInput = () => {
  return {
    id: getRandomId(),
    text: "",
    defaultText: "",
    isCorrect: false,
  } as answerInputType;
};

export const initClosedConfig: closedConfigType = {
  type: "text",
  hasMultipleAnswers: true,
  displayStyle: "vertical",
  negativePoints: 0,
  options: [initAnswerInput(), initAnswerInput()],
};

export const initPreinterviewConfig: preinterviewConfigType = {
  preparationTime: 0,
};
export const initOpenConfig: openConfigType = {
  charLimit: 0,
};

export const statementScaleTestConfig: statementScaleTestConfigType = {
  isScaleReversed: false,
};

export const defaultQuestionInput: testQuestionType = {
  question: "",
  importance: 0,
  timeLimit: 0,
  isInDemo: false,
  microDimIds: [],
  microDims: [],
  testId: "",
  specificId: "",
  specificType: "",
  _id: "",
};

export const closedQuestionInput: testQuestionType = {
  ...defaultQuestionInput,
  closedConfig: initClosedConfig,
};
export const preintrviewQuestionInput: testQuestionType = {
  ...defaultQuestionInput,
  preinterviewConfig: initPreinterviewConfig,
};
export const openQuestionInput: testQuestionType = {
  ...defaultQuestionInput,
  openConfig: initOpenConfig,
};
export const typingSpeedInput: testQuestionType = {
  ...defaultQuestionInput,
  importance: 1,
};
export const statementScaleInput: testQuestionType = {
  ...defaultQuestionInput,
  statementScaleTestConfig: statementScaleTestConfig,
  importance: 1,
};

export const answersTypeOptions: { value: string; label: string }[] = [
  { value: "multiple", label: "Multiple  correct answers" },
  { value: "single", label: "Single correct answer" },
];

export const answerTypeOptions: { value: string; label: string }[] = [
  { value: "text", label: "Answer type: Text" },
  { value: "image", label: "Answer type: Image" },
];
