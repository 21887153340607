import { lazyRetry } from "common/utility/Utils";

import MergeLayoutRoutes from "./MergeLayoutRoutes";

// ** Routes Imports
import authRoutes from "views/features/auth/routes/index";
import dashboardRoutes from "views/features/dashboard/routes";
import exceptionRoutes from "views/features/exception/routes";
import testLibraryRoutes from "views/features/testLibrary/routes";
import testSingleRoutes from "views/features/testSingle/routes";
// import testSimulationRoutes from "views/features/testSimulation/routes";
import microdimensionsRoutes from "views/features/microdimensions/routes";
import usersRoutes from "views/features/users/routes";
import applicantRoutes from "views/features/applicant/routes";
import applicantsRoutes from "views/features/applicants/routes";
import applicationRoutes from "views/features/application/routes";
import positionsRoutes from "views/features/positions/routes";
import positionRoutes from "views/features/position/routes";
import companiesRoute from "../../views/features/companies/routes";
import companyAuthRoutes from "../../views/features/company/companyAuth/routes";
import companyDashboardRoutes from "../../views/features/company/companyDashboard/routes";
import companyTeamRoutes from "../../views/features/company/companyTeam/routes";
import companyApplicantsRoutes from "../../views/features/company/CompanyApplicants/routes";
import companyApplicantRoutes from "../../views/features/company/companyApplicant/routes";
import companyPositionsRoutes from "../../views/features/company/companyPositions/routes";
import companySinglePositionRoutes from "../../views/features/company/companySinglePosition/routes";
import companyApplicationRoutes from "../../views/features/company/companyApplication/routes/index";
import companyTestLibraryRoutes from "../../views/features/company/companyTestLibrary/routes/index";
import companyTestSingleRoutes from "../../views/features/company/companyTestSingle/routes/index";
import systemEmails from "views/features/systemEmails/routes";

// ** Merge Routes
const adminRoutes = [
  ...authRoutes,
  ...exceptionRoutes,
  ...dashboardRoutes,
  ...testLibraryRoutes,
  // ...testSimulationRoutes,
  ...microdimensionsRoutes,
  ...testSingleRoutes,
  ...usersRoutes,
  ...applicantRoutes,
  ...applicantsRoutes,
  ...positionsRoutes,
  ...positionRoutes,
  ...applicationRoutes,
  ...companiesRoute,
  ...systemEmails,
];

const clientRoutes = [
  ...companyAuthRoutes,
  ...companyDashboardRoutes,
  ...companyTeamRoutes,
  ...companyApplicantsRoutes,
  ...companyApplicantRoutes,
  ...companyPositionsRoutes,
  ...companySinglePositionRoutes,
  ...companyApplicationRoutes,
  ...companyTestLibraryRoutes,
  ...companyTestSingleRoutes,
];

const routes = adminRoutes.concat(clientRoutes);

const getRoutes = () => {
  return [...MergeLayoutRoutes(routes)];
};

export { routes, getRoutes };
