import { createSlice } from "@reduxjs/toolkit";
import { testOverviewType } from "views/features/testSingle/testSingle.types";
import { errorRequestType } from "common/types/data.types";
import {
  submitApplication,
  verifyApplication,
  getTestApplicant,
  getApplication,
  getTestSimulationInfo,
  getActiveModule,
  getPositionSimulationInfo,
  submitPositionApplication,
  getTestSimulationResults,
  updatePositionSimulationInfo,
  getApplicationFeedback,
  getTestSimulationPublicResults,
} from "./application.actions";

import {
  positionFeedbackType,
  positionSimulationType,
  qualifyingQuestionsAnswersType,
  testCandidateType,
} from "../application.types";

interface initialStateTypes {
  testSimulationInfo: {
    value: testOverviewType | null;
    loading: boolean;
    refreshing: boolean;
    processing: boolean;
    isDemo: boolean;
    error: errorRequestType;
  };
  positionSimulationInfo: {
    value: positionSimulationType | null;
    loading: boolean;
    refreshing: boolean;
    processing: boolean;
    isDemo: boolean;
    error: errorRequestType;
  };
  application: {
    value: any;
    processing: boolean;
    error: errorRequestType;
    loading: boolean;
  };
  module: {
    value: any;
    processing: boolean;
    error: errorRequestType;
    loading: boolean;
  };
  candidate: {
    value: testCandidateType | null;
    loggedIn: boolean;
    loading: boolean;
    applicationId: null | string;
    alreadyApplied: boolean;
    processing: boolean;
    error: errorRequestType;
  };
  publicTestResults: {
    value: any;
    loading: boolean;
    error: errorRequestType;
  };
  applicationFeedback: {
    value: positionFeedbackType | null;
    loading: boolean;
    error: errorRequestType;
  };
}

const initialState: initialStateTypes = {
  testSimulationInfo: {
    value: null,
    loading: false,
    refreshing: false,
    processing: false,
    isDemo: false,
    error: { isError: false, type: "", message: "" },
  },
  positionSimulationInfo: {
    value: null,
    loading: false,
    refreshing: false,
    processing: false,
    isDemo: false,
    error: { isError: false, type: "", message: "" },
  },
  candidate: {
    value: null,
    loggedIn: false,
    loading: false,
    applicationId: null,
    alreadyApplied: false,
    processing: false,
    error: { isError: false, type: "", message: "" },
  },
  application: {
    value: null,
    processing: false,
    error: { isError: false, type: "", message: "" },
    loading: false,
  },
  module: {
    value: null,
    processing: false,
    error: { isError: false, type: "", message: "" },
    loading: false,
  },

  publicTestResults: {
    value: null,
    loading: true,
    error: { isError: false, type: "", message: "" },
  },
  applicationFeedback: {
    value: null,
    loading: true,
    error: { isError: false, type: "", message: "" },
  },
};

const application = createSlice({
  name: "application",
  initialState,
  reducers: {
    setIsTestDemo(state, { payload }) {
      state.testSimulationInfo.isDemo = payload;
    },
    setCandidateValue(state, { payload }) {
      state.candidate.value = payload;
    },
    setAssessmentSimulationModule(state, { payload }) {
      state.module.value = payload;
    },
    clearApplicationSession(state) {
      state.application = initialState.application;
      state.candidate = initialState.candidate;
      state.module = initialState.module;
    },
    clearApplicationCandidate(state) {
      state.candidate = initialState.candidate;
    },
    clearTestSimulationApply(state) {
      // state.candidate = initialState.candidate;
      state.testSimulationInfo = initialState.testSimulationInfo;
    },
    clearPositionSimulationInfo(state) {
      // state.candidate = initialState.candidate;
      state.positionSimulationInfo = initialState.positionSimulationInfo;
    },

    clearPublicTestResults(state) {
      state.publicTestResults = initialState.publicTestResults;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTestSimulationInfo.pending, (state) => {
      state.testSimulationInfo.loading = true;
    });
    builder.addCase(getTestSimulationInfo.fulfilled, (state, { payload }) => {
      state.testSimulationInfo.value = payload;
      state.testSimulationInfo.loading = false;
    });
    builder.addCase(getTestSimulationInfo.rejected, (state, { payload }) => {
      state.testSimulationInfo.loading = false;
      state.testSimulationInfo.error = {
        isError: true,
        message: payload?.message ? payload.message : "",
        type: payload?.type ? payload.type : "",
      };
    });
    builder.addCase(getPositionSimulationInfo.pending, (state) => {
      state.positionSimulationInfo.loading = true;
    });
    builder.addCase(
      getPositionSimulationInfo.fulfilled,
      (state, { payload }) => {
        state.positionSimulationInfo.value = payload;
        state.positionSimulationInfo.loading = false;
      }
    );
    builder.addCase(
      getPositionSimulationInfo.rejected,
      (state, { payload }) => {
        state.positionSimulationInfo.loading = false;
        state.positionSimulationInfo.error = {
          isError: true,
          message: payload?.message ? payload.message : "",
          type: payload?.type ? payload.type : "",
        };
      }
    );
    builder
      .addCase(updatePositionSimulationInfo.pending, (state) => {
        state.positionSimulationInfo.processing = true;
      })
      .addCase(updatePositionSimulationInfo.fulfilled, (state, { payload }) => {
        state.positionSimulationInfo.value = payload;
        state.positionSimulationInfo.processing = false;
      })
      .addCase(updatePositionSimulationInfo.rejected, (state) => {
        state.positionSimulationInfo.error = { isError: true, message: "" };
        state.positionSimulationInfo.processing = false;
      });
    builder.addCase(submitApplication.pending, (state) => {
      state.candidate.loading = true;
    });
    builder.addCase(submitApplication.fulfilled, (state, { payload }) => {
      state.candidate.processing = false;
      state.candidate.loading = false;
      state.candidate.applicationId = payload?.id || null;
    });
    builder.addCase(submitApplication.rejected, (state, { payload }) => {
      state.candidate.processing = false;
      state.candidate.error = {
        isError: true,
        message: payload?.message ? payload.message : "",
        type: payload?.type ? payload.type : "",
      };
    });
    builder.addCase(submitPositionApplication.pending, (state) => {
      state.candidate.loading = true;
      state.candidate.processing = true;
    });
    builder.addCase(
      submitPositionApplication.fulfilled,
      (state, { payload }) => {
        state.candidate.processing = false;
        state.candidate.loading = false;
        state.candidate.applicationId = payload?.id || null;
        state.candidate.alreadyApplied = payload?.alreadyApplied || false;
      }
    );
    builder.addCase(
      submitPositionApplication.rejected,
      (state, { payload }) => {
        state.candidate.processing = false;
        state.candidate.error = {
          isError: true,
          message: payload?.message ? payload.message : "",
          type: payload?.type ? payload.type : "",
        };
      }
    );
    builder.addCase(getApplication.pending, (state) => {
      state.application.loading = true;
    });
    builder.addCase(getApplication.fulfilled, (state, { payload }) => {
      state.application.loading = false;
      state.application.value = payload;
    });
    builder.addCase(getApplication.rejected, (state, { payload }) => {
      state.application.loading = false;
      state.application.error = {
        isError: true,
        message: payload?.message ? payload.message : "",
        type: payload?.type ? payload.type : "",
      };
    });
    builder.addCase(getActiveModule.pending, (state) => {
      state.module.loading = true;
    });
    builder.addCase(getActiveModule.fulfilled, (state, { payload }) => {
      state.module.loading = false;
      state.module.value = payload;
    });
    builder.addCase(getActiveModule.rejected, (state, { payload }) => {
      state.module.loading = false;
      state.module.error = {
        isError: true,
        message: payload?.message ? payload.message : "",
        type: payload?.type ? payload.type : "",
      };
    });
    builder.addCase(verifyApplication.pending, (state) => {
      state.candidate.processing = true;
    });
    builder.addCase(verifyApplication.fulfilled, (state, { payload }) => {
      state.candidate.processing = false;
      state.application.value = payload?.application;
    });
    builder.addCase(verifyApplication.rejected, (state, { payload }) => {
      state.candidate.processing = false;
      state.candidate.error = {
        isError: true,
        message: payload?.message ? payload.message : "",
        type: payload?.type ? payload.type : "",
      };
    });
    builder.addCase(getTestApplicant.pending, (state) => {
      state.candidate.loading = true;
    });
    builder.addCase(getTestApplicant.fulfilled, (state, { payload }) => {
      state.candidate.loading = false;
      state.candidate.value = payload;
    });
    builder.addCase(getTestApplicant.rejected, (state, { payload }) => {
      state.candidate.loading = false;
      state.candidate.error = {
        isError: true,
        message: payload?.message ? payload.message : "",
        type: payload?.type ? payload.type : "",
      };
    });
    builder.addCase(getTestSimulationPublicResults.pending, (state) => {
      state.publicTestResults.loading = true;
    });
    builder.addCase(
      getTestSimulationPublicResults.fulfilled,
      (state, { payload }) => {
        state.publicTestResults.loading = false;
        state.publicTestResults.value = payload;
      }
    );
    builder.addCase(
      getTestSimulationPublicResults.rejected,
      (state, { payload }) => {
        state.publicTestResults.loading = false;
        state.publicTestResults.error = {
          isError: true,
          message: payload?.message ? payload.message : "",
          type: payload?.type ? payload.type : "",
        };
      }
    );
    builder
      .addCase(getApplicationFeedback.pending, (state) => {
        state.applicationFeedback.loading = true;
      })
      .addCase(getApplicationFeedback.fulfilled, (state, { payload }) => {
        state.applicationFeedback.value = payload;
        state.applicationFeedback.loading = false;
      })
      .addCase(getApplicationFeedback.rejected, (state) => {
        state.applicationFeedback.error = { isError: true, message: "" };
        state.applicationFeedback.loading = false;
      });
  },
});

export const {
  setIsTestDemo,
  setCandidateValue,
  setAssessmentSimulationModule,
  clearTestSimulationApply,
  clearApplicationSession,
  clearApplicationCandidate,
  clearPositionSimulationInfo,
  clearPublicTestResults,
} = application.actions;

export default application.reducer;
