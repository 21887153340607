// ** Redux Imports
import {createAsyncThunk} from "@reduxjs/toolkit";

// ** Axios Imports
import {http} from "common/api/http";
import {setToast} from "redux/alert";
import {RootState} from "redux/store";

interface getTestsParams {
    state?: string;
    page?: number;
    limit?: number;
    companyId?: string;
    filters?: any;
    search?: any;
}

type multiSelectType = {
    _id: string;
};

export const getPositions = createAsyncThunk<
    any,
    getTestsParams,
    { state: RootState }
>(
    "positions/getPositions",
    async ({state, page, limit, companyId, filters, search}, {getState}) => {
        try {
            const params = {
                limit: limit ? limit : 10,
                page: page ? page : 1,
                ...(state && {
                    state: `${state.toUpperCase()}`,
                }),
                ...(filters?.type && {
                    automationType: filters.type.value,
                }),
                ...(filters?.template && {
                    templateId: filters.template.value,
                }),
                ...(filters?.company && {
                    companyId: filters.company._id,
                }),
                ...(search && {
                    search: search,
                }),
                ...(filters?.tests && {
                    tests: `${filters.tests.map((tests: multiSelectType) => tests._id)}`,
                }),
                ...(filters?.sort && {
                    sort: filters?.direction?.value
                        ? `${filters?.direction?.value === "desc" ? "-" : ""}${
                            filters.sort.value
                        }`
                        : filters?.sort,
                }),
                ...(filters?.microdimensions && {
                    microdimensions: `${filters.microdimensions.map(
                        (microdimension: multiSelectType) => microdimension._id
                    )}`,
                }),
                ...(companyId && {
                    company: companyId,
                }),
            };
            const response = await http.get(`/positions`, {params});
            // const values = getState().positions.positionsList.values;
            // const newValues =
            //   values && response.data?.page && response.data.page > 1
            //     ? {
            //         ...response.data,
            //         data: [...values.data, ...response.data.data],
            //       }
            //     : response.data;
            return response.data;
        } catch (err: any) {
            console.log(err);
            return err.response.data;
        }
    }
);

export const getTestTemplates = createAsyncThunk(
    "positions/getTestTemplates",
    async () => {
        try {
            const response = await http.get("/tests/templates");
            return response.data;
        } catch (err: any) {
            return err.response.data;
        }
    }
);

export interface positionWizardInput {
    name: string;
    hiringLimit: number;
    employmentType: string;
    workingFrom: string;
    location?: string;
    companyId?: string;
    requirements?: {
        licenses?: { importance: number, licenses: string[] },
        industryExperience?: { importance: number, industries: string[] }
        workExperience?: { from: number, to: number, importance: number }
        education?: {
            level: string;
            fieldOfStudy: string
            importance: number
        }
    },
    jobSummary?: string,
    keyResponsibilities?: string
}

export const createPosition = createAsyncThunk(
    "positions/createPosition",
    async (
        data: any,
        {dispatch}
    ) => {
        try {
            const response = await http.post("/positions", data)
            dispatch(
                setToast({
                    message: "Successfully created a position.",
                    type: "success",
                })
            );
            return response.data;
        } catch (err: any) {
            dispatch(
                setToast({
                    message: "Error creating a position. Please try again later.",
                    type: "error",
                })
            );
            return err.response.data;
        }
    }
);
