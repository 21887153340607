import { Fragment, ReactNode, useCallback, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import {ReactComponent as ImgAnswer} from "assets/icons/img-answer.svg";
import {ReactComponent as FileImage} from "assets/icons/document-big-ico.svg";
import filePreviewIco from "assets/icons/hamburger-ico-white.svg";
import imgIco from "assets/icons/uploaded-img-ico.svg";
import { ReactComponent as DeleteIco } from "assets/icons/discard-ico.svg";
import { isObjEmpty } from "common/utility/Utils";

const fileMimeTypes = ["pdf", "rar", "zip", "doc", "plain"];
const imageMimeTypes = ["png", "gif", "jpeg", "jpg"];
const pdfMimeTypes = ["pdf"];
const csvMimeType = ["csv"];
const excelMimeType = ["csv", "xls", "xlsx"];
const textMimeType = ["doc","docx", "txt"];

function getSupportedMimeTypes(values: string[]) {
  let tempArr: string[] = [];
  for (let i = 0; i < values.length; i++) {
    switch (values[i]) {
      case "image":
        tempArr = tempArr.concat(imageMimeTypes);
        break;
      case "file":
        tempArr = tempArr.concat(fileMimeTypes);
        break;
      case "pdf":
        tempArr = tempArr.concat(pdfMimeTypes);
        break;
      case "csv":
        tempArr = tempArr.concat(csvMimeType);
        break;
      case "excel":
        tempArr = tempArr.concat(excelMimeType);
        break;
      case "text":
        tempArr = tempArr.concat(textMimeType);
        break;
      default:
        break;
    }
  }
  return tempArr;
}

function renderSupportedMimeTypes(supported: string[]) {
  return supported.map((v: string, i: number) => {
    return (
      <Fragment key={i}>
        {v}
        {i === supported.length - 1 ? "" : ", "}
      </Fragment>
    );
  });
}

function switchFileTypes(value: string) {
  switch (value) {
    case "image":
      return {
        "image/*": [".png", ".gif", ".jpeg", ".jpg"],
      };
    case "file":
      return {
        "application/pdf": [],
        "application/zip": [],
        "application/rar": [],
        "application/doc": [],
        "application/plain": [],
      };
    case "pdf":
      return {
        "application/pdf": [],
      };
    case "csv":
      return {
        "text/*": [".csv"],
      };
    case "excel":
      return {
        "text/*": [".csv", ".xls", ".xlsx"],
      };
    case "text":
      return {
        "text/*": [".doc", ".docx"],
      };
    default:
      return {};
  }
}

function checkFilesSize(files: File[]) {
  let size = 0;
  for (let i = 0; i < files.length; i++) {
    size = size + files[i].size;
  }
  return size;
}

interface propTypes {
  accept?: string[];
  onChange?: (file: File) => void;
  onRemove?: () => void;
  preview?: boolean;
  invalid?: boolean;
  placeholder?: string;
  hideSupportType?: boolean
  hideIconUpload?: boolean
}

const UploadSingleDropzone = ({
  accept = ["image"],
  onChange,
  onRemove,
  preview,
  invalid,
  placeholder,
  hideSupportType,
  hideIconUpload
}: propTypes) => {
  const [myFiles, setMyFiles] = useState<File[]>([]);

  const [error, setError] = useState(false);

  const acceptFormat = useMemo(() => {
    if (accept?.length) {
      let tempObj = {};
      for (let i = 0; i < accept.length; i++) {
        tempObj = {
          ...tempObj,
          ...switchFileTypes(accept[i]),
        };
      }
      if (isObjEmpty(tempObj)) {
        return {
          "image/*": [".png", ".gif", ".jpeg", ".jpg"],
        };
      }
      return tempObj;
    }
    return {
      "image/*": [".png", ".gif", ".jpeg", ".jpg"],
    };
  }, [accept]);

  const initialPlaceholder: ReactNode = useMemo(() => {
    const supported = getSupportedMimeTypes(accept);
    return (
      <div className={`flex flex-col items-center justify-center ${hideIconUpload || hideSupportType ? "p-0" : "p-12"} text-center`}>
        {hideIconUpload ? null :
          <Fragment>
            {accept.includes("image") ?
                <ImgAnswer
                    className="w-[50px] h-[50px] [&_path]:fill-main-color"
                /> :
                <FileImage
                    className="w-[50px] h-[50px] [&_path]:fill-main-color"
                />
            }
          </Fragment>
        }
        <p className="text-lg font-light text-gray mt-3">
          {placeholder
            ? placeholder
            : `Drop your ${
                accept.includes("image") ? "image" : "file"
              } here or${" "}`}
          <span className="font-medium text-main-color">browse</span>
        </p>
        {hideSupportType ? null :
         <Fragment>
           <p className="text-sm font-light text-gray">
             Supports: {renderSupportedMimeTypes(supported)}
           </p>
           <p className="text-sm font-light text-gray">Max file size: 10mb</p>
         </Fragment>
        }
      </div>
    );
  }, [accept]);

  const errorPlaceholder: ReactNode = useMemo(() => {
    const supported = getSupportedMimeTypes(accept);

    return (
      <div className="flex flex-col gap-3 items-center justify-center p-12 text-center">
        <p className="text-lg font-light text-gray">
          File rejected. Click to try again
        </p>
        <p className="text-lg font-light text-timer-red mt-3">
          Please, upload only 1 file
        </p>
        <p className="text-sm font-light text-timer-red">
          Supports: {renderSupportedMimeTypes(supported)}
        </p>
        <p className="text-sm font-light text-timer-red">Max file size: 10mb</p>
      </div>
    );
  }, [accept]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      setMyFiles([...acceptedFiles]);
      // setPlaceholder(initialPlaceholder);
      setError(false);
      if (onChange) {
        onChange([...acceptedFiles][0]);
      }
    },
    [myFiles]
  );

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: {
      ...acceptFormat,
      // "application/pdf",
      // "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      // "application/msword",
      // "application/vnd.ms-excel",
      // "application/vnd.ms-powerpoint",
      // "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      // "text/plain",
      // "application/vnd.rar",
      // "application/zip",
      // "image/svg+xml",
    },
    onDrop: (acceptedFiles: File[]) => {
      let err = false;
      if (checkFilesSize(acceptedFiles) > 10000000) {
        err = true;
      }
      if (err) {
        // setPlaceholder(errorPlaceholder);
        setError(true);
        return;
      } else {
        // setPlaceholder(errorPlaceholder);
        setError(true);
        onDrop([...acceptedFiles.map((file) => Object.assign(file))]);
      }
    },
    onDropRejected: () => {
      // setPlaceholder(errorPlaceholder);
      setError(true);
    },
  });

  const removeFile = (file: File) => () => {
    const newFiles = [...myFiles];
    newFiles.splice(newFiles.indexOf(file), 1);
    setMyFiles(newFiles);
    onRemove && onRemove();
  };

  const files = myFiles.map((file: any) => {
    return (
      <p
        key={file.path}
        className="flex items-center gap-3 bg-main-color rounded-xl py-2 px-3 w-fit mt-2"
      >
        <img
          src={file.type.includes("image") ? imgIco : filePreviewIco}
          alt="img-ico"
        />
        <span className="text-white font-light">{file.path}</span>
        <span onClick={removeFile(file)} className="z-10 cursor-pointer">
          <DeleteIco className="[&_path]:fill-white" />
        </span>
      </p>
    );
  });

  const showPreview = myFiles.map((file: any) => {
    const fileData = Object.assign(file, {
      preview: URL.createObjectURL(file),
    });

    return (
      <div
        className="flex border-2 border-main-color p-0.5 w-28 h-auto"
        key={fileData.name}
      >
        <div className="flex overflow-hidden min-w-[0px]">
          <img src={fileData.preview} className="w-auto h-full block rounded" />
        </div>
      </div>
    );
  });

  return (
    <>
      <section
        className={`relative border-[3px] ${
          invalid
            ? "border-invalid-red bg-invalid-dimmed-red"
            : "border-light-gray"
        } border-dashed p-5 w-full flex flex-col h-full bg-transparent overflow-auto scrollbar-hide`}
      >
        <div {...getRootProps({ className: "dropzone -mt-[10px]" })}>
          <input {...getInputProps()} />
          <div
            className={`absolute top-0 left-0 ${
              invalid ? "bg-invalid-dimmed-red" : "bg-transparent"
            } cursor-pointer w-full h-full`}
          ></div>
          {files.length === 0
            ? error
              ? errorPlaceholder
              : initialPlaceholder
            : ""}
        </div>
        <div className="realtive flex gap-2 items-center flex-wrap">
          {files}
        </div>
      </section>
      {preview && (
        <div className="flex flex-col gap-2">
          {showPreview[0] && (
            <h4 className="font-light text-gray text-sm">File preview</h4>
          )}
          <div className="flex flex-wrap">{showPreview}</div>
        </div>
      )}
    </>
  );
};

export default UploadSingleDropzone;
