import { Outlet } from "react-router";

const BlankLayout = () => {
  return (
    <div className="app-layout">
      <Outlet />
    </div>
  );
};

export default BlankLayout;
