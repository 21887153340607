import {type FC, lazy} from "react";
import { lazyRetry } from "common/utility/Utils";
import { type routeType } from "router/routes/types";

const TestLibraryWrapper: FC | any = lazy(
    async () =>
        await lazyRetry(
            async () =>
                await import("../../../testLibrary/wrappers/TestLibraryWrapper/TestLibraryWrapper")
        )
);

const TestLibrary: FC | any = lazy(
    async () =>
        await lazyRetry(
            async () => await import("../../../testLibrary/pages/TestLibrary/TestLibrary")
        )
);

const companyTestLibraryRoutes: routeType[] = [
  {
    path: ":companyId/test-library",
    element: <TestLibraryWrapper />,
    permissions: "GET_TESTS",
    meta: {
      layout: "app",
      title: "Test library",
      type: "page",
      private: true,
    },
    children: [
      {
        path: "",
        element: <TestLibrary state={"published"} />,
        permissions: "GET_TESTS_PUBLISHED",
        meta: {
          type: "component",
          private: true,
        },
      },
    ]
  },
];
export default companyTestLibraryRoutes;
