import { createAsyncThunk } from "@reduxjs/toolkit";
import { http } from "common/api/http";
import {AppDispatch, RootState} from "redux/store";
import { errorRequestType } from "../../../../common/types/data.types";
import { isObjEmpty } from "common/utility/Utils";
import {data} from "autoprefixer";
import {setToast} from "../../../../redux/alert";
export const getApplicantProfile = createAsyncThunk<any, { id: string }>(
  "applicant/getApplicantProfile",
  async ({ id }, { dispatch, rejectWithValue }) => {
    try {
      const response = await http.get(`/applicant/${id}`);
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err.data.message);
    }
  }
);

type multiSelectType = {
    _id: string;
};
export const getApplicantApplications = createAsyncThunk<any, { id: string,page:number, globalFilters?: any; globalSearch?: any; globalCustomFilters?: any },  { state: RootState }>(
  "applicant/getApplicantApplications",
  async ({ id, page,globalFilters, globalSearch,globalCustomFilters },{ getState }) => {

      const params = {
          limit: 15,
          page: page ? page : 1,
          ...(globalSearch && {
              search: globalSearch,
          }),
          ...(globalCustomFilters?.dateFrom && {
              dateFrom: globalCustomFilters.dateFrom,
          }),
          ...(globalCustomFilters?.dateTo && {
              dateTo: globalCustomFilters.dateTo,
          }),
          ...(globalFilters?.sort && {
              sort: `${globalFilters?.direction?.value === "desc" ? "-" : ""}${
                  globalFilters.sort.value
              }`,
          }),
          ...(globalFilters?.test && {
              testId: globalFilters.test.value,
          }),
          ...(globalFilters?.position && {
              positionId: globalFilters.position.value,
          }),
          ...(globalFilters?.status && {
              state: globalFilters.status.value,
          }),
          ...(globalFilters?.microdimensions && {
              microdimensions: `${globalFilters.microdimensions.map(
                  (microdimension: multiSelectType) => microdimension._id
              )}`,
          }),
      };

    try {
      const response = await http.get(`/applicant/${id}/applications`,{ params });

      const values = getState().applicant?.applications?.value;
      const newValues =
        values && response.data?.page && response.data.page > 1
            ? {
                ...response.data,
                data: [...values.data, ...response.data.data],
            }
            : response.data;
      return newValues;
    } catch (err: any) {
      return err.response.data;
    }
  }
);

export const getApplicantApplication = createAsyncThunk<
  any,
  { id: string; applicationId: string },
  {
    rejectValue: errorRequestType;
  }
>(
  "applicant/getApplicantApplication",
  async ({ id, applicationId }, { dispatch, rejectWithValue }) => {
    try {
      const response = await http.get(
        `/applicant/${id}/application/${applicationId}`
      );
      const application = response.data;

      const module =
        application.test && !isObjEmpty(application?.testFull)
          ? application.testFull?.template?.enum === "QUALIFYING_QUESTIONS"
            ? application.hiringFlow?.applicationStepModules[0]
            : application.hiringFlow?.applicationStepModules[1]
          : null;

      if (module) {
        dispatch(
          getApplicantApplicationModule({
            id: id,
            applicationId: applicationId,
            stepId: module.moduleType === "auth" ? "1" : "2",
          })
        );
      }
      return application;
    } catch (err: any) {
      return rejectWithValue({
        isError: true,
        message: err.data.message,
        type: "invalid",
      } as errorRequestType);
    }
  }
);

export const getApplicantApplicationModule = createAsyncThunk<
  any,
  {
    id: string;
    applicationId: string;
    stepId: string;
  },
  {}
>(
  "applicant/getApplicantApplicationModule",
  async ({ id, applicationId, stepId }) => {
    try {
      const response = await http.get(
        `/applicant/${id}/application/${applicationId}/${stepId}`
      );
      return response.data;
    } catch (err: any) {
      return err.response.data;
    }
  }
);
export const getApplicantTestResults = createAsyncThunk<
  any,
  {
    id: string;
    applicationId: string;
    hiringFlowId: string;
    stepId: string;
    testId: string;
  },
  {}
>(
  "applicant/getApplicantTestResults",
  async ({ id, applicationId, hiringFlowId, stepId, testId }) => {
    try {
      const response = await http.get(
        `/applicant/${id}/application/${applicationId}/hiring-flows/${hiringFlowId}/step/${stepId}/test/${testId}/results`
      );
      return response.data;
    } catch (err: any) {
      return err.response.data;
    }
  }
);
export const refreshApplicantTestResults = createAsyncThunk<
  any,
  {
    id: string;
    applicationId: string;
    hiringFlowId: string;
    stepId: string;
    testId: string;
  },
  {}
>(
  "applicant/refreshApplicantTestResults",
  async ({ id, applicationId, hiringFlowId, stepId, testId }) => {
    try {
      const response = await http.get(
        `/applicant/${id}/application/${applicationId}/hiring-flows/${hiringFlowId}/step/${stepId}/test/${testId}/results`
      );
      return response.data;
    } catch (err: any) {
      return err.response.data;
    }
  }
);

export const handleSubmitMicrodimensionScore = createAsyncThunk<
  any,
  {
    applicantId: string;
    applicationId: string;
    hiringFlowId: string;
    stepId: string;
    testId: string;
    microdimensionId: string;
    questionId: string;
    score: number;
  },
  {}
>(
  "applicant/handleSubmitMicrodimensionScore",
  async (
    {
      applicantId,
      applicationId,
      hiringFlowId,
      stepId,
      testId,
      microdimensionId,
      questionId,
      score,
    },
    { dispatch }
  ) => {
    try {
      const response = await http.post(
        `/application/${applicationId}/hiring-flows/${hiringFlowId}/step/${stepId}/test/${testId}/score`,
        {
          microdimensionId,
          questionId,
          score,
        }
      );
      dispatch(
        refreshApplicantTestResults({
          id: applicantId,
          applicationId,
          hiringFlowId,
          stepId,
          testId,
        })
      );
      return response.data;
    } catch (err: any) {
      return err.response.data;
    }
  }
);

export const handleSubmitFinishScoring = createAsyncThunk<
  any,
  {
    applicantId: string;
    applicationId: string;
    hiringFlowId: string;
    stepId: string;
    testId: string;
  },
  { dispatch: AppDispatch }
>(
  "applicant/handleSubmitFinishScoring",
  async (
    { applicantId, applicationId, hiringFlowId, stepId, testId },
    { dispatch }
  ) => {
    try {
      // POST /application:applicationId/hiring-flows/:hiringFlowId/step/:stepId/test/:testId/finish-scoring
      const response = await http.post(
        `/application/${applicationId}/hiring-flows/${hiringFlowId}/step/${stepId}/test/${testId}/finish-scoring`
      );
      dispatch(
        getApplicantApplication({
          id: applicantId,
          applicationId,
        })
      );
      return response.data;
    } catch (err: any) {
      return err.response.data;
    }
  }
);

export const refreshApplicantApplicationModule = createAsyncThunk<
    any,
    {
        id: string;
        applicationId: string;
        stepId: number;
    },
    {}
>(
    "applicant/refreshApplicantApplicationModule",
    async ({ id, applicationId, stepId }) => {
        try {
            const response = await http.get(
                `/applicant/${id}/application/${applicationId}/${stepId}`
            );
            return response.data;
        } catch (err: any) {
            return err.response.data;
        }
    }
);

export const handleLiveInterviewScoring = createAsyncThunk<
    any,
    {
        data: {microdimensionId: string, score: number}[],
        applicationId: string;
        hiringFlowId: string;
        stepId: string;
        applicantId: string;
        initialStep: number
    },
    {}
>(
    "applicant/handleLiveInterviewScoring",
    async (
        {
            data,
            applicationId,
            hiringFlowId,
            stepId,
            applicantId,
            initialStep
        },
        { dispatch }
    ) => {
        try {
            const response = await http.post(
                `/application/${applicationId}/hiring-flows/${hiringFlowId}/step/${stepId}/score`, data
            );
            dispatch(refreshApplicantApplicationModule({
               id: applicantId,
                applicationId: applicationId,
                stepId: initialStep
            }))

            dispatch(
                setToast({
                    message: "Successfully updated score.",
                    type: "success",
                })
            );
            return response.data;
        } catch (err: any) {
            return err.response.data;
        }
    }
);

export const handleFinishScoringLiveInterview = createAsyncThunk<
    any,
    {
        applicantId: string;
        applicationId: string;
        hiringFlowId: string;
        stepId: string;
        initialStep: number
    },
    { dispatch: AppDispatch }
>(
    "applicant/handleFinishScoringLiveInterview",
    async (
        { applicantId, applicationId, hiringFlowId, stepId, initialStep },
        { dispatch }
    ) => {
        try {
            const response = await http.post(
                `/application/${applicationId}/hiring-flows/${hiringFlowId}/step/${stepId}/live-interview/finish-scoring`
            );
            dispatch(refreshApplicantApplicationModule({
                id: applicantId,
                applicationId: applicationId,
                stepId: initialStep
            }))
            return response.data;
        } catch (err: any) {
            return err.response.data;
        }
    }
);
