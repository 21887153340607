// ** React Imports
import { Suspense } from "react";

import { type routerWrapperType } from "./types";
import LoadingPageCounter from "../../views/components/LoadingPageCounter/LoadingPageCounter";

const ShieldRoute = ({ children, route }: routerWrapperType) => {
  // const user = useSelector((state) => state.authentication.userData)
  // const selectedRole = useSelector((state) => state.authentication.selectedRole)
  // const career = useSelector((state) => state.career.career)

  // if (isObjEmpty(user)) {
  //   return <Navigate to="/login" />
  // }

  // if (!checkStepsForUserRole(user, selectedRole, career)) {
  //   return <Navigate to={getHomeRouteForLoggedInUser(selectedRole)} />
  // }

  return <Suspense fallback={<LoadingPageCounter />}>{children}</Suspense>;
};

export default ShieldRoute;
