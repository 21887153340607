// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";
import {
  getPositionOverview,
  getPositionApplicationPageContent,
  startHiringFlow,
  refreshPositionOverview,
  updateAssessmentModuleFlow,
  updatePositionInfoData,
  getPositionCandidateList,
  exportPositionCandidateList,
  refreshPositionCandidateList,
  positionDeleteStep,
  createNewPositionModule,
  duplicatePosition,
  downloadPositionTemplate,
  importPositionTemplate,
  positionActionsCandidate,
  archiveHiringFlow,
  retryApplicantAssessment,
  getPositionEmailTemplate,
  savePositionEmailTemplate,
  getPositionStepEmailTemplate,
  savePositionStepEmailTemplate,
  exportPdfReportsCandidateList,
  downloadSingleReport,
  updatePositionSources,
  deletePositionSource,
  getPositionStats,
  updatePositionGeneralSettings,
} from "./position.actions";
import {
  type hiringFlowModuleType,
  positionStatsType,
} from "../common/position.types";
import { errorRequestConstant } from "common/helpers/constants.helpers";
import { errorRequestType } from "common/types/data.types";

interface positionType {
  positionOverview: {
    value: any | null;
    processing: boolean;
    loading: boolean;
    error: errorRequestType;
    refreshLoading: boolean;
    duplicatedPositionId: string;
  };
  positionCandidateList: {
    value: any | null;
    loading: boolean;
    error: errorRequestType;
  };
  positionUpdateList: {
    value: any | null;
    loading: boolean;
    error: errorRequestType;
  };
  exportCandidateList: {
    value: any | null;
    loading: boolean;
    error: errorRequestType;
  };
  downloadTemplate: {
    loading: boolean;
    error: errorRequestType;
  };
  importTemplate: {
    loading: boolean;
    error: errorRequestType;
  };
  module: hiringFlowModuleType;
  positionDeleteStep: {
    loading: boolean;
    error: errorRequestType;
    response: string;
  };
  positionCreateStep: {
    loading: boolean;
    error: errorRequestType;
  };
  initialPositionStep: {
    stepIndex: any;
  };
  emailTemplate: {
    value: any;
    loading: boolean;
    processing: boolean;
    error: errorRequestType;
  };
  singleReportDownload: {
    value: any;
    loading: boolean;
    error: errorRequestType;
  };
  sourceState: {
    processing: boolean;
  };
  positionStats: {
    value: positionStatsType | null;
    loading: boolean;
    error: errorRequestType;
  };
}

const initialState: positionType = {
  positionOverview: {
    value: null,
    processing: false,
    loading: false,
    error: {
      isError: false,
      message: "",
      type: "",
    },
    refreshLoading: false,
    duplicatedPositionId: "",
  },
  positionCandidateList: {
    value: null,
    loading: false,
    error: {
      isError: false,
      message: "",
      type: "",
    },
  },
  positionUpdateList: {
    value: null,
    loading: false,
    error: {
      isError: false,
      message: "",
      type: "",
    },
  },
  exportCandidateList: {
    value: null,
    loading: false,
    error: {
      isError: false,
      message: "",
      type: "",
    },
  },
  downloadTemplate: {
    loading: false,
    error: {
      isError: false,
      message: "",
      type: "",
    },
  },
  importTemplate: {
    loading: false,
    error: {
      isError: false,
      message: "",
      type: "",
    },
  },
  module: {
    value: null,
    processing: false,
    loading: false,
    error: errorRequestConstant,
  },
  positionDeleteStep: {
    loading: false,
    error: {
      isError: false,
      message: "",
      type: "",
    },
    response: "",
  },
  positionCreateStep: {
    loading: false,
    error: {
      isError: false,
      message: "",
      type: "",
    },
  },
  initialPositionStep: {
    stepIndex: null,
  },
  emailTemplate: {
    value: null,
    loading: false,
    processing: false,
    error: {
      isError: false,
      message: "",
      type: "",
    },
  },
  singleReportDownload: {
    value: null,
    loading: false,
    error: {
      isError: false,
      message: "",
      type: "",
    },
  },
  sourceState: {
    processing: false,
  },
  positionStats: {
    value: null,
    loading: false,
    error: {
      isError: false,
      message: "",
      type: "",
    },
  },
};

export const position = createSlice({
  name: "position",
  initialState,
  reducers: {
    clearPositionOverview: (state) => {
      state.positionOverview = initialState.positionOverview;
    },
    clearHiringFlowModule: (state) => {
      state.module = initialState.module;
    },
    selectHiringFlowModule: (state, { payload }) => {
      state.module.value = payload;
    },
    clearDeleteStepResponse: (state) => {
      state.positionDeleteStep.response = "";
    },
    clearCandidateList: (state) => {
      state.positionCandidateList = initialState.positionCandidateList;
    },
    clearExportCandidateList: (state) => {
      state.exportCandidateList.value = null;
    },
    clearSingleReportDownload: (state) => {
      state.singleReportDownload.value = null;
    },
    setInitialPositionStep: (state, { payload }) => {
      state.initialPositionStep.stepIndex = payload;
    },
    updateEmailTemplateDesign: (state, { payload }) => {
      state.emailTemplate.value = {
        ...state.emailTemplate.value,
        html: payload.html,
        json: payload.json,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPositionOverview.pending, (state) => {
        state.positionOverview.loading = true;
      })
      .addCase(getPositionOverview.fulfilled, (state, { payload }) => {
        state.positionOverview.value = payload;
        state.positionOverview.loading = false;
      })
      .addCase(refreshPositionOverview.pending, (state, { payload }) => {
        state.positionOverview.refreshLoading = true;
      })
      .addCase(refreshPositionOverview.fulfilled, (state, { payload }) => {
        state.positionOverview.value = payload;
        state.positionOverview.refreshLoading = false;
      })
      .addCase(getPositionOverview.rejected, (state, { payload }) => {
        state.positionOverview.error = {
          isError: true,
          message: payload?.message ? payload.message : "",
          type: payload?.type ? payload.type : "",
        };
        state.positionOverview.loading = false;
      })
      .addCase(getPositionApplicationPageContent.pending, (state) => {
        state.positionOverview.loading = true;
      })
      .addCase(
        getPositionApplicationPageContent.fulfilled,
        (state, { payload }) => {
          state.positionOverview.value = payload;
          state.positionOverview.loading = false;
        }
      )
      .addCase(getPositionApplicationPageContent.rejected, (state) => {
        state.positionOverview.error = { isError: true, message: "" };
        state.positionOverview.loading = false;
      })

      .addCase(startHiringFlow.pending, (state) => {
        state.positionOverview.processing = true;
      })
      .addCase(startHiringFlow.fulfilled, (state, action) => {
        state.positionOverview.processing = false;
      })
      .addCase(startHiringFlow.rejected, (state) => {
        state.positionOverview.error = { isError: true, message: "" };
        state.positionOverview.processing = false;
      })
      .addCase(archiveHiringFlow.pending, (state) => {
        state.positionOverview.processing = true;
      })
      .addCase(archiveHiringFlow.fulfilled, (state, action) => {
        state.positionOverview.processing = false;
      })
      .addCase(archiveHiringFlow.rejected, (state) => {
        state.positionOverview.error = { isError: true, message: "" };
        state.positionOverview.processing = false;
      })
      .addCase(updateAssessmentModuleFlow.pending, (state) => {
        state.module.processing = true;
      })
      .addCase(updateAssessmentModuleFlow.fulfilled, (state) => {
        state.module.processing = false;
        state.module.error = initialState.module.error;
      })
      .addCase(updateAssessmentModuleFlow.rejected, (state) => {
        state.module.error = { ...state.module.error, isError: true };
        state.module.processing = false;
      })
      .addCase(updatePositionInfoData.pending, (state) => {
        state.module.processing = true;
      })
      .addCase(updatePositionInfoData.fulfilled, (state) => {
        state.module.processing = false;
        state.module.error = initialState.module.error;
      })
      .addCase(updatePositionInfoData.rejected, (state) => {
        state.module.error = { ...state.module.error, isError: true };
        state.module.processing = false;
      })
      .addCase(getPositionCandidateList.pending, (state) => {
        state.positionCandidateList.loading = true;
      })
      .addCase(getPositionCandidateList.fulfilled, (state, { payload }) => {
        state.positionCandidateList.value = payload;
        state.positionCandidateList.loading = false;
      })
      .addCase(getPositionCandidateList.rejected, (state) => {
        state.positionCandidateList.error = { isError: true, message: "" };
        state.positionCandidateList.loading = false;
      })
      .addCase(refreshPositionCandidateList.fulfilled, (state, { payload }) => {
        state.positionCandidateList.value = payload;
      })
      .addCase(positionActionsCandidate.pending, (state) => {
        state.positionUpdateList.loading = true;
      })
      .addCase(positionActionsCandidate.fulfilled, (state, { payload }) => {
        state.positionCandidateList.value.paginatedData = {
          ...state.positionCandidateList.value.paginatedData,
          data: payload,
        };
        state.positionUpdateList.loading = false;
      })
      .addCase(positionActionsCandidate.rejected, (state) => {
        state.positionUpdateList.error = { isError: true, message: "" };
        state.positionUpdateList.loading = false;
      })
      .addCase(retryApplicantAssessment.pending, (state) => {
        state.positionUpdateList.loading = true;
      })
      .addCase(retryApplicantAssessment.fulfilled, (state, { payload }) => {
        state.positionCandidateList.value.paginatedData = {
          ...state.positionCandidateList.value.paginatedData,
          data: payload,
        };
        state.positionUpdateList.loading = false;
      })
      .addCase(retryApplicantAssessment.rejected, (state) => {
        state.positionUpdateList.error = { isError: true, message: "" };
        state.positionUpdateList.loading = false;
      })
      .addCase(exportPositionCandidateList.pending, (state) => {
        state.exportCandidateList.loading = true;
      })
      .addCase(exportPositionCandidateList.fulfilled, (state, { payload }) => {
        state.exportCandidateList.value = payload;
        state.exportCandidateList.loading = false;
      })
      .addCase(exportPositionCandidateList.rejected, (state) => {
        state.exportCandidateList.error = { isError: true, message: "" };
        state.exportCandidateList.loading = false;
      })
      .addCase(exportPdfReportsCandidateList.pending, (state) => {
        state.exportCandidateList.loading = true;
      })
      .addCase(
        exportPdfReportsCandidateList.fulfilled,
        (state, { payload }) => {
          state.exportCandidateList.value = payload;
          state.exportCandidateList.loading = false;
        }
      )
      .addCase(exportPdfReportsCandidateList.rejected, (state) => {
        state.exportCandidateList.error = { isError: true, message: "" };
        state.exportCandidateList.loading = false;
      })
      .addCase(downloadPositionTemplate.pending, (state) => {
        state.downloadTemplate.loading = true;
      })
      .addCase(downloadPositionTemplate.fulfilled, (state, { payload }) => {
        state.downloadTemplate.loading = false;
      })
      .addCase(downloadPositionTemplate.rejected, (state) => {
        state.downloadTemplate.error = { isError: true, message: "" };
        state.downloadTemplate.loading = false;
      })
      .addCase(importPositionTemplate.pending, (state) => {
        state.importTemplate.loading = true;
      })
      .addCase(importPositionTemplate.fulfilled, (state, { payload }) => {
        state.importTemplate.loading = false;
      })
      .addCase(importPositionTemplate.rejected, (state) => {
        state.importTemplate.error = { isError: true, message: "" };
        state.importTemplate.loading = false;
      })
      .addCase(positionDeleteStep.pending, (state) => {
        state.positionDeleteStep.loading = true;
      })
      .addCase(positionDeleteStep.fulfilled, (state, { payload }) => {
        state.positionDeleteStep.loading = false;
        state.positionDeleteStep.response = "success";
      })
      .addCase(positionDeleteStep.rejected, (state) => {
        state.positionDeleteStep.error = { isError: true, message: "" };
        state.positionDeleteStep.loading = false;
      })
      .addCase(createNewPositionModule.pending, (state) => {
        state.positionCreateStep.loading = true;
      })
      .addCase(createNewPositionModule.fulfilled, (state, { payload }) => {
        state.positionCreateStep.loading = false;
      })
      .addCase(createNewPositionModule.rejected, (state) => {
        state.positionCreateStep.error = { isError: true, message: "" };
        state.positionCreateStep.loading = false;
      })
      .addCase(duplicatePosition.pending, (state) => {
        state.positionOverview.processing = true;
      })
      .addCase(duplicatePosition.fulfilled, (state, { payload }) => {
        state.positionOverview.processing = false;
        state.positionOverview.duplicatedPositionId = payload;
      })
      .addCase(duplicatePosition.rejected, (state) => {
        state.positionOverview.error = { isError: true, message: "" };
        state.positionOverview.processing = false;
      })
      .addCase(getPositionEmailTemplate.pending, (state) => {
        state.emailTemplate.loading = true;
      })
      .addCase(getPositionEmailTemplate.fulfilled, (state, { payload }) => {
        state.emailTemplate.loading = false;
        state.emailTemplate.value = payload;
      })
      .addCase(getPositionEmailTemplate.rejected, (state) => {
        state.emailTemplate.error = { isError: true, message: "" };
        state.emailTemplate.loading = false;
      })
      .addCase(savePositionEmailTemplate.pending, (state) => {
        state.emailTemplate.processing = true;
      })
      .addCase(savePositionEmailTemplate.fulfilled, (state, { payload }) => {
        state.emailTemplate.processing = false;
        // state.emailTemplate.value = payload;
      })
      .addCase(savePositionEmailTemplate.rejected, (state) => {
        state.emailTemplate.error = { isError: true, message: "" };
        state.emailTemplate.processing = false;
      })
      .addCase(getPositionStepEmailTemplate.pending, (state) => {
        state.emailTemplate.processing = true;
      })
      .addCase(getPositionStepEmailTemplate.fulfilled, (state, { payload }) => {
        state.emailTemplate.processing = false;
        state.emailTemplate.value = payload;
      })
      .addCase(getPositionStepEmailTemplate.rejected, (state) => {
        state.emailTemplate.error = { isError: true, message: "" };
        state.emailTemplate.processing = false;
      })
      .addCase(savePositionStepEmailTemplate.pending, (state) => {
        state.emailTemplate.processing = true;
      })
      .addCase(
        savePositionStepEmailTemplate.fulfilled,
        (state, { payload }) => {
          state.emailTemplate.processing = false;
          // state.emailTemplate.value = payload;
        }
      )
      .addCase(savePositionStepEmailTemplate.rejected, (state) => {
        state.emailTemplate.error = { isError: true, message: "" };
        state.emailTemplate.processing = false;
      })
      .addCase(downloadSingleReport.pending, (state) => {
        state.singleReportDownload.loading = true;
      })
      .addCase(downloadSingleReport.fulfilled, (state, { payload }) => {
        state.singleReportDownload.value = payload;
        state.singleReportDownload.loading = false;
      })
      .addCase(downloadSingleReport.rejected, (state) => {
        state.singleReportDownload.error = { isError: true, message: "" };
        state.singleReportDownload.loading = false;
      })

      .addCase(updatePositionSources.pending, (state) => {
        state.sourceState.processing = true;
      })
      .addCase(updatePositionSources.fulfilled, (state, payload) => {
        state.positionOverview.value = {
          ...state.positionOverview.value,
          sources: payload.payload.slug,
        };
        state.sourceState.processing = false;
      })
      .addCase(updatePositionSources.rejected, (state) => {
        state.sourceState.processing = false;
      })

      .addCase(deletePositionSource.pending, (state) => {
        state.sourceState.processing = true;
      })
      .addCase(deletePositionSource.fulfilled, (state, payload) => {
        state.sourceState.processing = false;
        state.positionOverview.value = {
          ...state.positionOverview.value,
          sources: payload.payload,
        };
      })
      .addCase(deletePositionSource.rejected, (state) => {
        state.sourceState.processing = false;
      })
      .addCase(getPositionStats.pending, (state) => {
        state.positionStats.loading = true;
      })
      .addCase(getPositionStats.fulfilled, (state, { payload }) => {
        state.positionStats.value = payload;
        state.positionStats.loading = false;
      })
      .addCase(getPositionStats.rejected, (state, { payload }) => {
        state.positionStats.error = {
          isError: true,
          message: "",
          type: "",
        };
        state.positionStats.loading = false;
      })
      .addCase(updatePositionGeneralSettings.pending, (state) => {
        state.module.processing = true;
      })
      .addCase(updatePositionGeneralSettings.fulfilled, (state, action) => {
        state.positionOverview.value = action?.payload;
        state.module.processing = false;
        state.module.error = initialState.module.error;
      })
      .addCase(updatePositionGeneralSettings.rejected, (state) => {
        state.module.error = { ...state.module.error, isError: true };
        state.module.processing = false;
      });
  },
});

export const {
  clearPositionOverview,
  clearHiringFlowModule,
  selectHiringFlowModule,
  clearDeleteStepResponse,
  setInitialPositionStep,
  clearCandidateList,
  clearExportCandidateList,
  clearSingleReportDownload,
  updateEmailTemplateDesign,
} = position.actions;

export default position.reducer;
