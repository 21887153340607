import { type FC, lazy } from "react";
import { lazyRetry } from "common/utility/Utils";
import { type routeType } from "router/routes/types";

const MicrodimensionsWrapper: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () =>
        await import(
          "../wrappers/MicrodimensionsWrapper/MicrodimensionsWrapper"
        )
    )
);
const MicrodimensionsLibrary: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () =>
        await import("../pages/MicrodimensionsLibrary/MicrodimensionsLibrary")
    )
);
const MicrodimensionsWizard: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () =>
        await import("../pages/MicrodimensionsWizard/MicrodimensionsWizard")
    )
);

const microdimensionsRoutes: routeType[] = [
  {
    path: "/microdimensions",
    element: <MicrodimensionsWrapper />,
    permissions: "GET_MICRODIMENSIONS",
    meta: {
      layout: "app",
      type: "page",
      private: true,
      roles: ["zenhire"],
    },
    children: [
      {
        path: "",
        element: <MicrodimensionsLibrary />,
        meta: {
          title: "Microdimensions",
          type: "component",
          private: true,
        },
      },
    ],
  },
  {
    path: "/microdimensions-wizard",
    element: <MicrodimensionsWizard />,
    permissions: ["CREATE_MICRODIMENSION","UPDATE_MICRODIMENSION"],
    meta: {
      layout: "app",
      title: "Microdimensions Wizard",
      type: "page",
      private: true,
      roles: ["zenhire"],
    },
  },
];

export default microdimensionsRoutes;
