// ** Redux Imports
import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import { downloadPdfZipReports } from "./exception.actions";

type reducerType = {
  downloadFile: {
    value: null;
    loading: boolean;
    error: any;
  };
};

const initialState: reducerType = {
  downloadFile: {
    value: null,
    loading: false,
    error: false,
  },
};

export const exception = createSlice({
  name: "exception",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(downloadPdfZipReports.pending, (state) => {
        state.downloadFile.loading = true;
      })
      .addCase(downloadPdfZipReports.fulfilled, (state, { payload }) => {
        state.downloadFile.value = payload;
        state.downloadFile.loading = false;
        state.downloadFile.error = false;
      })
      .addCase(downloadPdfZipReports.rejected, (state) => {
        state.downloadFile.error = true;
        state.downloadFile.loading = false;
      });
  },
});

// export const {} = exception.actions;

export default exception.reducer;
