// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";
import {createTest, getTestTemplates, getTests, getTestsForModule} from "./testLibrary.actions";
import {
  TestTemplatesListType,
  type TestLibraryListType,
  type TestLibraryWizardType,
} from "../testLibrary.types";
interface TestLibraryType {
  testTemplatesList: TestTemplatesListType;
  testLibraryForm: TestLibraryWizardType;
  testLibraryList: TestLibraryListType;
}

const initialState: TestLibraryType = {
  testTemplatesList: {
    values: null,
    error: false,
    loading: false,
  },
  testLibraryForm: {
    navigate: null,
    processing: false,
    template: null,
    error: false,
  },
  testLibraryList: {
    loading: false,
    values: null,
    error: null,
  },
};

export const testLibrary = createSlice({
  name: "testLibrary",
  initialState,
  reducers: {
    clearTestLibraryList: (state) => {
      state.testLibraryList = initialState.testLibraryList;
    },
    clearTestTemplates: (state) => {
      state.testTemplatesList = initialState.testTemplatesList;
    },
    clearTestLibraryForm: (state) => {
      state.testLibraryForm = initialState.testLibraryForm;
    },
    selectTestWizardTemplate: (state, action) => {
      state.testLibraryForm.template = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTests.pending, (state) => {
        state.testLibraryList.loading = true;
      })
      .addCase(getTests.fulfilled, (state, { payload }) => {
        state.testLibraryList.values = payload;
        state.testLibraryList.loading = false;
      })
      .addCase(getTests.rejected, (state) => {
        state.testLibraryList.error = true;
        state.testLibraryList.loading = false;
      })
      .addCase(getTestsForModule.pending, (state) => {
        state.testLibraryList.loading = true;
      })
      .addCase(getTestsForModule.fulfilled, (state, { payload }) => {
        state.testLibraryList.values = payload;
        state.testLibraryList.loading = false;
      })
      .addCase(getTestsForModule.rejected, (state) => {
        state.testLibraryList.error = true;
        state.testLibraryList.loading = false;
      })
      .addCase(createTest.pending, (state) => {
        state.testLibraryForm.processing = true;
      })
      .addCase(createTest.fulfilled, (state, action) => {
        state.testLibraryForm.template = null;
        state.testLibraryForm.navigate = action.payload._id;
        state.testLibraryForm.processing = false;
      })
      .addCase(createTest.rejected, (state) => {
        state.testLibraryForm.error = true;
        state.testLibraryForm.processing = false;
      })
      .addCase(getTestTemplates.pending, (state) => {
        state.testTemplatesList.loading = true;
      })
      .addCase(getTestTemplates.fulfilled, (state, { payload }) => {
        state.testTemplatesList.values = payload;
        state.testTemplatesList.loading = false;
      })
      .addCase(getTestTemplates.rejected, (state, action) => {
        state.testTemplatesList.error = true;
        state.testTemplatesList.loading = false;
      });
  },
});

export const {
  clearTestLibraryList,
  selectTestWizardTemplate,
  clearTestLibraryForm,
  clearTestTemplates,
} = testLibrary.actions;

export default testLibrary.reducer;
