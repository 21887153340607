// ** Reducers Imports
import global from "./global";
import navigation from "../router/reducer/navigation";
import alert from "./alert";
import authentication from "views/features/auth/reducer/authentication";
import testSimulation from "views/features/testSimulation/reducer/testSimulation.reducer";
import microdimensions from "views/features/microdimensions/reducer/microdimensions.reducer";
import testLibrary from "views/features/testLibrary/reducer/testLibrary.reducer";
import testSingle from "views/features/testSingle/reducer/testSingle.reducer";
import users from "views/features/users/reducer/users.reducer";
import applicants from "views/features/applicants/reducer/applicants.reducer";
import applicant from "views/features/applicant/reducer/applicant.reducer";
import positions from "views/features/positions/reducer/positions.reducer";
import companies from "views/features/companies/reducer/companies.reducer";
import company from "../views/features/company/reducer/company.reducer";
import position from "views/features/position/reducer/position.reducer";
import application from "views/features/application/reducer/application.reducer";
import globalFilters from "./globalFilters";
import systemEmail from "views/features/systemEmails/reducer/systemEmails.reducer";
import exception from "views/features/exception/reducer/exception.reducer";
import companyDashboard from "views/features/company/companyDashboard/reducer/companyDashboard.reducer";

const rootReducer = {
    global,
    navigation,
    alert,
    exception,
    authentication,
    systemEmail,
    microdimensions,
    testLibrary,
    testSingle,
    testSimulation,
    users,
    applicants,
    applicant,
    application,
    positions,
    companies,
    company,
    position,
    globalFilters,
    companyDashboard
};

export default rootReducer;
