import { type FC, lazy } from "react";
import { lazyRetry } from "common/utility/Utils";
import { type routeType } from "router/routes/types";
import { Navigate } from "react-router";
const CompanySettingsWrapper: FC | any = lazy(
  async () =>
    await lazyRetry(async () => await import("../wrappers/SettingsWrapper"))
);
const CompanySettingsInfo: FC | any = lazy(
  async () =>
    await lazyRetry(async () => await import("../pages/CompanySettingsInfo"))
);
const CompanySettingsIdentity: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () => await import("../pages/CompanySettingsIdentity")
    )
);
const SettingsWrapper: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () =>
        await import("../../../auth/wrappers/SettingsWrapper/SettingsWrapper")
    )
);
const SettingsGeneral: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () => await import("../../../auth/pages/Settings/SettingsGeneral")
    )
);
const SettingsSecurity: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () => await import("../../../auth/pages/Settings/SettingsSecurity")
    )
);

const companyAuthRoutes: routeType[] = [
  {
    path: ":companyId/settings",
    element: <CompanySettingsWrapper />,
    permissions: "UPDATE_COMPANY",
    meta: {
      layout: "app",
      type: "page",
      private: true,
    },
    children: [
      {
        path: "",
        element: <CompanySettingsInfo />,
        meta: {
          title: "Company Info",
          type: "component",
          private: true,
        },
      },
      {
        path: "visual-identity",
        element: <CompanySettingsIdentity />,
        meta: {
          title: "Visual Identity",
          type: "component",
          private: true,
        },
      },
    ],
  },
  {
    path: ":companyId/profile",
    element: <SettingsWrapper />,
    permissions: "UPDATE_USER",
    meta: {
      layout: "app",
      title: "Settings",
      type: "page",
      private: true,
      roles: ["zenhire"],
    },
    children: [
      {
        path: "general",
        element: <SettingsGeneral />,
        meta: {
          title: "General",
          type: "component",
          private: true,
        },
      },
      {
        path: "security",
        element: <SettingsSecurity />,
        meta: {
          title: "Security",
          type: "component",
          private: true,
        },
      },
    ],
  },
];
export default companyAuthRoutes;
