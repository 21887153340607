import { type FC, lazy } from "react";
import { lazyRetry } from "common/utility/Utils";
import { type routeType } from "router/routes/types";

const TestWrapper: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () =>
        await import(
          "views/features/testSingle/wrappers/TestWrapper/TestWrapper"
        )
    )
);
const TestOverview: FC | any = lazy(() =>
  lazyRetry(
    () => import("views/features/testSingle/pages/TestOverview/TestOverview")
  )
);

const companyTestSingleRoutes: routeType[] = [
  {
    path: ":companyId/test/:id",
    element: <TestWrapper />,
    permissions: "GET_TESTS",
    meta: {
      layout: "app",
      type: "page",
      private: true,
    },
    children: [
      {
        path: "",
        element: <TestOverview />,
        permissions: ["GET_TESTS_PUBLISHED"],
        meta: {
          title: "Overview",
          type: "component",
          private: true,
        },
      },
    ],
  },
];
export default companyTestSingleRoutes;
