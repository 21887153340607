// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";

interface filtersType {
    globalFilters: {
        [key: string]: any;
    } | any,
}

const initialState: filtersType = {
    globalFilters: {},
};
export const globalFiltersSlice = createSlice({
    name: "globalFilters",
    initialState,

    reducers: {
        setGlobalFilters: (state, action) => {
            state.globalFilters = {
                ...state.globalFilters,
                [action.payload.page]: {
                    ...state.globalFilters[action.payload.page],
                    filters: action.payload.filters,
                    pagination: 1
                },
            };
        },
        setCustomGlobalFilters: (state, action) => {
            state.globalFilters = {
                ...state.globalFilters,
                [action.payload.page]: {
                    ...state.globalFilters[action.payload.page],
                    customFilters: state.globalFilters[action.payload.page]?.customFilters ?
                        {...state.globalFilters[action.payload.page]?.customFilters, ...action.payload.customFilters} :
                        action.payload.customFilters,
                    pagination: 1
                },
            };
        },
        setGlobalSearch: (state, action) => {
            state.globalFilters = {
                ...state.globalFilters,
                [action.payload.page]: {
                    ...state.globalFilters[action.payload.page],
                    search: action.payload.search,
                    pagination: 1
                },
            };
        },
        setGlobalPagination: (state, action) => {
            state.globalFilters = {
                ...state.globalFilters,
                [action.payload.page]: {
                    ...state.globalFilters[action.payload.page],
                    pagination: action?.payload?.pagination ? action?.payload?.pagination : 1,
                    limit: action?.payload?.limit ? action?.payload?.limit : state.globalFilters[action.payload.page]?.limit || 10  ,
                },
            };
        },
        clearGlobalFilters: (state, action) => {
            state.globalFilters = {
                ...state.globalFilters,
                [action.payload.page]: {
                    filters: state.globalFilters[action.payload.page]?.filters.sort && !action?.payload?.clearFiltersSort ? {sort:state.globalFilters[action.payload.page]?.filters.sort} : {},
                    search: state.globalFilters[action.payload.page]?.search || "",
                    pagination: 1,
                    customFilters:  state.globalFilters[action.payload.page]?.customFilters ? state.globalFilters[action.payload.page]?.customFilters  : {}
                },
            };
        },
        clearCustomGlobalFilters: (state, action) => {
            state.globalFilters = {
                ...state.globalFilters,
                [action.payload.page]: {
                    ...state.globalFilters[action.payload.page],
                    customFilters: action.payload.customFilters,
                    pagination: 1
                },
            };
        },
    },
    extraReducers: (builder) => {},
});
export const {
    setGlobalFilters,
    setCustomGlobalFilters,
    clearCustomGlobalFilters,
    clearGlobalFilters,
    setGlobalSearch,
    setGlobalPagination
} = globalFiltersSlice.actions;
export default globalFiltersSlice.reducer;