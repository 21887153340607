import { publicHttp } from "common/api/public-http";

export const resendEmail = async ({
  applicationId,
  email,
}: {
  applicationId: string;
  email?: string;
}) =>
  await publicHttp.post(
    `/application/${applicationId}/resend-verification-email`
  );

export const fetchPositionApplicationPageContent = async (id: string) => {
  const response = await publicHttp.get(`/positions/${id}/public`);
  return response;
};
