// All reusable functions used for ZenHire app specifically

import {
  audioTypes,
  codecs,
  supportedMimeTypesChrome,
  supportedMimeTypesMozilla,
  supportedMimeTypesSafari,
  supportedMimeTypesEdge,
  videoTypes,
} from "./constants.helpers";
import { EmailTypeEnum } from "views/components/EmailTemplateBuilder/emailTemplateEnum";
import { formatDate, isDateValid } from "common/utility/Utils";

export function renderFileUrl(url: string) {
  if (!url?.includes("http://") && !url?.includes("https://")) {
    return `${process.env.REACT_APP_BASENAME}${url}`;
  }
  return url.includes("http://") && !url.includes("blob")
    ? url.replace("http://", "https://")
    : url;
}

export function scrollTop() {
  window.scrollTo(0, 0);
}

export const getInitials = (fullName: string) => {
  const allNames = fullName.trim().split(" ");
  const initials = allNames.reduce((acc, curr, index) => {
    if (index === 0 || index === allNames.length - 1) {
      acc = `${acc}${curr.charAt(0).toUpperCase()}`;
    }
    return acc;
  }, "");
  return initials;
};

export function microdimensionsUnique(array: any[]) {
  var a = array.filter((m: any) => !!m);

  for (var i = 0; i < a.length; ++i) {
    for (var j = i + 1; j < a.length; ++j) {
      if (a[i]._id === a[j]._id) {
        a.splice(j--, 1);
      }
    }
  }

  return a;
}

export function getSpecificTestRouteName(template: any) {
  switch (template) {
    case "CLOSED_QUESTIONS":
      return "qa-test";
    case "OPEN_QUESTIONS":
      return "qa-test";
    case "PRE_INTERVIEW":
      return "preinterview-test";
    case "TYPING_SPEED":
      return "typing-test";
    case "STATEMENT_SCALE":
      return "scale-test";
    case "QUALIFYING_QUESTIONS":
      return "qa-test";
    default:
      return "test";
  }
}

function checkSupportedMimeTypes(types: string[]) {
  const isSupported = MediaRecorder.isTypeSupported;
  var supported: any[] = [];
  for (let i = 0; i < types.length; i++) {
    supported.push({ type: types[i], isSupported: isSupported(types[i]) });
  }
}

function getSupportedMimeType(types: string[]) {
  const isSupported = MediaRecorder.isTypeSupported;
  for (let i = 0; i < types.length; i++) {
    if (isSupported(types[i])) {
      return types[i];
    }
  }
  return "";
}

export function getBrowserSupportedMimeType(browser: string) {
  switch (browser) {
    case "chrome":
      checkSupportedMimeTypes(supportedMimeTypesChrome);
      return getSupportedMimeType(supportedMimeTypesChrome);
    case "safari":
      checkSupportedMimeTypes(supportedMimeTypesSafari);
      return getSupportedMimeType(supportedMimeTypesSafari);
    case "mozilla":
      checkSupportedMimeTypes(supportedMimeTypesMozilla);
      return getSupportedMimeType(supportedMimeTypesMozilla);
    case "edge":
      checkSupportedMimeTypes(supportedMimeTypesEdge);
      return getSupportedMimeType(supportedMimeTypesEdge);
    case "edge-chromium":
      checkSupportedMimeTypes(supportedMimeTypesEdge);
      return getSupportedMimeType(supportedMimeTypesEdge);
    default:
      return "";
  }
}

export function getSupportedMimeTypes() {
  // media: string
  // types: string[]
  // codecs: string[]
  var tempVideoTypes = videoTypes;
  var tempAudioTypes = audioTypes;
  var tempCodecs = codecs;

  const isSupported = MediaRecorder.isTypeSupported;
  const supportedVideo: string[] = [];
  const supportedAudio: string[] = [];
  const supportedVideoTemp: { type: string; codec: string }[] = [];
  const supportedAudioTemp: { type: string; codec: string }[] = [];
  tempVideoTypes.forEach((type) => {
    const mimeType = `video/${type}`;
    tempCodecs.forEach((codec) =>
      [
        `${mimeType};codecs=${codec}`,
        `${mimeType};codecs=${codec.toUpperCase()}`,
        // /!\ false positive /!\
        // `${mimeType};codecs:${codec}`,
        // `${mimeType};codecs:${codec.toUpperCase()}`
      ].forEach((variation) => {
        if (isSupported(variation)) {
          supportedVideo.push(variation);
          supportedVideoTemp.push({ type, codec });
        }
      })
    );
    if (isSupported(mimeType)) {
      supportedVideo.push(mimeType);
      supportedVideoTemp.push({ type, codec: "" });
    }
  });
  tempAudioTypes.forEach((type) => {
    const mimeType = `audio/${type}`;
    tempCodecs.forEach((codec) =>
      [
        `${mimeType};codecs=${codec}`,
        `${mimeType};codecs=${codec.toUpperCase()}`,
        // /!\ false positive /!\
        // `${mimeType};codecs:${codec}`,
        // `${mimeType};codecs:${codec.toUpperCase()}`
      ].forEach((variation) => {
        if (isSupported(variation)) {
          supportedAudio.push(variation);
          supportedAudioTemp.push({ type, codec });
        }
      })
    );
    if (isSupported(mimeType)) {
      supportedAudio.push(mimeType);
      supportedVideoTemp.push({ type, codec: "" });
    }
  });
  let universalType = "";
  let universalCodec = "";
  let audioCodec = "";
  let videoCodec = "";
  for (let i = 0; i < supportedVideoTemp.length; i++) {
    for (let j = 0; j < supportedAudioTemp.length; j++) {
      if (supportedVideoTemp[i].type === supportedAudioTemp[j].type) {
        universalType = supportedVideoTemp[i].type;
        if (supportedVideoTemp[i].codec === supportedAudioTemp[j].codec) {
          universalCodec = supportedVideoTemp[i].codec;
        } else {
          audioCodec = supportedAudioTemp[j].codec;
          videoCodec = supportedVideoTemp[i].codec;
        }
      }
    }
  }

  return supportedVideo.concat(supportedAudio);
}

export function getHumanizedApplicationState(value: string) {
  switch (value) {
    case "IN_PROGRESS":
      return "In Process";
    case "COMPLETED":
      return "Completed";
    case "NOT_STARTED":
      return "Not Started yet";
    case "UNSCORED":
      return "Not scored";
    case "REJECTED":
      return "Rejected";
    case "PASSED":
      return "Passed";
    case "TIMEDOUT":
      return "Timed out";
    case "HIRED":
      return "Hired";
    default:
      return "Not started yet";
  }
}

export const areArraysDifferent = (a: any[], b: any[]) => {
  return JSON.stringify(a) !== JSON.stringify(b);
};

export const getTestContentsType = (template: string) => {
  switch (template) {
    case "CLOSED_QUESTIONS":
    case "OPEN_QUESTIONS":
    case "PRE_INTERVIEW":
      return "Question";
    case "TYPING_SPEED":
      return "Sentence";
    case "STATEMENT_SCALE":
      return "Statement";
    default:
      return "Question";
  }
};

export const getLightenColor = (
  originalColor: string,
  lightenAmount: number
) => {
  const lighten = (color: string, amount: number) => {
    // Parse the color into RGB components
    const hex = color.replace(/^#/, "");
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    // Calculate the new color values
    const newR = Math.min(255, r + amount * (255 - r));
    const newG = Math.min(255, g + amount * (255 - g));
    const newB = Math.min(255, b + amount * (255 - b));

    // Convert back to hex
    const newColor = `#${Math.round(newR).toString(16)}${Math.round(
      newG
    ).toString(16)}${Math.round(newB).toString(16)}`;

    return newColor;
  };

  return lighten(originalColor, lightenAmount);
};

export function getHiringStatusColors(value: string) {
  switch (value.toUpperCase()) {
    case "IN_PROGRESS":
      return "bg-[#D2EFFF] text-[#1D85BF]";
    case "COMPLETED":
      return "bg-[#D2FFFF] text-[#249F97]";
    case "NOT_STARTED":
      return "bg-ultra-light-gray text-gray";
    case "UNSCORED":
      return "bg-[#FFF2D2] text-[#B87A1D]";
    case "REJECTED":
      return "bg-[#FFD2D8] text-[#C84040]";
    case "PASSED":
      return "bg-light-main-green text-green";
    case "TIMEDOUT":
      return "bg-[#FFD2D8] text-[#C84040]";
    case "HIRED":
      return "bg-[#D6FFDA] text-[#49A252]";
    default:
      return "bg-[#E6E6E6] text-[#787878]";
  }
}

export const formatEmailType = (emailType: string) => {
  switch (emailType) {
    case EmailTypeEnum.APPLICANT_HIRED:
      return "position-hired-email";
    case EmailTypeEnum.APPLICANT_REJECTED:
      return "position-rejected-email";
    case EmailTypeEnum.APPLICATION_CREATED_FOR_POSITION:
      return "position-apply-email";
    case EmailTypeEnum.APPLICATION_CREATED_FOR_POSITION_AUTOMATIC_PROCEED:
      return "position-apply-auto-proceed-email";
    case EmailTypeEnum.APPLICATION_REMINDER_NOT_STARTED:
      return "assessment-not-started-reminder-email";
    case EmailTypeEnum.APPLICATION_REMINDER_NOT_COMPLETED:
      return "continue-assessment-reminder-email";
    case EmailTypeEnum.APPLICATION_STEP_ACCESS:
      return "assessment-access-email";
    case EmailTypeEnum.APPLICATION_STEP_RESTART:
      return "assessment-retry-email";
    case EmailTypeEnum.APPLICATION_STEP_ACCESS_LIVEINTERVIEW:
      return "live-interview-email";
    case EmailTypeEnum.APPLICATION_CREATED_FOR_TEST_SIMULATION:
      return "test-application-email";
    default:
      return "";
  }
};

export const getEmailTypeEnum = (emailName: string) => {
  switch (emailName) {
    case "position-hired-email":
      return EmailTypeEnum.APPLICANT_HIRED;
    case "position-rejected-email":
      return EmailTypeEnum.APPLICANT_REJECTED;
    case "position-apply-email":
      return EmailTypeEnum.APPLICATION_CREATED_FOR_POSITION;
    case "position-apply-auto-proceed-email":
      return EmailTypeEnum.APPLICATION_CREATED_FOR_POSITION_AUTOMATIC_PROCEED;
    case "assessment-not-started-reminder-email":
      return EmailTypeEnum.APPLICATION_REMINDER_NOT_STARTED;
    case "continue-assessment-reminder-email":
      return EmailTypeEnum.APPLICATION_REMINDER_NOT_COMPLETED;
    case "assessment-access-email":
      return EmailTypeEnum.APPLICATION_STEP_ACCESS;
    case "assessment-retry-email":
      return EmailTypeEnum.APPLICATION_STEP_RESTART;
    case "live-interview-email":
      return EmailTypeEnum.APPLICATION_STEP_ACCESS_LIVEINTERVIEW;
    case "test-application-email":
      return EmailTypeEnum.APPLICATION_CREATED_FOR_TEST_SIMULATION;
    default:
      return "";
  }
};

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export function convertMonthsToYearsAndMonths(monthsInput: number) {
  let years = Math.floor(monthsInput / 12);
  let months = monthsInput % 12;
  if (!years && !months) {
    return null;
  }
  let sentence = !years
    ? `${months ? `${months} month${months > 1 ? "s" : ""}` : ""}`
    : `${years} year${years > 1 ? "s" : ""}${
        months ? ` and ${months} month${months > 1 ? "s" : ""}` : ""
      }`;

  return sentence;
}

export function isApplicationDateExpired(date?: string) {
  if (date) {
    const positionExpiredDate = new Date(
      formatDate(date ? date : "", {
        day: "numeric",
        month: "numeric",
        year: "numeric",
      })
    );
    const todaysDate = new Date();

    return !isDateValid(todaysDate, positionExpiredDate);
  } else {
    return false;
  }
}

export const formatPriceForBe = (value: string) => {
  if (value) {
    return value.replace(/\./g, "");
  } else {
    return "";
  }
};

export function findQuestionTextEditorField(node: any, flag: string): string[] {
  let texts: string[] = [];

  if (node[flag]) {
    texts.push(node[flag]);
  }
  if (node.children) {
    node.children.forEach((child: any) => {
      texts = texts.concat(findQuestionTextEditorField(child, flag));
    });
  }

  return texts;
}

export function formatCompanyRoute(
  id: string | null | undefined,
  route: string
) {
  return `${id ? `/${id}` : ""}${route}`;
}
