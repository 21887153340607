import { type FC, lazy } from "react";
import { lazyRetry } from "common/utility/Utils";
import { type routeType } from "router/routes/types";

const PositionWrapper: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () => await import("../wrappers/PositionWrapper/PositionWrapper")
    )
);
const PositionOverview: FC | any = lazy(() =>
  lazyRetry(() => import("../pages/PositionOverview/PositionOverview"))
);
const HiringFlowEditor: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () => await import("../pages/HiringFlowEditor/HiringFlowEditor")
    )
);
const HiringFlowBlank: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () => await import("../components/HiringFlowBlank/HiringFlowBlank")
    )
);
const HiringFlowModule: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () =>
        await import("../components/HiringFlowModule/HiringFlowModule")
    )
);

const CandidateList: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () => await import("../pages/CandidateList/CandidateList")
    )
);
const PositionSettings: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () => await import("../pages/PositionSettings/PositionSettings")
    )
);
const PositionCompetenciesSettings: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () =>
        await import("../pages/PositionSettings/PositionCompetenciesSettings")
    )
);

const PositionSettingsWrapper: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () =>
        await import(
          "../wrappers/PositionSettingsWrapper/PositionSettingsWrapper"
        )
    )
);

const PositionEmailTemplateEditorPage: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () =>
        await import(
          "../moduleSpecificComponents/PositionEmailTemplateEditorPage/PositionEmailTemplateEditorPage"
        )
    )
);

const PositionStepEmailTemplateEditorPage: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () =>
        await import(
          "../moduleSpecificComponents/PositionStepEmailTemplateEditorPage/PositionStepEmailTemplateEditorPage"
        )
    )
);

const PositionAnalyticsWrapper: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () =>
        await import(
          "../wrappers/PositionAnalyticsWrapper/PositionAnalyticsWrapper"
        )
    )
);

const PositionAnalytics: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () => await import("../pages/PositionAnalytics/PositionAnalytics")
    )
);

const PositionGeneralSettings: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () =>
        await import("../pages/PositionSettings/PositionGeneralSettings")
    )
);

const positionRoutes: routeType[] = [
  {
    path: "/position/:id",
    element: <PositionWrapper />,
    permissions: "GET_POSITIONS",
    meta: {
      layout: "app",
      type: "page",
      private: true,
      roles: ["zenhire"],
    },
    children: [
      {
        path: "",
        element: <PositionOverview />,
        meta: {
          title: "Overview",
          type: "component",
          private: true,
        },
      },
      {
        path: "hiring-flow",
        element: <HiringFlowEditor />,
        meta: {
          title: "Hiring Flow",
          type: "component",
          private: true,
        },
        children: [
          {
            path: "",
            element: <HiringFlowBlank />,
            meta: {
              type: "component",
              private: true,
            },
          },
          {
            path: "step/:stepId",
            element: <HiringFlowModule />,
            meta: {
              type: "component",
              private: true,
            },
            children: [
              {
                path: "email-template-editor/:emailType",
                element: <PositionStepEmailTemplateEditorPage />,
                meta: {
                  type: "component",
                  private: true,
                },
              },
              {
                path: "candidate-list",
                element: <CandidateList />,
                permissions: "GET_APPLICANTS",
                meta: {
                  title: "Applicants List",
                  type: "component",
                  private: true,
                },
              },
            ],
          },
        ],
      },
      {
        path: "settings",
        element: <PositionSettingsWrapper />,
        permissions: "UPDATE_POSITION",
        meta: {
          title: "Position settings",
          type: "component",
          private: true,
        },
        children: [
          {
            path: "",
            element: <PositionGeneralSettings />,
            allPermissionsCheck: ["GET_POSITIONS", "UPDATE_POSITION"],
            meta: {
              type: "component",
              private: true,
            },
          },
          {
            path: "emails",
            element: <PositionSettings />,
            meta: {
              type: "component",
              private: true,
            },
            children: [
              // Position Template Editor
              {
                path: "email-template-editor/:emailType",
                element: <PositionEmailTemplateEditorPage />,
                meta: {
                  title: "Email template editor",
                  type: "component",
                  private: true,
                },
              },
            ],
          },
          {
            path: "competencies",
            element: <PositionCompetenciesSettings />,
            allPermissionsCheck: ["GET_POSITIONS", "UPDATE_POSITION"],
            meta: {
              type: "component",
              private: true,
            },
          },
        ],
      },
      {
        path: "analytics",
        element: <PositionAnalyticsWrapper />,
        allPermissionsCheck: ["GET_POSITIONS", "GET_POSITION_STATS"],
        meta: {
          title: "Position analytics",
          type: "component",
          private: true,
        },
        children: [
          {
            path: "",
            element: <PositionAnalytics />,
            meta: {
              type: "component",
              private: true,
            },
          },
        ],
      },
    ],
  },
];

export default positionRoutes;
