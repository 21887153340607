import { type FC, lazy } from "react";
import { lazyRetry } from "common/utility/Utils";
import { type routeType } from "router/routes/types";

const TestWrapper: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () => await import("../wrappers/TestWrapper/TestWrapper")
    )
);
const TestOverview: FC | any = lazy(() =>
  lazyRetry(() => import("../pages/TestOverview/TestOverview"))
);
const TestEditor: FC | any = lazy(
  async () =>
    await lazyRetry(async () => await import("../pages/TestEditor/TestEditor"))
);
const TestPublishing: FC | any = lazy(() =>
  lazyRetry(() => import("../pages/TestPublishing/TestPublishing"))
);
const TestResultsHistory: FC | any = lazy(() =>
  lazyRetry(() => import("../pages/TestResultsHistory/TestResultsHistory"))
);
const ConfigurationController: FC | any = lazy(() =>
  lazyRetry(
    () =>
      import("../components/ConfigurationController/ConfigurationController")
  )
);
const QuestionEditorController: FC | any = lazy(() =>
  lazyRetry(
    () =>
      import("../components/QuestionEditorController/QuestionEditorController")
  )
);

const testSingleRoutes: routeType[] = [
  {
    path: "/test/:id",
    element: <TestWrapper />,
    permissions: "GET_TESTS",
    meta: {
      layout: "app",
      type: "page",
      private: true,
      roles: ["zenhire"],
    },
    children: [
      {
        path: "",
        element: <TestOverview />,
        meta: {
          title: "Overview",
          type: "component",
          private: true,
        },
      },
      {
        path: "content",
        element: <TestEditor />,
        permissions: "GET_TEST_CONTENTS",
        meta: {
          title: "Content",
          type: "component",
          private: true,
        },
        children: [
          {
            path: "",
            element: <QuestionEditorController />,
            meta: {
              title: "Configuration",
              type: "component",
              private: true,
            },
          },
          {
            path: "configuration",
            element: <ConfigurationController />,
            meta: {
              title: "Configuration",
              type: "component",
              private: true,
            },
          },
        ],
      },

      {
        path: "publishing",
        element: <TestPublishing />,
        permissions: [
          "PUBLISH_TEST",
          "INCUBATE_TEST",
          "ARCHIVE_TEST",
          "DELETE_TEST",
        ],
        meta: {
          title: "Publishing",
          type: "component",
          private: true,
        },
      },
      {
        path: "results-history",
        element: <TestResultsHistory />,
        permissions: "GET_TEST_TAKERS",
        meta: {
          title: "Results History",
          type: "component",
          private: true,
        },
      },
    ],
  },
];

export default testSingleRoutes;
