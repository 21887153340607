import { publicHttp } from "common/api/public-http";
import { testCandidateHttp } from "common/api/testCandidate-http";
import { getSpecificTestRouteName } from "common/helpers/functions.helpers";

export function sendChunkToBackend({
  applicationId,
  hiringFlowId,
  stepId,
  testId,
  questionId,
  chunk,
  partId,
}: {
  applicationId: string;
  hiringFlowId: string;
  stepId: string;
  testId: string;
  questionId: string;
  chunk: any;
  partId: number;
}): Promise<number> {
  return new Promise(async (resolve) => {
    const formData = new FormData();
    formData.append("data", chunk, "recording-part-" + partId + ".webm");
    formData.append("partId", `${partId}`);
    formData.append("questionId", questionId);
    formData.append("testId", testId);

    try {
      const response = await testCandidateHttp.put(
        `/application/${applicationId}/hiring-flows/${hiringFlowId}/step/${stepId}/preinterview-test/${testId}/question/${questionId}/answer/chunk`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );

      console.log(
        "Chunk " + partId + " sent to the backend successfully.",
        response
      );
      resolve(partId);
    } catch (error) {
      console.error(
        "Error sending chunk " + partId + " to the backend:",
        error
      );
      resolve(partId - 1);
    }
  });
}

export const resendEmail = async ({
  applicationId,
  email,
}: {
  applicationId: string;
  email?: string;
}) =>
  await publicHttp.post(
    `/application/${applicationId}/resend-verification-email`
  );

export const mimeTypeToFormatAndCodecs = (mimeType: string) => {
  var format = "";
  var codecs = "";
  if (mimeType.includes("video/")) {
    format = mimeType.includes(";")
      ? mimeType.split(";")[0].split("/")[1]
      : mimeType.split("/")[1];
    codecs = mimeType.includes(";") ? mimeType.split(";")[1].split("=")[1] : "";
  }
  return { format, codecs };
  // Send a request to the backend to notify about recording stop
};

export const fetchTestSimulationQuestions = async ({
  isDemo,
  template,
  applicationId,
  hiringFlowId,
  stepId,
  testId,
}: {
  isDemo: boolean;
  testId: string;
  template: string | null;
  applicationId?: string;
  hiringFlowId?: string;
  stepId?: string;
}) => {
  const response = await testCandidateHttp.get(
    !isDemo
      ? `/application/${applicationId}/hiring-flows/${hiringFlowId}/step/${stepId}/${getSpecificTestRouteName(
          template
        )}/${testId}/questions`
      : `/tests/${testId}/questions?isDemo=${isDemo}`
  );
  return response;
};
