import { createAsyncThunk } from "@reduxjs/toolkit";
import { publicHttp } from "common/api/public-http";
import { testCandidateHttp } from "common/api/testCandidate-http";
import { errorRequestType } from "common/types/data.types";
import { setIsQuestionDemo, setIsTestDemo } from "./testSimulation.reducer";
import { RootState } from "redux/store";
import { getSpecificTestRouteName } from "common/helpers/functions.helpers";
import { fetchTestSimulationQuestions } from "./testSimulation.services";

export const getTestSimulationInfo = createAsyncThunk<
  any,
  { id: string; isDemo?: boolean },
  {
    rejectValue: errorRequestType;
    state: RootState;
  }
>(
  "testSimulation/getTestSimulationInfo",
  async ({ id, isDemo }, { rejectWithValue, dispatch, getState }) => {
    try {
      var module = getState().application.module.value;
      var test = module?.userData?.tests
        ? module.userData.tests.find((m: any) => m._id === id)
        : null;
      const response = test?._id
        ? { data: test }
        : await publicHttp.get(`/tests/${id}/simulation/overview`);
      dispatch(setIsTestDemo(isDemo || false));
      return response.data;
    } catch (err: any) {
      console.log("error", err);
      return rejectWithValue({
        isError: true,
        message: err.data.message,
        code: "invalid",
      } as errorRequestType);
    }
  }
);

export const getTestSimulationQuestions = createAsyncThunk<
  any,
  {
    isDemo: boolean;
    testId: string;
    applicationId?: string;
    hiringFlowId?: string;
    stepId?: string;
  },
  {
    rejectValue: errorRequestType;
    state: RootState;
  }
>(
  "testSimulation/getTestSimulationQuestions",
  async (
    { applicationId, hiringFlowId, stepId, testId, isDemo },
    { rejectWithValue, getState }
  ) => {
    try {
      const template =
        getState().testSimulation.testSimulationInfo.value?.template?.enum ||
        null;

      const response = await fetchTestSimulationQuestions({
        applicationId,
        hiringFlowId,
        template,
        stepId,
        testId,
        isDemo,
      });
      return response.data.filter(
        (v: any) =>
          v.appliedState !== "COMPLETED" && v.appliedState !== "UNSCORED"
      );
    } catch (err: any) {
      return rejectWithValue({
        isError: true,
        message: err.data.message,
        code: "invalid",
      } as errorRequestType);
    }
  }
);

export const getTestQuestionsMenuList = createAsyncThunk<
  any,
  {
    testId: string;
    applicationId?: string;
    hiringFlowId?: string;
    stepId?: string;
  },
  {
    rejectValue: errorRequestType;
    state: RootState;
  }
>(
  "testSimulation/getTestQuestionsMenuList",
  async (
    { applicationId, hiringFlowId, stepId, testId },
    { rejectWithValue, getState }
  ) => {
    try {
      const template =
        getState().testSimulation.testSimulationInfo.value?.template?.enum ||
        null;

      const response = await fetchTestSimulationQuestions({
        applicationId,
        hiringFlowId,
        template,
        stepId,
        testId,
        isDemo: false,
      });

      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        isError: true,
        message: err.data.message,
        code: "invalid",
      } as errorRequestType);
    }
  }
);

export const getTestSimulationQuestion = createAsyncThunk<
  any,
  {
    applicationId?: string;
    hiringFlowId?: string;
    stepId?: string;
    testId: string;
    questionId: string;
    isDemo?: boolean;
  },
  {
    rejectValue: errorRequestType;
    state: RootState;
  }
>(
  "testSimulation/getTestSimulationQuestion",
  async (
    { applicationId, hiringFlowId, stepId, testId, questionId, isDemo },
    { rejectWithValue, getState, dispatch }
  ) => {
    try {
      const template =
        getState().testSimulation.testSimulationInfo.value?.template?.enum;
      const response = await testCandidateHttp.get(
        isDemo
          ? `/tests/${testId}/questions/demo/${questionId}`
          : `/application/${applicationId}/hiring-flows/${hiringFlowId}/step/${stepId}/${getSpecificTestRouteName(
              template
            )}/${testId}/question/${questionId}`
      );

      dispatch(setIsQuestionDemo(isDemo || false));
      if (!isDemo) {
        dispatch(
          getTestQuestionsMenuList({
            applicationId: applicationId,
            hiringFlowId: hiringFlowId,
            stepId: stepId,
            testId: testId,
          })
        );
      }
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        isError: true,
        message: err.data.message,
        code: "invalid",
      } as errorRequestType);
    }
  }
);

export const submitTestSimulationQuestionAnswer = createAsyncThunk<
  any,
  {
    applicationId: string;
    hiringFlowId: string;
    stepId: string;
    testId: string;
    questionId: string;
    body: any;
  },
  {
    rejectValue: errorRequestType;
    state: RootState;
  }
>(
  "testSimulation/submitTestSimulationQuestionAnswer",
  async (
    { applicationId, hiringFlowId, stepId, testId, questionId, body },
    { rejectWithValue, getState, dispatch }
  ) => {
    try {
      const template =
        getState().testSimulation.testSimulationInfo.value?.template?.enum;
      const response = await testCandidateHttp.post(
        `/application/${applicationId}/hiring-flows/${hiringFlowId}/step/${stepId}/${getSpecificTestRouteName(
          template
        )}/${testId}/question/${questionId}/answer`,
        body
      );
      dispatch(
        getTestQuestionsMenuList({
          applicationId: applicationId,
          hiringFlowId: hiringFlowId,
          stepId: stepId,
          testId: testId,
        })
      );
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        isError: true,
        message: err.data.message,
        code: "invalid",
      } as errorRequestType);
    }
  }
);

export const submitTestSimulationQuestionAnswersRest = createAsyncThunk<
  any,
  {
    applicationId: string;
    hiringFlowId: string;
    stepId: string;
    testId: string;
    ids: any;
  },
  {
    rejectValue: errorRequestType;
    state: RootState;
  }
>(
  "testSimulation/submitTestSimulationQuestionAnswersRest",
  async (
    { applicationId, hiringFlowId, stepId, testId, ids },
    { rejectWithValue, getState }
  ) => {
    try {
      const template =
        getState().testSimulation.testSimulationInfo.value?.template?.enum;

      var responses = [];
      for (let i = 0; i < ids.length; i++) {
        const response = await testCandidateHttp.post(
          `/application/${applicationId}/hiring-flows/${hiringFlowId}/step/${stepId}/${getSpecificTestRouteName(
            template
          )}/${testId}/question/${ids[i]}/answer`,
          []
        );
        responses.push(response.data);
      }

      return true;
    } catch (err: any) {
      return rejectWithValue({
        isError: true,
        message: err.data.message,
        code: "invalid",
      } as errorRequestType);
    }
  }
);

export const submitQATestTimeout = createAsyncThunk<
  any,
  {
    applicationId: string;
    hiringFlowId: string;
    stepId: string;
    testId: string;
  },
  {
    rejectValue: errorRequestType;
    state: RootState;
  }
>(
  "testSimulation/submitQATestTimeout",
  async (
    { applicationId, hiringFlowId, stepId, testId },
    { rejectWithValue, getState }
  ) => {
    try {
      const template =
        getState().testSimulation.testSimulationInfo.value?.template?.enum;

      const response = await testCandidateHttp.post(
        `/application/${applicationId}/hiring-flows/${hiringFlowId}/step/${stepId}/${getSpecificTestRouteName(
          template
        )}/${testId}/timeout`,
        []
      );

      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        isError: true,
        message: err.data.message,
        code: "invalid",
      } as errorRequestType);
    }
  }
);
export const submitQATest = createAsyncThunk<
  any,
  {
    applicationId: string;
    hiringFlowId: string;
    stepId: string;
    testId: string;
  },
  {
    rejectValue: errorRequestType;
    state: RootState;
  }
>(
  "testSimulation/submitQATest",
  async (
    { applicationId, hiringFlowId, stepId, testId },
    { rejectWithValue, getState }
  ) => {
    try {
      const template =
        getState().testSimulation.testSimulationInfo.value?.template?.enum;

      const response = await testCandidateHttp.post(
        `/application/${applicationId}/hiring-flows/${hiringFlowId}/step/${stepId}/${getSpecificTestRouteName(
          template
        )}/${testId}/submit`,
        []
      );

      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        isError: true,
        message: err.data.message,
        code: "invalid",
      } as errorRequestType);
    }
  }
);

export const submitStatementScaleTest = createAsyncThunk<
  any,
  {
    applicationId: string;
    hiringFlowId: string;
    stepId: string;
    testId: string;
  },
  {
    rejectValue: errorRequestType;
    state: RootState;
  }
>(
  "testSimulation/submitStatementScaleTest",
  async (
    { applicationId, hiringFlowId, stepId, testId },
    { rejectWithValue, getState }
  ) => {
    try {
      const template =
        getState().testSimulation.testSimulationInfo.value?.template?.enum;

      const response = await testCandidateHttp.post(
        `/application/${applicationId}/hiring-flows/${hiringFlowId}/step/${stepId}/${getSpecificTestRouteName(
          template
        )}/${testId}/submit`,
        []
      );

      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        isError: true,
        message: err.data.message,
        code: "invalid",
      } as errorRequestType);
    }
  }
);

export const submitStatementScaleTimeout = createAsyncThunk<
  any,
  {
    applicationId: string;
    hiringFlowId: string;
    stepId: string;
    testId: string;
  },
  {
    rejectValue: errorRequestType;
    state: RootState;
  }
>(
  "testSimulation/submitStatementScaleTimeout",
  async (
    { applicationId, hiringFlowId, stepId, testId },
    { rejectWithValue, getState }
  ) => {
    try {
      const template =
        getState().testSimulation.testSimulationInfo.value?.template?.enum;

      const response = await testCandidateHttp.post(
        `/application/${applicationId}/hiring-flows/${hiringFlowId}/step/${stepId}/${getSpecificTestRouteName(
          template
        )}/${testId}/timeout`,
        []
      );

      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        isError: true,
        message: err.data.message,
        code: "invalid",
      } as errorRequestType);
    }
  }
);

export const submitOpenQuestionsTest = createAsyncThunk<
  any,
  {
    applicationId: string;
    hiringFlowId: string;
    stepId: string;
    testId: string;
  },
  {
    rejectValue: errorRequestType;
    state: RootState;
  }
>(
  "testSimulation/submitOpenQuestionsTest",
  async (
    { applicationId, hiringFlowId, stepId, testId },
    { rejectWithValue, getState }
  ) => {
    try {
      const template =
        getState().testSimulation.testSimulationInfo.value?.template?.enum;

      const response = await testCandidateHttp.post(
        `/application/${applicationId}/hiring-flows/${hiringFlowId}/step/${stepId}/${getSpecificTestRouteName(
          template
        )}/${testId}/submit`,
        []
      );

      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        isError: true,
        message: err.data.message,
        code: "invalid",
      } as errorRequestType);
    }
  }
);

export const submitOpenQuestionsTimeout = createAsyncThunk<
  any,
  {
    applicationId: string;
    hiringFlowId: string;
    stepId: string;
    testId: string;
  },
  {
    rejectValue: errorRequestType;
    state: RootState;
  }
>(
  "testSimulation/submitOpenQuestionsTimeout",
  async (
    { applicationId, hiringFlowId, stepId, testId },
    { rejectWithValue, getState }
  ) => {
    try {
      const template =
        getState().testSimulation.testSimulationInfo.value?.template?.enum;

      const response = await testCandidateHttp.post(
        `/application/${applicationId}/hiring-flows/${hiringFlowId}/step/${stepId}/${getSpecificTestRouteName(
          template
        )}/${testId}/timeout`,
        []
      );

      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        isError: true,
        message: err.data.message,
        code: "invalid",
      } as errorRequestType);
    }
  }
);

export const reportAntiCheating = createAsyncThunk<
  any,
  {
    applicationId: string;
    hiringFlowId: string;
    stepId: string;
    testId: string;
    questionId: string;
    body: {
      type: string;
      data: {};
    };
  },
  {
    rejectValue: errorRequestType;
    state: RootState;
  }
>(
  "testSimulation/reportAntiCheating",
  async (
    { applicationId, hiringFlowId, stepId, testId, questionId, body },
    { rejectWithValue }
  ) => {
    try {
      const response = await testCandidateHttp.post(
        `/application/${applicationId}/hiring-flows/${hiringFlowId}/step/${stepId}/test/${testId}/questions/${questionId}/cheating-attempt`,
        body
      );

      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        isError: true,
        message: err.data.message,
        code: "invalid",
      } as errorRequestType);
    }
  }
);
