import { createSlice } from "@reduxjs/toolkit";
import { errorRequestType } from "common/types/data.types";
import {
  getCompanyOverview,
  getLocation,
  initiateEmailAddress,
  refreshCompanyOverview,
  updateCompanyInfo,
} from "./company.actions";
import { companyItemType } from "../../companies/companies.type";
interface companyType {
  companyOverview: {
    value: companyItemType | null;
    loading: boolean;
    processing: boolean;
    error: errorRequestType;
  };
  companyDemoPreview: {
    color: string;
    linkColor: string;
    headerColor: string;
    enableHeaderColor: boolean;
    verticalLogo: {
      preview: string;
      file: File | null;
    };
    headerLogo: {
      preview: string;
      file: File | null;
    };
    enableHeaderLogo: boolean;
  };
  companyUpdate: {
    loading: boolean;
    error: errorRequestType;
  };
  locationValues: {
    values: any;
    loading: boolean;
  };
}

const initialState: companyType = {
  companyOverview: {
    value: null,
    loading: false,
    processing: false,
    error: {
      isError: false,
      message: "",
      type: "",
    },
  },
  companyDemoPreview: {
    color: "",
    linkColor: "",
    headerColor: "",
    enableHeaderColor: false,
    enableHeaderLogo: false,
    verticalLogo: {
      preview: "",
      file: null,
    },
    headerLogo: {
      preview: "",
      file: null,
    },
  },
  companyUpdate: {
    loading: false,
    error: {
      isError: false,
      message: "",
      type: "",
    },
  },
  locationValues: {
    values: null,
    loading: false,
  },
};
export const company = createSlice({
  name: "company",
  initialState,
  reducers: {
    clearCompanyOverview: (state) => {
      state.companyOverview = initialState.companyOverview;
    },
    updateDemoPreview: (state, action) => {
      state.companyDemoPreview = {
        ...state.companyDemoPreview,
        [action.payload.attribute]: action.payload.value,
      };
    },
    clearDemoPreview: (state, action) => {
      state.companyDemoPreview = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCompanyOverview.pending, (state) => {
        state.companyOverview.loading = true;
      })
      .addCase(getCompanyOverview.fulfilled, (state, { payload }) => {
        state.companyOverview.value = payload;
        state.companyDemoPreview = {
          ...state.companyDemoPreview,
          color: payload?.color,
          linkColor: payload?.linkColor ? payload?.linkColor : "",
          enableHeaderColor: payload?.enableHeaderColor,
          enableHeaderLogo: payload?.enableHeaderLogo,
          headerColor: payload?.headerColor ? payload.headerColor : "",

          verticalLogo: {
            ...state.companyDemoPreview?.verticalLogo,
            preview: payload?.logo,
            file: null,
          },
          headerLogo: {
            ...state.companyDemoPreview?.headerLogo,
            preview: payload?.headerLogo,
            file: null,
          },
        };
        state.companyOverview.loading = false;
      })
      .addCase(getCompanyOverview.rejected, (state) => {
        state.companyOverview.error = { isError: true, message: "" };
        state.companyOverview.loading = false;
      })
      .addCase(refreshCompanyOverview.fulfilled, (state, { payload }) => {
        state.companyOverview.value = payload;
      })
      .addCase(updateCompanyInfo.pending, (state) => {
        state.companyUpdate.loading = true;
      })
      .addCase(updateCompanyInfo.fulfilled, (state, { payload }) => {
        state.companyUpdate.loading = false;
      })
      .addCase(updateCompanyInfo.rejected, (state) => {
        state.companyUpdate.error = { isError: true, message: "" };
        state.companyUpdate.loading = false;
      })
      .addCase(initiateEmailAddress.pending, (state) => {
        state.companyOverview.processing = true;
      })
      .addCase(initiateEmailAddress.fulfilled, (state, { payload }) => {
        state.companyOverview.processing = false;
      })
      .addCase(initiateEmailAddress.rejected, (state, { payload }) => {
        state.companyOverview.error = { isError: true, message: payload };
        state.companyOverview.processing = false;
      })
      .addCase(getLocation.pending, (state) => {
        state.locationValues.loading = true;
      })
      .addCase(getLocation.fulfilled, (state, { payload }) => {
        state.locationValues.values = payload;
        state.locationValues.loading = false;
      })
      .addCase(getLocation.rejected, (state) => {
        state.locationValues.loading = false;
      });
  },
});

export const { updateDemoPreview, clearCompanyOverview, clearDemoPreview } =
  company.actions;

export default company.reducer;
