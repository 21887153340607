// ** Redux Imports
// import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import { http } from "common/api/http";

export const fetchTestSingle = async (id: string) => {
  const response = await http.get(`/tests/${id}/overview`);
  return response;
};

export const fetchTestSingleContents = async (id: string) => {
  const response = await http.get(`/tests/${id}/contents`);
  return response;
};

export const duplicateTest = async (id: string) => {
  const response = await http.post(`/tests/${id}/duplicate`);
  return response;
};

export const getTestDemoQuestions = async (testId: string) => {
  const response = await http.get(`/tests/${testId}/questions?isDemo=true`);
  return response;
};
