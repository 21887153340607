import { NavLink } from "react-router-dom";
import { ReactComponent as SettingsIco } from "assets/icons/settings-ico.svg";
import { ReactComponent as LogoutIco } from "assets/icons/logout-ico.svg";
import { useAppDispatch, useAppSelector } from "redux/reduxTypes";
import { handleLogout } from "views/features/auth/reducer/auth.actions";
import Spinner from "../spinners/Spinner";
import Avatar from "../Avatar/Avatar";
import { useParams } from "react-router";
import { useAuthorization } from "../../../common/hooks/useAuthorization";
import { clearCompanyOverview } from "../../features/company/reducer/company.reducer";

interface propsTypes {
  img: string;
  closeDropdown: Function;
  contentName: string;
  role: string;
  fullName: string;
  settingsLink: string;
  idClient?: string;
}

const ProfileDropdown = (props: propsTypes) => {
  const dispatch = useAppDispatch();
  const { checkPermissions } = useAuthorization();
  const userData = useAppSelector((state) => state.authentication.userData);
  const companyOverview = useAppSelector(
    (state) => state.company.companyOverview?.value
  );

  function handleUserLogout() {
    dispatch(handleLogout());
    if (companyOverview) {
      dispatch(clearCompanyOverview());
    }
  }
  const key = useParams();
  const { checkRoles } = useAuthorization();

  return (
    <div className="flex flex-col items-center py-5 w-[214px] bg-black text-white rounded-xl shadow-xl show-animation">
      {!userData.value?._id || userData.processing || userData.loading ? (
        <Spinner />
      ) : (
        <>
          <Avatar content={props.contentName} avatar={props.img} size={62} />
          <p className="mt-4 text-sm font-normal">{props.role}</p>
          <h3 className="mt-1 mb-5 text-lg font-normal text-center px-[4px] break-words w-full">
            {props.fullName}
          </h3>
          <div className="bg-white w-full h-[1px] mb-5"></div>
        </>
      )}
      {checkPermissions("UPDATE_USER") ? (
        <NavLink
          to={`${props.settingsLink}`}
          className="flex items-center gap-2.5 self-start ml-[20px] mb-3"
          onClick={() => props.closeDropdown()}
        >
          <SettingsIco />
          <p>Settings</p>
        </NavLink>
      ) : null}
      {(props.idClient && checkRoles("company")) || !props.idClient ? (
        <button
          onClick={() => handleUserLogout()}
          className="flex items-center gap-2.5 self-start ml-[20px]"
        >
          <LogoutIco />
          <p>Logout</p>
        </button>
      ) : null}
    </div>
  );
};

export default ProfileDropdown;
