import { createAsyncThunk } from "@reduxjs/toolkit";
import { http } from "common/api/http";

export const getCompanyDashboardPositions = createAsyncThunk(
  "company/getDashboardPositions",
  async (companyId: string) => {
    try {
      const response = await http.get(
        `/companies/dashboard/positions?companyId=${companyId}`
      );
      return response.data;
    } catch (err: any) {
      return err.response.data;
    }
  }
);

export const getCompanyDashboardApplicants = createAsyncThunk(
  "company/getDashboardApplicants",
  async (companyId: string) => {
    try {
      const response = await http.get(
        `/companies/dashboard/applicants?companyId=${companyId}`
      );
      return response.data;
    } catch (err: any) {
      return err.response.data;
    }
  }
);
