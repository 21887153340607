import {
  FunctionComponent,
  RefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { NavLink } from "react-router-dom";
import Avatar from "../Avatar/Avatar";
import Spinner from "../spinners/Spinner";
import { useOutsideClickHandler } from "../../../common/hooks/useOutsideClickHandler";
import { ReactComponent as SettingsIco } from "assets/icons/settings-ico-light-green.svg";
import { companyItemType } from "../../features/companies/companies.type";
import { renderFileUrl } from "../../../common/helpers/functions.helpers";
import { ImagePreview } from "../FilePreview/ImagePreview";
import ProfileDropdown from "../ProfileDropdown/ProfileDropdown";
import noImageImg from "../../../assets/icons/no-image.svg";
import { useAuthorization } from "../../../common/hooks/useAuthorization";
import { useAppDispatch, useAppSelector } from "../../../redux/reduxTypes";
import { useLocation } from "react-router";
import { setScrollDirection } from "../../../redux/global";
import { ReactComponent as ArrowDown } from "assets/icons/arrow-green-down.svg";

const navLinks = [
  {
    label: "Dashboard",
    to: "",
  },
  {
    label: "Applicants",
    to: "/applicants",
    permissions: "GET_APPLICANTS",
  },
  {
    label: "Positions",
    to: "/positions",
    permissions: "GET_POSITIONS",
  },
  {
    label: "Test Library",
    to: "/test-library",
    permissions: "GET_TESTS",
  },
  // {
  //   label: "Company Stats",
  //   to: "/company-stats",
  // },
  // {
  //   label: "Our Company",
  //   to: "/our-company",
  // },
  {
    label: "Our Team",
    to: "/team",
    permissions: "GET_USERS",
  },
];

type propsType = {
  companyData: companyItemType | null;
  idCompany: string;
};
const ClientNavbar: FunctionComponent<propsType> = (props) => {
  const scrollDirection = useAppSelector(
    (state) => state.global.scrollDirection
  );
  const dispatch = useAppDispatch();
  const userData = useAppSelector((state) => state.authentication.userData);
  const { companyData, idCompany } = props;
  const [isProfileDropdownOpen, setIsProfileDropdownOpen] = useState(false);
  const ref: RefObject<HTMLDivElement> = useRef(null);
  const location = useLocation();
  const { checkPermissions } = useAuthorization();
  const closeDropdownHandler = () => {
    setIsProfileDropdownOpen(false);
  };

  function closeMenu() {
    setTimeout(() => {
      closeDropdownHandler();
    }, 50);
  }

  const handleClickOutside = (event?: any) => {
    closeMenu();
  };

  useOutsideClickHandler(ref, handleClickOutside);

  const displayStyle = () => {
    if (scrollDirection === "DOWN") {
      closeMenu();
      return "-mt-[57px] transition-all";
    } else {
      if (scrollDirection === "UP") {
        return "mt-0 transition-all";
      }
      setTimeout(() => {
        return "relative z-50 block";
      }, 102);
    }

    return "relative z-50 block bg-black shadow-lg";
  };

  useEffect(() => {
    displayStyle();
  }, [scrollDirection]);

  useEffect(() => {
    if (scrollDirection === "UP") {
      setTimeout(() => {
        dispatch(setScrollDirection(null));
      }, 100);
    }
  }, [scrollDirection]);

  return (
    <>
      {scrollDirection === "DOWN" ? (
        <>
          <div
            className="fixed w-full h-[17px] bg-transparent z-[999] cursor-pointer"
            onMouseEnter={() => {
              scrollDirection === "DOWN" && dispatch(setScrollDirection("UP"));
            }}
          >
            <div className="text-green fixed z-[888] top-2 fade-in-top w-full flex items-center justify-center">
              <ArrowDown className={"w-[12px] [&_path]:fill-main-color"} />
            </div>
          </div>
        </>
      ) : null}
      <div
        ref={ref}
        style={{
          background: companyData?.enableHeaderColor
            ? companyData?.headerColor
            : companyData?.color
            ? companyData?.color
            : "black",
        }}
        className={`${displayStyle()} bg-black z-[40] ${
          scrollDirection !== "DOWN" ? "shadow-lg" : ""
        }`}
      >
        <div className="flex items-center m-auto h-[57px] px-[30px] max-w-[1440px]">
          <NavLink
            to={`/${idCompany}`}
            className="flex items-center cursor-pointer gap-2 mr-auto h-full"
          >
            <ImagePreview
              src={
                companyData?.headerLogo && companyData?.enableHeaderLogo
                  ? renderFileUrl(companyData.headerLogo)
                  : companyData?.logo
                  ? renderFileUrl(companyData.logo)
                  : noImageImg
              }
              className="max-h-[30px]"
              alt={"company-ico"}
              noImageClass={
                "!bg-transparent !w-10 rounded-[50%] !h-10  border-none"
              }
            />
            {/*<p*/}
            {/*  style={{*/}
            {/*    color: companyData?.linkColor || "#FFFFFF40",*/}
            {/*  }}*/}
            {/*  className="text-[17px]"*/}
            {/*>*/}
            {/*  {companyData?.name}*/}
            {/*</p>*/}
          </NavLink>

          <nav className="font-light text-[15px]">
            {navLinks
              .filter((link) =>
                (link.permissions && checkPermissions(link.permissions)) ||
                !link.permissions
                  ? link
                  : null
              )
              .map((link, index) => (
                <NavLink
                  key={index}
                  to={`/${idCompany}${link.to}`}
                  className={({ isActive }) =>
                    `select-none py-[15px] px-3.5 ${
                      isActive ? "text-white border-b-[2px] font-medium" : ""
                    }`
                  }
                  end={!link.to}
                  style={{
                    color: companyData?.linkColor
                      ? companyData?.linkColor
                      : "#FFFFFF80",
                  }}
                >
                  {link.label}
                </NavLink>
              ))}
          </nav>

          <div className="relative flex items-center gap-5 ml-12">
            {checkPermissions("UPDATE_COMPANY") ? (
              <NavLink to={`/${idCompany}/settings`}>
                <SettingsIco
                  className={`${
                    location.pathname.includes("settings")
                      ? "[&_path]:!fill-main-color-light"
                      : "cursor-pointer [&_path]:!fill-main-link-color"
                  }`}
                />
              </NavLink>
            ) : null}
            {userData.loading || userData.processing || !userData.value ? (
              <Spinner classIcon={"w-[30px] h-[30px]"} />
            ) : (
              <>
                <div
                  className="cursor-pointer w-[30px] h-[30px]"
                  onClick={() => {
                    setIsProfileDropdownOpen((prevState) => !prevState);
                  }}
                >
                  <Avatar
                    content={`${userData.value.name} ${userData.value.surname}`}
                    avatar={userData.value.avatar}
                    size={30}
                    containerClass={userData.value.avatar ? "" : "border"}
                  />
                </div>

                {isProfileDropdownOpen && (
                  <div
                    className="absolute top-12 right-0 z-50"
                    onMouseLeave={closeDropdownHandler}
                  >
                    <ProfileDropdown
                      img={userData.value.avatar}
                      closeDropdown={closeDropdownHandler}
                      contentName={`${userData.value.name} ${userData.value.surname}`}
                      role={userData.value.role.description}
                      fullName={`${userData.value.name} ${userData.value.middleName} ${userData.value.surname}`}
                      settingsLink={`/${idCompany}/profile/general`}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientNavbar;
