import { createSlice } from "@reduxjs/toolkit";
import {activateDeactivateUser, createAdminUser, getUsers, updateUserPermissions} from "./users.actions";
import {usersActionType, usersFormReducerType, usersInformationType, usersListReducerType} from "../users.types";
interface ReducerType {
  usersForm: usersFormReducerType;
  usersList: usersListReducerType;
  userAction: usersActionType
  usersInformation: usersInformationType
}

const initialState: ReducerType = {
  usersForm: {
    processing: false,
    navigate: false,
    error: { isError: false, type: "", message: "" },
  },
  usersList: {
    values: null,
    loading: false,
    error: false,
  },
  userAction: {
    loading: false,
    error: { isError: false, type: "", message: "" },
  },
  usersInformation: {
    basic_info: null,
    permissions: null,
    template: null,
    company: null
  }
};

export const users = createSlice({
  name: "users",
  initialState,
  reducers: {
    clearUsersList: (state) => {
      state.usersList = initialState.usersList;
    },
    clearUsersForm: (state) => {
      state.usersForm = initialState.usersForm;
    },
    addUserInformation: (state,action) => {
      state.usersInformation.basic_info = action.payload;
    },
    addUserPermissions: (state,action) => {
      state.usersInformation.permissions = action.payload
    },
    addUserFormTemplate: (state,action) => {
      state.usersInformation.template = action.payload
    },
    addUserFormCompany: (state,action) => {
      state.usersInformation.company = action.payload
    },
    clearUserInformation: (state) => {
      state.usersInformation = initialState.usersInformation
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.pending, (state) => {
        state.usersList.loading = true;
      })
      .addCase(getUsers.fulfilled, (state, { payload }) => {
        state.usersList.values = payload;
        state.usersList.loading = false;
      })
      .addCase(getUsers.rejected, (state) => {
        state.usersList.error = true;
        state.usersList.loading = false;
      })
      .addCase(activateDeactivateUser.pending, (state) => {
        state.userAction.loading = true;
      })
      .addCase(activateDeactivateUser.fulfilled, (state, { payload }) => {
        state.usersList.values = {
          ...state.usersList.values,
          data: payload
        };
        state.userAction.loading = false;
      })
      .addCase(activateDeactivateUser.rejected, (state,{ payload }) => {
        state.userAction.error = {
          isError: true,
          message: payload?.message ? payload.message : "",
          type: payload?.type ? payload.type : "",
        };
        state.userAction.loading = false;
      })
      .addCase(updateUserPermissions.pending, (state) => {
        state.userAction.loading = true;
      })
      .addCase(updateUserPermissions.fulfilled, (state, { payload }) => {
        state.usersList.values = {
          ...state.usersList.values,
          data: payload
        };
        state.userAction.loading = false;
      })
      .addCase(updateUserPermissions.rejected, (state,{ payload }) => {
        state.userAction.error = {
          isError: true,
          message: payload?.message ? payload.message : "",
          type: payload?.type ? payload.type : "",
        };
        state.userAction.loading = false;
      })
      .addCase(createAdminUser.pending, (state) => {
        state.usersForm.processing = true;
      })
      .addCase(createAdminUser.fulfilled, (state) => {
        state.usersForm.navigate = true;
        state.usersForm.processing = false;
        state.usersForm.error = initialState.usersForm.error;
      })
      .addCase(createAdminUser.rejected, (state, { payload }) => {
        state.usersForm.processing = false;
        state.usersForm.error = {
          isError: true,
          message: payload?.message ? payload.message : "",
          type: payload?.type ? payload.type : "",
        };
      });
  },
});

export const {
  clearUsersForm,
  clearUsersList,
  addUserInformation,
  addUserPermissions,
  addUserFormTemplate,
  addUserFormCompany,
  clearUserInformation
} = users.actions;

export default users.reducer;
