import { getRandomId } from "common/utility/Utils";
import { matchRoutes } from "react-router";
import { routePatternType, routeType } from "router/routes/types";

export function getRouteForLoggedInUser(role: string) {
  switch (role) {
    case "admin":
      return "/";
    case "client":
      return "/company";
    default:
      return "/";
  }
}

const findPath = (routes: routePatternType[]) => {
  let path = "";
  for (let i = 0; i < routes.length; i++) {
    path += `${routes[i].path}${i === routes.length - 1 ? "" : "/"}`;
  }
  return path;
};

// Gets full route pattern
// e.g. /applicant/:id/application/:applicationId/step/:stepId
export function getFullRouteMatchPath(
  routes: routePatternType[],
  location: Partial<Location> | string
) {
  const matches = matchRoutes(routes, location);
  if (matches?.length) {
    return findPath(matches.filter((m) => m.route.path).map((m) => m.route));
  }
  return null;
}

// Helper function that uses results from `matchRoutes`
// and builds up the path by navigating the route config
export function getRoutesMatchPath(
  routes: routePatternType[],
  location: Partial<Location> | string
) {
  const matches = matchRoutes(routes, location);
  if (matches?.length) {
    // don't return index routes with path ""
    return matches.filter((r) => r.route.path);
  }
  return null;
}

// returns id of a tree from the navigation structure
export function getTreeId(
  routes: routePatternType[],
  location: Partial<Location> | string
) {
  const matches = matchRoutes(routes, location);
  if (matches?.length) {
    // the first one with id matches criteria
    for (let i = 0; i < matches.length; i++) {
      if (matches[i].route.id) return matches[i].route.id;
    }
    return null;
  }
  return null;
}

// creates structure out of all routes in the system
export function getRoutesPatternStructure(routes: routeType[], depth?: number) {
  return routes
    .filter((r) => r.path)
    .map((route): any => {
      return {
        path: route.path,
        level: depth || 0,
        ...(!depth ? { id: getRandomId() } : {}),
        ...(route.children
          ? {
              children: getRoutesPatternStructure(
                route.children,
                (depth || 0) + 1
              ),
            }
          : {}),
      };
    });
}
