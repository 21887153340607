import { createAsyncThunk } from "@reduxjs/toolkit";
// ** Axios Imports
import { RootState } from "redux/store";
import { fetchApplicants } from "./applicants.services";
type multiSelectType = {
  _id: string;
};
export const getApplicants = createAsyncThunk<
  any,
  {
    page: number;
    state: string;
    limit: number;
    companyId?: string;
    filters?: any;
    search?: any;
  },
  { state: RootState }
>(
  "applicants/getApplicants",
  async ({ page, state, limit, companyId, filters, search }, { getState }) => {

    try {
      const params = {
        ...(filters?.tests && {
          tests: `${filters.tests.map((tests: multiSelectType) => tests._id)}`,
        }),
        ...(search && {
          search: search,
        }),
        ...(filters?.status && {
          status: filters.status.value,
        }),
        ...(filters?.company && {
          company: filters.company._id,
        }),
        ...(filters?.microdimensions && {
          microdimensions: `${filters.microdimensions.map(
            (microdimension: multiSelectType) => microdimension._id
          )}`,
        }),
        ...(filters?.positions && {
          positions: `${filters.positions.map(
            (position: multiSelectType) => position._id
          )}`,
        }),
        ...(filters?.sort && {
          sort: filters.sort,
        }),
      };

      const response = await fetchApplicants({
        page: page,
        state: state,
        limit: limit,
        filters: params,
        ...(companyId && {
          company: companyId,
        }),
      });

      return response.data;
    } catch (err: any) {
      return err.response.data;
    }
  }
);
