// ** Redux Imports
// import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import { createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import { http } from "common/api/http";
import { setToast } from "redux/alert";
import {
  fetchPosition,
  fetchPositionApplicationPageContent,
} from "./position.services";
import { RootState } from "redux/store";
import { errorRequestType } from "../../../../common/types/data.types";
import { updateEmailTemplateDesign } from "./position.reducer";
import { nanoid } from "nanoid";
import { singlePositionType } from "views/features/position/position.type";

export const getPositionOverview = createAsyncThunk<
  any,
  { id: string },
  {
    rejectValue: errorRequestType;
  }
>(
  "position/getPositionOverview",
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      const response = await fetchPosition(id);
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        isError: true,
        message: err.data.message,
        code: "invalid",
      } as errorRequestType);
    }
  }
);

export const getPositionApplicationPageContent = createAsyncThunk(
  "position/getPositionApplicationPageContent",
  async ({ id }: { id: string }) => {
    try {
      const response = await fetchPositionApplicationPageContent(id);
      return response.data;
    } catch (err: any) {
      return err.response.data;
    }
  }
);

export const refreshPositionOverview = createAsyncThunk(
  "position/refreshPositionOverview",
  async ({ id }: { id: string }) => {
    try {
      const response = await fetchPosition(id);
      return response.data;
    } catch (err: any) {
      return err.response.data;
    }
  }
);

export const startHiringFlow = createAsyncThunk(
  "position/startHiringFlow",
  async (id: string, { dispatch }) => {
    try {
      const response = await http.patch(`/positions/${id}/activate`);
      dispatch(
        setToast({
          message: "Position successfully activated.",
          type: "success",
        })
      );
      dispatch(getPositionOverview({ id }));

      return response.data;
    } catch (err: any) {
      dispatch(
        setToast({
          message: "Error activating the position.",
          type: "error",
        })
      );
      return err.response.data;
    }
  }
);
export const archiveHiringFlow = createAsyncThunk(
  "position/archiveHiringFlow",
  async (id: string, { dispatch }) => {
    try {
      const response = await http.put(`/positions/${id}/archive`);
      dispatch(
        setToast({
          message: "Position successfully archived.",
          type: "success",
        })
      );
      dispatch(getPositionOverview({ id }));

      return response.data;
    } catch (err: any) {
      dispatch(
        setToast({
          message: "Error archiving the position.",
          type: "error",
        })
      );
      return err.response.data;
    }
  }
);

export const updateAssessmentModuleFlow = createAsyncThunk<
  any,
  { id: string; hiringFlowId: string; stepId: string; data: any },
  { state: RootState }
>(
  "position/updateAssessmentModuleFlow",
  async ({ id, hiringFlowId, stepId, data }, { dispatch }) => {
    try {
      const response = await http.put(
        `/positions/${id}/hiring-flows/${hiringFlowId}/step/${stepId}`,
        data
      );
      dispatch(refreshPositionOverview({ id }));
      dispatch(
        setToast({
          message: "Successfully updated the position.",
          type: "success",
        })
      );
      return response.data;
    } catch (err: any) {
      return err.response.data;
    }
  }
);

type positionDataType = {
  id: string;
  name?: string;
  industry?: string;
  workingFrom?: string;
  hiringLimit?: number;
  employmentType?: string;
  location?: string;
  shouldSendReminders?: boolean;
  microDims?: { microDimId: string; weight: number }[];
};
export const updatePositionInfoData = createAsyncThunk(
  "testSingle/updatePositionInfoData",
  async (data: positionDataType, { dispatch }) => {
    try {
      const response = await http.patch(`/positions/${data?.id}`, data);
      dispatch(
        setToast({
          message: "Successfully updated the position.",
          type: "success",
        })
      );
      dispatch(refreshPositionOverview({ id: data.id }));
      return response.data;
    } catch (err: any) {
      dispatch(
        setToast({
          message: "Error updating the positon. Please try again later.",
          type: "error",
        })
      );
      return err.response.data;
    }
  }
);

export const getPositionCandidateList = createAsyncThunk<
  any,
  {
    id: string;
    stepId: string;
    page: number;
    limit?: number;
    globalFilters?: any;
    globalSearch?: any;
    globalCustomFilters?: any;
  }
>(
  "position/getCandidateList",
  async (
    {
      page,
      limit,
      id,
      stepId,
      globalFilters,
      globalSearch,
      globalCustomFilters,
    },
    { dispatch }
  ) => {
    try {
      const params = {
        ...(limit &&
          globalCustomFilters?.score?.type !== "top" && {
            limit: limit ? limit : 10,
          }),
        page: page ? page : 1,
        ...(globalSearch && {
          search: globalSearch,
        }),
        ...(globalFilters?.sort && {
          sort: globalFilters.sort,
        }),
        ...(globalCustomFilters?.state?.length &&
          !globalCustomFilters.state?.includes("all") && {
            state: `${globalCustomFilters.state.map((item: string) =>
              item.toUpperCase()
            )}`,
          }),
        ...(globalFilters?.from_date && {
          startedAtFrom: globalFilters.from_date,
        }),
        ...(globalFilters?.to_date && {
          startedAtTo: globalFilters.to_date,
        }),
        ...(globalCustomFilters?.score && {
          [globalCustomFilters?.score.type]:
            globalCustomFilters?.score?.type === "threshold"
              ? Number(globalCustomFilters?.score?.score) / 100
              : globalCustomFilters?.score?.score,
        }),
      };

      const response = await http.get(
        `/positions/${id}/step/${Number(stepId) + 1}/progress`,
        { params }
      );
      dispatch(refreshPositionOverview({ id }));

      return response.data;
    } catch (err: any) {
      console.log(err);
      return err.response.data;
    }
  }
);

export const exportPositionCandidateList = createAsyncThunk<
  any,
  {
    id: string;
    stepId: string;
    type: string;
    globalFilters?: any;
    globalSearch?: any;
    globalCustomFilters?: any;
  },
  { state: RootState }
>(
  "position/exportCandidateList",
  async (
    { id, stepId, type, globalFilters, globalSearch, globalCustomFilters },
    { getState }
  ) => {
    try {
      const params = {
        ...(globalSearch && {
          search: globalSearch,
        }),
        ...(globalCustomFilters?.state?.length && {
          state: `${globalCustomFilters.state.map((item: string) =>
            item.toUpperCase()
          )}`,
        }),
        ...(globalFilters?.from_date && {
          startedAtFrom: globalFilters.from_date,
        }),
        ...(globalFilters?.to_date && {
          startedAtTo: globalFilters.to_date,
        }),
        ...(globalCustomFilters?.score?.type === "threshold" && {
          threshold: globalCustomFilters?.score?.score / 100,
        }),
      };
      const response = await http.get(
        `/positions/${id}/step/${Number(stepId) + 1}/progress/export`,
        type === "filtered" ? { params } : {}
      );
      return response.data;
    } catch (err: any) {
      console.log(err);
      return err.response.data;
    }
  }
);

export const exportPdfReportsCandidateList = createAsyncThunk<
  any,
  {
    id: string;
    stepId: string;
    type: string;
    globalFilters?: any;
    globalSearch?: any;
    globalCustomFilters?: any;
    applicationIds?: string[];
  },
  { state: RootState }
>(
  "position/exportPdfReportsCandidateList",
  async (
    {
      id,
      stepId,
      type,
      globalFilters,
      globalSearch,
      globalCustomFilters,
      applicationIds,
    },
    { getState }
  ) => {
    try {
      const params = {
        ...(globalSearch && {
          search: globalSearch,
        }),
        ...(globalCustomFilters?.state?.length && {
          state: `${globalCustomFilters.state.map((item: string) =>
            item.toUpperCase()
          )}`,
        }),
        ...(globalFilters?.from_date && {
          startedAtFrom: globalFilters.from_date,
        }),
        ...(globalFilters?.to_date && {
          startedAtTo: globalFilters.to_date,
        }),
        ...(globalCustomFilters?.score?.type === "threshold" && {
          threshold: globalCustomFilters?.score?.score / 100,
        }),
        ...(applicationIds &&
          type === "bulkReports" && {
            ids: applicationIds,
          }),
      };

      const response = await http.get(
        `/positions/${id}/step/${Number(stepId) + 1}/progress/export/zip`,
        type === "filtered" || type === "bulkReports" ? { params } : {}
      );

      if (response.data.url) {
        const nanoId = nanoid();

        const link = document.createElement("a");
        link.href = response.data.url;
        link.download = `${nanoId}-reports.zip`;

        document.body.appendChild(link);

        link.click();
        link.remove();
      }

      return response.data;
    } catch (err: any) {
      console.log(err);
      return err.response.data;
    }
  }
);

export const refreshPositionCandidateList = createAsyncThunk<
  any,
  { id: string; stepId: string },
  { state: RootState }
>("position/refreshCandidateList", async ({ id, stepId }, { getState }) => {
  try {
    const globalFilters =
      (getState().globalFilters.globalFilters &&
        getState().globalFilters?.globalFilters[
          `/position/${id}/hiring-flow/step/${stepId}/candidate-list`
        ]?.filters) ||
      null;
    const globalSearch =
      (getState().globalFilters.globalFilters &&
        getState().globalFilters?.globalFilters[
          `/position/${id}/hiring-flow/step/${stepId}/candidate-list`
        ]?.search) ||
      null;
    const paginationLimit =
      (getState().globalFilters.globalFilters &&
        getState().globalFilters?.globalFilters[
          `/position/${id}/hiring-flow/step/${stepId}/candidate-list`
        ]) ||
      null;

    const params = {
      limit: paginationLimit ? paginationLimit.limit : 10,
      page: paginationLimit ? paginationLimit.pagination : 1,
      ...(globalSearch && {
        search: globalSearch,
      }),
      ...(globalFilters?.sort && {
        sort: globalFilters.sort,
      }),
      ...(globalFilters?.state?.length &&
        !globalFilters.state?.includes("all") && {
          state: `${globalFilters.state.map((item: string) =>
            item.toUpperCase()
          )}`,
        }),
      ...(globalFilters?.from_date && {
        startedAtFrom: globalFilters.from_date,
      }),
      ...(globalFilters?.to_date && {
        startedAtTo: globalFilters.to_date,
      }),
    };

    const response = await await http.get(
      `/positions/${id}/step/${Number(stepId) + 1}/progress`,
      { params }
    );
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
});

export const positionActionsCandidate = createAsyncThunk<
  any,
  {
    positionId: string;
    stepId: string;
    data: string[];
    type: string;
  },
  {
    state: RootState;
  }
>(
  "position/positionActionsCandidate",
  async ({ positionId, stepId, data, type }, { dispatch, getState }) => {
    try {
      const response: { data: { moduleState: string; _id: string }[] } =
        await http.put(
          `/positions/${positionId}/step/${Number(stepId) + 1}/${type}`,
          {
            applicationIds: data,
          }
        );

      const values =
        getState().position.positionCandidateList?.value?.paginatedData?.data;
      const updatedData = values.map((applicant: { applicationId: string }) => {
        const updateInfo = response?.data.find(
          (update: { _id: string; moduleState: string }) =>
            update._id === applicant.applicationId
        );

        if (updateInfo) {
          return { ...applicant, state: updateInfo.moduleState };
        }

        return applicant;
      });

      dispatch(refreshPositionOverview({ id: positionId }));

      dispatch(
        setToast({
          message: `Successfully ${type !== "hire" ? `${type}ed` : "hired"}`,
          type: "success",
        })
      );

      return updatedData;
    } catch (err: any) {
      dispatch(
        setToast({
          message: `Error ${
            type === "hire" ? "hir" : type
          }ing. Please try again later.`,
          type: "error",
        })
      );
      return err.response.data;
    }
  }
);

export const retryApplicantAssessment = createAsyncThunk<
  any,
  {
    applicationId: string;
    hiringFlowId: string;
    stepId: string;
  },
  {
    state: RootState;
  }
>(
  "position/retryApplicantAssessment",
  async ({ applicationId, stepId, hiringFlowId }, { dispatch, getState }) => {
    try {
      const response: { data: { moduleState: string; _id: string }[] } =
        await http.get(
          `/application/${applicationId}/hiring-flows/${hiringFlowId}/step/${stepId}/restart`
        );

      dispatch(
        setToast({
          message: `Successfully retried an applicant.`,
          type: "success",
        })
      );
      const values =
        getState().position.positionCandidateList?.value?.paginatedData?.data;
      const updatedData = values.map((applicant: { applicationId: string }) => {
        if (applicant.applicationId === applicationId) {
          return { ...applicant, state: "NOT_STARTED", results: null };
        }

        return applicant;
      });

      return updatedData;
    } catch (err: any) {
      dispatch(
        setToast({
          message: `Error retrying. Please try again later.`,
          type: "error",
        })
      );
      return err.response.data;
    }
  }
);

export const createNewPositionModule = createAsyncThunk<
  any,
  {
    id: string;
    hiringFlowId: string;
    data: { moduleType: string; stepIndex: number };
  },
  { state: RootState }
>(
  "position/createNewModule",
  async ({ id, hiringFlowId, data }, { dispatch }) => {
    try {
      const response = await http.post(
        `/positions/${id}/hiring-flows/${hiringFlowId}/step`,
        data
      );
      dispatch(refreshPositionOverview({ id }));
      dispatch(
        setToast({
          message: "Successfully created a new step",
          type: "success",
        })
      );
      return response.data;
    } catch (err: any) {
      return err.response.data;
    }
  }
);

export const positionDeleteStep = createAsyncThunk<
  any,
  { positionId: string; hiringFlowId: string; stepId: string },
  { state: RootState }
>(
  "position/deleteStep",
  async ({ positionId, hiringFlowId, stepId }, { dispatch }) => {
    try {
      const response = await http.delete(
        `/positions/${positionId}/hiring-flows/${hiringFlowId}/step/${stepId}`
      );
      // dispatch(refreshPositionOverview({ id:positionId }));
      dispatch(
        setToast({
          message: "Successfully deleted step",
          type: "success",
        })
      );
      return response.data;
    } catch (err: any) {
      return err.response.data;
    }
  }
);

export const duplicatePosition = createAsyncThunk<
  any,
  {
    id: string;
  },
  { state: RootState }
>("position/duplicatePosition", async ({ id }, { dispatch }) => {
  try {
    const response = await http.post(`/positions/${id}/duplicate`);
    dispatch(
      setToast({
        message: "You have successfully created a duplicate of this position.",
        type: "success",
      })
    );

    dispatch(refreshPositionOverview({ id: response.data._id }));

    return response.data._id;
  } catch (err: any) {
    return err.response.data;
  }
});

export const downloadPositionTemplate = createAsyncThunk(
  "position/downloadTemplate",
  async ({ positionId }: { positionId: string }, { dispatch }) => {
    try {
      const response = await http.get(`/positions/csv-template/${positionId}`);
      return response.data;
    } catch (err: any) {
      dispatch(
        setToast({
          message: "Error download template. Please try again later.",
          type: "error",
        })
      );
      return err.response.data;
    }
  }
);

export const importPositionTemplate = createAsyncThunk<
  any,
  {
    positionId: string;
    file: File;
    stepId: string;
  },
  {
    state: RootState;
  }
>(
  "position/importTemplate",
  async ({ positionId, file, stepId }, { dispatch, getState }) => {
    try {
      const response = await http.post(
        `/application/position/${positionId}/import-csv`,
        { file },
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      dispatch(
        refreshPositionCandidateList({ id: positionId, stepId: stepId })
      );
      dispatch(
        setToast({
          message: "Successfully imported candidates",
          type: "success",
        })
      );
      return response.data;
    } catch (err: any) {
      dispatch(
        setToast({
          message: err?.data?.message
            ? err.data.message
            : "Error importing candidates. Please try again later.",
          type: "error",
        })
      );
      return err.response.data;
    }
  }
);

export const getPositionEmailTemplate = createAsyncThunk<
  any,
  { positionId: string; emailType: string },
  { state: RootState }
>(
  "position/getPositionEmailTemplate",
  async ({ positionId, emailType }, { dispatch }) => {
    try {
      const response = await http.get(
        `/positions/${positionId}/email-template/${emailType}`
      );

      return response.data;
    } catch (err: any) {
      return err.response.data;
    }
  }
);

export const savePositionEmailTemplate = createAsyncThunk<
  any,
  { positionId: string; emailType: string; body: { html: string; json: any } },
  { state: RootState }
>(
  "position/savePositionEmailTemplate",
  async ({ positionId, emailType, body }, { dispatch }) => {
    try {
      const response = await http.put(
        `/positions/${positionId}/email-template/${emailType}`,
        body
      );

      dispatch(updateEmailTemplateDesign(body));

      dispatch(
        setToast({
          message: `Successfully updated email template`,
          type: "success",
        })
      );

      return response.data;
    } catch (err: any) {
      return err.response.data;
    }
  }
);

export const getPositionStepEmailTemplate = createAsyncThunk<
  any,
  {
    positionId: string;
    hiringFlowsId: string;
    stepId: string;
    emailType: string;
  },
  { state: RootState }
>(
  "position/getPositionStepEmailTemplate",
  async ({ positionId, hiringFlowsId, stepId, emailType }, { dispatch }) => {
    try {
      const response = await http.get(
        `positions/${positionId}/hiring-flows/${hiringFlowsId}/step/${stepId}/email-template/${emailType}`
      );

      return response.data;
    } catch (err: any) {
      return err.response.data;
    }
  }
);

export const savePositionStepEmailTemplate = createAsyncThunk<
  any,
  {
    positionId: string;
    hiringFlowsId: string;
    stepId: string;
    emailType: string;
    body: { html: string; json: any };
  },
  { state: RootState }
>(
  "position/savePositionStepEmailTemplate",
  async (
    { positionId, hiringFlowsId, stepId, emailType, body },
    { dispatch }
  ) => {
    try {
      const response = await http.put(
        `positions/${positionId}/hiring-flows/${hiringFlowsId}/step/${stepId}/email-template/${emailType}`,
        body
      );

      dispatch(
        setToast({
          message: `Successfully updated email template`,
          type: "success",
        })
      );

      return response.data;
    } catch (err: any) {
      return err.response.data;
    }
  }
);

export const downloadSingleReport = createAsyncThunk<
  any,
  { applicantId: string; applicationId: string; fileName?: string | null },
  { state: RootState }
>(
  "position/downloadSingleReport",
  async ({ applicantId, applicationId, fileName }) => {
    try {
      const response = await http.get(
        `/applicant/${applicantId}/application/${applicationId}/pdf`
      );

      const link = document.createElement("a");
      link.href = response.data.url;
      link.download = `${fileName || applicationId}-report.pdf`;

      document.body.appendChild(link);

      link.setAttribute("target", "_blank");
      link.click();
      link.remove();

      return response.data;
    } catch (err: any) {
      return err.response.data;
    }
  }
);

export const updatePositionSources = createAsyncThunk(
  "position/updatePositionSources",
  async (
    { data, id }: { data: { name: string }[]; id: string },
    { dispatch }
  ) => {
    try {
      const response = await http.post(`/positions/${id}/source`, data);
      dispatch(
        setToast({
          message: "Successfully added a new source",
          type: "success",
        })
      );
      return response.data;
    } catch (err: any) {
      dispatch(
        setToast({
          message: "Error adding a source. Please try again later.",
          type: "error",
        })
      );
      return err.response.data;
    }
  }
);

export const deletePositionSource = createAsyncThunk<
  any,
  { value: string; id: string },
  { state: RootState }
>(
  "position/deletePositionSource",
  async ({ value, id }, { dispatch, getState }) => {
    try {
      const response = await http.delete(`/positions/${id}/source`, {
        data: { name: value },
      });
      dispatch(
        setToast({
          message: "Successfully deleted a source.",
          type: "success",
        })
      );

      const values = getState().position.positionOverview?.value?.sources;
      return values.filter((item: { name: string }) => item.name !== value);
    } catch (err: any) {
      dispatch(
        setToast({
          message: "Error deleting a source. Please try again later.",
          type: "error",
        })
      );
      return err.response.data;
    }
  }
);

export const getPositionStats = createAsyncThunk(
  "position/positionStats",
  async ({ positionId }: { positionId: string }, { dispatch }) => {
    try {
      const response = await http.get(`/positions/${positionId}/stats`);
      return response.data;
    } catch (err: any) {
      dispatch(
        setToast({
          message:
            "Error getting stats for this position. Please try again later.",
          type: "error",
        })
      );
      return err.response.data;
    }
  }
);

export const updatePositionGeneralSettings = createAsyncThunk(
  "position/updatePositionGeneralSettings",
  async (
    { data, positionId }: { data: singlePositionType; positionId: string },
    { dispatch }
  ) => {
    try {
      const response = await http.patch(`/positions/${positionId}`, data);
      dispatch(
        setToast({
          message: "Successfully updated the position.",
          type: "success",
        })
      );
      // dispatch(refreshPositionOverview({id: positionId}));
      return response.data;
    } catch (err: any) {
      dispatch(
        setToast({
          message: "Error updating the position. Please try again later.",
          type: "error",
        })
      );
      return err.response.data;
    }
  }
);
