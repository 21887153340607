// ** React Imports
import { Fragment } from "react";
// ** Layouts

// ** Route Components
import PublicRoute from "router/wrappers/PublicRoute";
import PrivateRoute from "router/wrappers/PrivateRoute";
import ShieldRoute from "router/wrappers/ShieldRoute";

// ** Utils
import { type routeType } from "./types";
import TitleWrapper from "views/layouts/TitleWrapper/TitleWrapper";
import AppLayout from "views/layouts/AppLayout/AppLayout";
import { ProtectedRoute } from "../../views/features/auth/components/ProtectedRoute/ProtectedRoute";
import NavigationMiddleware from "router/middlewares/NavigationMiddleware";

function renderRoutes(routes: routeType[], isChild: boolean, depth: number) {
  return routes.filter((route: routeType) => {
    let layout = "blank";

    if (route.meta) {
      let RouteTag = PublicRoute;
      if (route.meta && route.meta.private) {
        RouteTag = PrivateRoute;
      } else if (route.meta && route.meta.shield) {
        RouteTag = ShieldRoute;
      }
      // route.meta?.layout === "app" ? (layout = "app") : (layout = "blank");
      switch (route.meta?.layout) {
        case "app":
          layout = "app";
          break;
        case "page":
          layout = "page";
          break;
        default:
          layout = "blank";
      }

      const Wrapper = route.meta?.title ? TitleWrapper : Fragment;
      const wrapperProps = {
        ...(route.meta?.title ? { title: route.meta.title } : {}),
      };
      const LayoutWrapper = layout === "app" && !isChild ? AppLayout : Fragment;
      // let layoutWrapperProps = {
      //   ...(route.meta?.title ? { title: route.meta.title } : {})
      // }

      route.element = (
        <Wrapper {...wrapperProps}>
          <LayoutWrapper>
            <RouteTag route={route.meta}>
              <NavigationMiddleware route={route.path}>
                {route.permissions ? (
                  <ProtectedRoute permissions={route.permissions}>
                    {route.element}
                  </ProtectedRoute>
                ) : (
                  route.element
                )}
              </NavigationMiddleware>
            </RouteTag>
          </LayoutWrapper>
        </Wrapper>
      );

      if (route.children != null) {
        route.children = [...renderRoutes(route.children, true, depth + 1)];
      }
      return route;
    }
    return false;
  });
}

// ** Return Filtered Array of routes & Paths
const MergeLayoutRoutes = (routes: routeType[]) => {
  return routes ? renderRoutes(routes, false, 0) : [];
};

export default MergeLayoutRoutes;
