import { http } from "common/api/http";
import { refreshHttp } from "common/api/refresh-http";
import { removeKey, storeKey } from "common/api/token";
import { objectToFormData } from "common/utility/Utils";

export const refreshAccessToken = async () => {
  try {
    const rs: { data: { access_token: string } } = await refreshHttp.get(
      "/auth/refresh"
    );
    storeKey({ access_token: rs.data.access_token });
    return true;
  } catch (error) {
    removeKey("zen_access_token");
    removeKey("zen_refresh_token");
    return false;
  }
};

export const fetchMicrodimensionsOptions = async ({
  limit,
}: {
  limit?: number;
}) => {
  const response = await http.get(`/microdimensions/filter`);
  return response;
};

export const fetchTestTemplateOptions = async ({
  limit,
}: {
  limit?: number;
}) => {
  const response = await http.get(`/tests/templates/filter`);
  return response;
};

export const fetchCompaniesOptions = async ({ limit }: { limit?: number }) => {
  const response = await http.get(`/companies/filter`);
  return response;
};

export const uploadFile = async ({
  path,
  file,
  overwrite = false,
}: {
  path: string;
  file: File;
  overwrite?: boolean;
}) => {
  const response = await http.post(
    `/system/file`,
    objectToFormData({
      path,
      file,
      overwrite,
    }),
    { headers: { "Content-Type": "multipart/form-data" } }
  );
  return response;
};
export const deleteFile = async ({ path }: { path: string }) => {
  const response = await http.delete(
    path.includes("http")
      ? path
      : path.includes("/system/file/")
      ? path
      : `/system/file/${path}`
  );
  return response;
};
export const fetchAllTestsOptions = async () => {
  return await http.get(`/tests/published-or-incubated`);
};
export const fetchAllPublishedTestsOptions = async () => {
  return await http.get(`/tests?state=published`);
};
export const fetchAllCompaniesOptions = async () => {
  return await http.get(`/companies?include[]=name`);
};
export const fetchAllCompaniesWithoutSuspended = async () => {
  return await http.get(`/companies?state=ACTIVE,PENDING`);
};
export const fetchUserRoles = async () => {
  return await http.get(`/users/roles`);
};
export const fetchAllRoles = async () => {
  return await http.get(`/users/roles/all`);
};
export const fetchAllPermissionsOptions = async () => {
  return await http.get(`/users/permissions`);
};

export const fetchAllActivePositions = async (company?: string) => {
  return await http.get(`/positions?state=ACTIVE${company ? `&company=${company}` : ""}`);
};

export const fetchAllPositions = async (company?: string) => {
  return await http.get(`/positions${company ? `?company=${company}` : ""}`);
};