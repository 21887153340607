import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useEffect } from "react";

const UpdateStatePlugin = ({ value }: { value: string }) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (editor && value) {
      // setTimeout(() => {
      const editorState = editor.parseEditorState(value);
      editor.setEditorState(editorState);
      // }, 50);
    }
  }, [value, editor]);

  return <></>;
};

export default UpdateStatePlugin;
