// ** Redux Imports
import {createSlice} from "@reduxjs/toolkit";
import {createPosition, getPositions} from "./positions.actions";
import {
    positionsTemplatesListType,
    type positionsListType,
    type positionsFormType,
} from "../positions.types";

interface positionsStateType {
    positionsTemplatesList: positionsTemplatesListType;
    positionsForm: positionsFormType;
    positionsList: positionsListType;
}

const initialState: positionsStateType = {
    positionsTemplatesList: {
        values: null,
        error: false,
        loading: false,
    },
    positionsForm: {
        navigate: null,
        processing: false,
        template: null,
        error: false,
        info: {
            basics: null,
            location: null,
            education: null,
            licence: null,
            experience: null,
            industry: null,
            moreInfo: null,
            skills: null
        }
    },
    positionsList: {
        filters: {
            search: "",
            type: "",
            sort: "",
            template: "",
            microdimensions: [],
        },
        loading: false,
        values: null,
        error: null,
    },
};

export const positions = createSlice({
    name: "positions",
    initialState,
    reducers: {
        clearPositionsList: (state) => {
            state.positionsList = initialState.positionsList;
        },
        clearTestTemplates: (state) => {
            state.positionsTemplatesList = initialState.positionsTemplatesList;
        },
        clearTestLibraryForm: (state) => {
            state.positionsForm = initialState.positionsForm;
        },
        selectTestWizardTemplate: (state, action) => {
            state.positionsForm.template = action.payload;
        },
        setPositionsListFilters: (state, action) => {
            state.positionsList.filters = {
                ...state.positionsList.filters,
                ...action.payload,
            };
        },
        clearPositionsListFilters: (state) => {
            state.positionsList.filters = initialState.positionsList.filters;
        },
        createPositionForm: (state, action) => {
            state.positionsForm.info = {
                ...state.positionsForm.info,
                [action.payload.name]: action.payload.information
            };
        },
        clearSpecificNamePositionForm: (state, action) => {
            state.positionsForm.info = {
                ...state.positionsForm.info,
                [action.payload.name]: null
            };
        },
        clearPositionWizardForm: (state) => {
            state.positionsForm.info = initialState.positionsForm.info
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPositions.pending, (state) => {
                state.positionsList.loading = true;
            })
            .addCase(getPositions.fulfilled, (state, {payload}) => {
                state.positionsList.values = payload;
                state.positionsList.loading = false;
            })
            .addCase(getPositions.rejected, (state) => {
                state.positionsList.error = true;
                state.positionsList.loading = false;
            })
            .addCase(createPosition.pending, (state) => {
                state.positionsForm.processing = true;
            })
            .addCase(createPosition.fulfilled, (state, action) => {
                state.positionsForm.template = null;
                state.positionsForm.navigate = action.payload._id;
                state.positionsForm.processing = false;
            })
            .addCase(createPosition.rejected, (state) => {
                state.positionsForm.error = true;
                state.positionsForm.processing = false;
            });
    },
});

export const {
    clearPositionsList,
    selectTestWizardTemplate,
    clearTestLibraryForm,
    clearTestTemplates,
    clearPositionsListFilters,
    setPositionsListFilters,
    createPositionForm,
    clearSpecificNamePositionForm,
    clearPositionWizardForm
} = positions.actions;

export default positions.reducer;
