// ** Redux Imports
import { createAsyncThunk } from "@reduxjs/toolkit";
// ** Axios Imports
import { http } from "common/api/http";
import { RootState } from "redux/store";
import { setToast } from "../../../../redux/alert";
import {errorRequestType} from "../../../../common/types/data.types";
interface getCompaniesParams {
  state?: string;
  page?: number;
  limit?: number;
  filters?: any;
  search?: any;
}
export const getCompanies = createAsyncThunk<
  any,
  getCompaniesParams,
  { state: RootState }
>("companies", async ({ state, page, limit, filters,search }, { getState }) => {
  try {

    const params = {
      limit: limit ? limit : 10,
      page: page ? page : 1,
      ...(state && {
        state: `${state.toUpperCase()}`,
      }),
      ...(filters?.status && {
        state: filters.status.value.toUpperCase(),
      }),
      ...(search && {
        search: search,
      }),
      ...(filters?.sort && {
        sort: filters.sort,
      }),
    };

    const response = await http.get(`/companies`, { params });

    return response.data;
  } catch (err: any) {
    console.log(err);
    return err.response.data;
  }
});

export interface companyWizardInput {
  name: string;
  address: string;
  websiteUrl: string;
  logo: string;
  color: string
  tests: string[]
}

export const createCompany = createAsyncThunk(
  "company/createCompany",
  async (
    { name, address, websiteUrl, logo, color,tests }: companyWizardInput,
    { dispatch }
  ) => {
    try {
      const response = await http.post("/companies", {
        name,
        address,
        websiteUrl,
        logo,
        color,
        tests
      });
      dispatch(
        setToast({
          message: "Company successfully created.",
          type: "success",
        })
      );
      return response.data;
    } catch (err: any) {
      dispatch(
        setToast({
          message: "Error creating company. Please try again later.",
          type: "error",
        })
      );
      return err.response.data;
    }
  }
);

export const suspendCompany = createAsyncThunk(
  "company/suspendCompany",
  async ({ id }: { id: string }, { dispatch }) => {
    try {
      console.log(id);

      const response = await http.patch(`/companies/${id}/suspend`);
      dispatch(
        setToast({
          message: "Successfully suspended company.",
          type: "success",
        })
      );
      return response.data;
    } catch (err: any) {
      dispatch(
        setToast({
          message: "Error suspending company. Please try again later.",
          type: "error",
        })
      );
      return err.response.data;
    }
  }
);

export const activateCompany = createAsyncThunk(
  "company/activateCompany",
  async ({ id }: { id: string }, { dispatch }) => {
    try {
      const response = await http.patch(`/companies/${id}/activate`);
      dispatch(
        setToast({
          message: "Successfully activated company.",
          type: "success",
        })
      );
      return response.data;
    } catch (err: any) {
      dispatch(
        setToast({
          message: "Error activating company. Please try again later.",
          type: "error",
        })
      );
      return err.response.data;
    }
  }
);

export const updateCompany = createAsyncThunk<
    any,
    {id:string,tests:string[]},
    {
        state: RootState
    }
    >(
    "company/updateCompany",
    async ({id,tests}, { dispatch,getState }) => {
      try {
        const response : any = await http.patch(`/companies/${id}`, {
          tests
        });
        dispatch(
            setToast({
              message: "Successfully updated company.",
              type: "success",
            })
        );

       const values = getState().companies.companiesList.values?.data
       return  values?.map((company:any) =>
           company._id === response.data._id ? response.data : company
       );

      } catch (err: any) {
        dispatch(
            setToast({
              message: "Error updating company. Please try again later.",
              type: "error",
            })
        );
        return err.response.data;
      }
    }
);
