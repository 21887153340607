// ** Redux Imports
import { createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import { http } from "common/api/http";
import { setToast } from "redux/alert";
import { RootState } from "redux/store";

interface getTestsParams {
  state?: string;
  page?: number;
  currentLocation?: string;
  companyId?: string
  filters?: any
  search?: any
}

type multiSelectType = {
  _id: string;
};

export const getTests = createAsyncThunk<
  any,
  getTestsParams,
  { state: RootState }
>(
  "testLibrary/getTests",
  async ({ state, page, currentLocation,companyId,filters,search }, { getState }) => {
    try {
      const stateName =
        state === "draft"
          ? "/drafts"
          : state === "incubation"
          ? "/incubator"
          : "";

      const params = {
        limit: 15,
        page: page ? page : 1,
        ...(state && {
          state: state,
        }),
        ...(filters?.type && {
          automationType: filters.type.value,
        }),
        ...(filters?.template && {
          templateId: filters.template.value,
        }),
        ...(search && {
          search: search,
        }),
        ...(filters?.sort && {
          sort: `${filters?.direction?.value === "desc" ? "-" : ""}${
              filters.sort.value
          }`,
        }),
        ...(filters?.microdimensions && {
          microDimIds: `${filters.microdimensions.map(
            (microdimension: multiSelectType) => microdimension._id
          )}`,
        }),
        ...(companyId && {
          company:companyId
        })
      };

      const response = await http.get(`/tests`, { params });

      const values = getState().testLibrary.testLibraryList.values;
      const newValues =
        values && response.data?.page && response.data.page > 1
          ? {
              ...response.data,
              data: [...values.data, ...response.data.data],
            }
          : response.data;
      return newValues;
    } catch (err: any) {
      console.log(err);
      return err.response.data;
    }
  }
);

export const getTestTemplates = createAsyncThunk(
  "testLibrary/getTestTemplates",
  async () => {
    try {
      const response = await http.get("/tests/templates");
      return response.data;
    } catch (err: any) {
      return err.response.data;
    }
  }
);

export interface testWizardInput {
  templateId: number;
  name: string;
  icon: string;
  shortDescription: string;
  timerType: string;
  timeLimit?: number;
  wpm?: number;
  connectToAi?: boolean;
  statementScaleTestConfig?: {
    options: { text: string }[];
  };
}

export const createTest = createAsyncThunk(
  "testLibrary/createTest",
  async (
    {
      templateId,
      name,
      icon,
      shortDescription,
      timerType = "per_question",
      timeLimit = 0,
      wpm = 0,
      connectToAi = false,
      statementScaleTestConfig = {
        options: [],
      },
    }: testWizardInput,
    { dispatch }
  ) => {
    try {
      const response = await http.post("/tests", {
        templateId,
        name,
        shortDescription,
        icon,
        timerType,
        timeLimit,
        wpm,
        connectToAi,
        ...(statementScaleTestConfig.options.length && {
          statementScaleTestConfig,
        }),
      });

      dispatch(
        setToast({
          message: "Successfully created a test.",
          type: "success",
        })
      );
      return response.data;
    } catch (err: any) {
      dispatch(
        setToast({
          message: "Error creating a test. Please try again later.",
          type: "error",
        })
      );
      return err.response.data;
    }
  }
);

interface getTestsModuleParams {
  positionId: number;
  hiringFlowId: number;
  stepId: number;
  location?: string;
  companyId?: string
}

export const getTestsForModule = createAsyncThunk<
  any,
  getTestsModuleParams,
  { state: RootState }
>(
  "testLibrary/getTestsForModule",
  async ({ positionId, hiringFlowId, stepId, location,companyId }, { getState }) => {
    const globalFilters =
      (getState().globalFilters.globalFilters &&
        getState().globalFilters?.globalFilters[location || ""]) ||
      null;

    const params = {
      ...(globalFilters?.filters?.type && {
        automationType: globalFilters?.filters?.type.value,
      }),
      ...(globalFilters?.filters?.template && {
        templateId: globalFilters?.filters?.template.value,
      }),
      ...(globalFilters?.search && {
        search: globalFilters.search,
      }),
      ...(globalFilters?.filters?.microdimensions && {
        microdimensions: `${globalFilters?.filters?.microdimensions.map(
          (microdimension: multiSelectType) => microdimension._id
        )}`,
      }),
      ...(globalFilters?.filters?.sort && {
        sort: `${
          globalFilters?.filters?.direction?.value === "desc" ? "-" : ""
        }${globalFilters?.filters?.sort.value}`,
      }),
      ...(companyId && {
        company:companyId
      })
    };

    try {
      const response = await http.get(
        `/positions/${positionId}/hiring-flows/${hiringFlowId}/step/${stepId}/tests`,
        { params }
      );
      return response.data;
    } catch (err: any) {
      console.log(err);
      return err.response.data;
    }
  }
);
