// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";
import { getBrowserName } from "common/utility/Utils";

interface alertType {
  isMobile: boolean;
  scrollDirection: "UP" | "DOWN" | null;
  browser: { value: string; human: string };
}

const initialState: alertType = {
  isMobile: false,
  scrollDirection: null,
  browser: getBrowserName() || { value: "", human: "" },
};

export const globalSlice = createSlice({
  name: "global",
  initialState,

  reducers: {
    setIsMobile: (state, { payload }) => {
      state.isMobile = payload;
    },
    setScrollDirection: (state, { payload }) => {
      state.scrollDirection = payload;
    },
  },
  extraReducers: (builder) => {},
});

export const { setIsMobile, setScrollDirection } = globalSlice.actions;

export default globalSlice.reducer;
