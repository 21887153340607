import { type FC, lazy } from "react";
import { lazyRetry } from "common/utility/Utils";
import { type routeType } from "router/routes/types";

const PositionWrapper: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () =>
        await import(
          "../../../position/wrappers/PositionWrapper/PositionWrapper"
        )
    )
);
const PositionOverview: FC | any = lazy(() =>
  lazyRetry(
    () => import("../../../position/pages/PositionOverview/PositionOverview")
  )
);
const HiringFlowEditor: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () =>
        await import(
          "../../../position/pages/HiringFlowEditor/HiringFlowEditor"
        )
    )
);
const HiringFlowBlank: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () =>
        await import(
          "../../../position/components/HiringFlowBlank/HiringFlowBlank"
        )
    )
);
const HiringFlowModule: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () =>
        await import(
          "../../../position/components/HiringFlowModule/HiringFlowModule"
        )
    )
);

const CandidateList: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () =>
        await import("../../../position/pages/CandidateList/CandidateList")
    )
);
const PositionSettings: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () =>
        await import(
          "../../../position/pages/PositionSettings/PositionSettings"
        )
    )
);

const PositionCompetenciesSettings: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () =>
        await import(
          "../../../position/pages/PositionSettings/PositionCompetenciesSettings"
        )
    )
);

const PositionSettingsWrapper: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () =>
        await import(
          "../../../position/wrappers/PositionSettingsWrapper/PositionSettingsWrapper"
        )
    )
);

const PositionEmailTemplateEditorPage: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () =>
        await import(
          "../../../position/moduleSpecificComponents/PositionEmailTemplateEditorPage/PositionEmailTemplateEditorPage"
        )
    )
);

const PositionStepEmailTemplateEditorPage: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () =>
        await import(
          "../../../position/moduleSpecificComponents/PositionStepEmailTemplateEditorPage/PositionStepEmailTemplateEditorPage"
        )
    )
);

const PositionAnalyticsWrapper: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () =>
        await import(
          "../../../position/wrappers/PositionAnalyticsWrapper/PositionAnalyticsWrapper"
        )
    )
);

const PositionAnalytics: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () =>
        await import(
          "../../../position/pages/PositionAnalytics/PositionAnalytics"
        )
    )
);

const PositionGeneralSettings: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () =>
        await import(
          "../../../position/pages/PositionSettings/PositionGeneralSettings"
        )
    )
);

const companySinglePositionRoutes: routeType[] = [
  {
    path: ":companyId/position/:id",
    element: <PositionWrapper />,
    permissions: "GET_POSITIONS",
    meta: {
      layout: "app",
      type: "page",
      private: true,
    },
    children: [
      {
        path: "",
        element: <PositionOverview />,
        meta: {
          title: "Overview",
          type: "component",
          private: true,
        },
      },
      {
        path: "hiring-flow",
        element: <HiringFlowEditor />,
        meta: {
          title: "Hiring Flow",
          type: "component",
          private: true,
        },
        children: [
          {
            path: "",
            element: <HiringFlowBlank />,
            meta: {
              type: "component",
              private: true,
            },
          },
          {
            path: "step/:stepId",
            element: <HiringFlowModule />,
            meta: {
              type: "component",
              private: true,
            },
            children: [
              {
                path: "candidate-list",
                element: <CandidateList />,
                permissions: "GET_APPLICANTS",
                meta: {
                  title: "Applicants List",
                  type: "component",
                  private: true,
                },
              },
            ],
          },
        ],
      },
      {
        path: "settings",
        element: <PositionSettingsWrapper />,
        permissions: "UPDATE_POSITION",
        meta: {
          title: "Position settings",
          type: "component",
          private: true,
        },
        children: [
          {
            path: "",
            element: <PositionGeneralSettings />,
            allPermissionsCheck: ["GET_POSITIONS", "UPDATE_POSITION"],
            meta: {
              type: "component",
              private: true,
            },
          },
          {
            path: "emails",
            element: <PositionSettings />,
            meta: {
              type: "component",
              private: true,
            },
          },
          {
            path: "competencies",
            element: <PositionCompetenciesSettings />,
            allPermissionsCheck: ["GET_POSITIONS", "UPDATE_POSITION"],
            meta: {
              type: "component",
              private: true,
            },
          },
        ],
      },
      {
        path: "analytics",
        element: <PositionAnalyticsWrapper />,
        allPermissionsCheck: ["GET_POSITIONS", "GET_POSITION_STATS"],
        meta: {
          title: "Position analytics",
          type: "component",
          private: true,
        },
        children: [
          {
            path: "",
            element: <PositionAnalytics />,
            meta: {
              type: "component",
              private: true,
            },
          },
        ],
      },
      // Position Template Editor
      {
        path: "settings/emails/email-template-editor/:emailType",
        element: <PositionEmailTemplateEditorPage />,
        permissions: [
          "UPDATE_POSITION",
          "EMAIL_TEMPLATE_GET",
          "EMAIL_TEMPLATE_UPDATE",
        ],
        // allPermissionsCheck: [
        //   "UPDATE_POSITION",
        //   "EMAIL_TEMPLATE_GET",
        //   "EMAIL_TEMPLATE_UPDATE",
        // ],
        meta: {
          title: "Email template editor",
          type: "component",
          private: true,
        },
      },
      // Position Step Template Editor
      {
        path: "hiring-flow/:hiringFlowId/step/:stepId/email-template-editor/:emailType",
        element: <PositionStepEmailTemplateEditorPage />,
        permissions: [
          "UPDATE_POSITION",
          "EMAIL_TEMPLATE_GET",
          "EMAIL_TEMPLATE_UPDATE",
        ],
        meta: {
          type: "component",
          private: true,
        },
      },
    ],
  },
];
export default companySinglePositionRoutes;
