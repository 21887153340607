// ** Redux Imports
import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import {
  createMicrodimension,
  deleteMicrodimension,
  getMicrodimensions,
  updateMicrodimension,
} from "./microdimensions.actions";
import {
  type MicrodimensionsFormReducerType,
  type MicrodimensionsDeleteReducerType,
  type MicrodimensionsLibraryReducerType,
} from "../microdimensions.types";

interface reducerType {
  microdimensionsDelete: MicrodimensionsDeleteReducerType;
  microdimensionsForm: MicrodimensionsFormReducerType;
  microdimensionsList: MicrodimensionsLibraryReducerType;
}

const initialState: reducerType = {
  microdimensionsDelete: {
    value: null,
    processing: false,
    error: false,
  },
  microdimensionsForm: {
    navigate: false,
    edit: null,
    processing: false,
    error: false,
  },
  microdimensionsList: {
    filters: {
      search: "",
      type: "",
      sort: "",
      direction: ""
    },
    loading: false,
    values: null,
    error: null,
  },
};

export const microdimensions = createSlice({
  name: "microdimensions",
  initialState,
  reducers: {
    setDeleteModal: (state, { payload }) => {
      state.microdimensionsDelete.value = payload;
    },
    setMicrodimensionEditForm: (state, { payload }) => {
      state.microdimensionsForm.edit = payload;
    },
    clearMicrodimensionsList: (state) => {
      state.microdimensionsList = initialState.microdimensionsList;
    },
    clearMicrodimensionDelete: (state) => {
      state.microdimensionsDelete = initialState.microdimensionsDelete;
    },
    clearMicrodimensionsForm: (state) => {
      state.microdimensionsForm = initialState.microdimensionsForm;
    },
    setMicrodimensionsFilters: (state, action) => {
      state.microdimensionsList.filters = {
        ...state.microdimensionsList.filters,
        ...action.payload,
      };
    },
    clearMicrodimensionsFilters: (state) => {
      state.microdimensionsList.filters =
        initialState.microdimensionsList.filters;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMicrodimensions.pending, (state) => {
        state.microdimensionsList.loading = true;
      })
      .addCase(getMicrodimensions.fulfilled, (state, { payload }) => {
        state.microdimensionsList.values =
          state.microdimensionsList?.values && payload?.page && payload.page > 1
            ? {
                ...payload,
                data: [
                  ...state.microdimensionsList.values.data,
                  ...payload.data,
                ],
              }
            : payload;
        state.microdimensionsForm.error = false;
        state.microdimensionsList.loading = false;
      })
      .addCase(getMicrodimensions.rejected, (state, action) => {
        state.microdimensionsList.error = action.payload;
        state.microdimensionsList.loading = false;
      })
      .addCase(createMicrodimension.pending, (state) => {
        state.microdimensionsForm.processing = true;
      })
      .addCase(createMicrodimension.fulfilled, (state) => {
        state.microdimensionsForm.navigate = true;
        state.microdimensionsForm.error = false;
        state.microdimensionsForm.processing = false;
      })
      .addCase(createMicrodimension.rejected, (state, action) => {
        state.microdimensionsForm.error = true;
        state.microdimensionsForm.processing = false;
      })
      .addCase(updateMicrodimension.pending, (state) => {
        state.microdimensionsForm.processing = true;
      })
      .addCase(updateMicrodimension.fulfilled, (state) => {
        state.microdimensionsForm.navigate = true;
        state.microdimensionsForm.edit = null;
        state.microdimensionsForm.error = false;
        state.microdimensionsForm.processing = false;
      })
      .addCase(updateMicrodimension.rejected, (state, action) => {
        state.microdimensionsForm.error = true;
        state.microdimensionsForm.processing = false;
      })
      .addCase(deleteMicrodimension.pending, (state) => {
        state.microdimensionsDelete.processing = true;
      })
      .addCase(deleteMicrodimension.fulfilled, (state, action) => {
        state.microdimensionsDelete.error = false;
        state.microdimensionsDelete.processing = false;
        state.microdimensionsDelete.value = null;
        state.microdimensionsList.values = action.payload;
      })
      .addCase(deleteMicrodimension.rejected, (state, action) => {
        state.microdimensionsDelete.error = true;
        state.microdimensionsDelete.value = null;
        state.microdimensionsDelete.processing = false;
      });
  },
});

export const {
  setDeleteModal,
  setMicrodimensionEditForm,
  clearMicrodimensionsList,
  clearMicrodimensionDelete,
  clearMicrodimensionsForm,
  setMicrodimensionsFilters,
  clearMicrodimensionsFilters,
} = microdimensions.actions;

export default microdimensions.reducer;
