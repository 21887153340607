import { createAsyncThunk } from "@reduxjs/toolkit";
// ** Axios Imports
import { http } from "common/api/http";
import { type microdimensionWithTestsType } from "../microdimensions.types";
import { fetchMicrodimensions } from "./microdimensions.services";
import { setToast } from "redux/alert";
import { RootState } from "redux/store";

export const getMicrodimensions = createAsyncThunk<
  any,
  { page: number },
  { state: RootState }
>(
  "microdimensionsLibrary/getMicrodimensions",
  async ({ page }, { getState }) => {
    try {
      const globalFilters =
        (getState().globalFilters.globalFilters &&
          getState().globalFilters?.globalFilters[`/microdimensions`]
            ?.filters) ||
        null;
      const globalSearch =
        (getState().globalFilters.globalFilters &&
          getState().globalFilters?.globalFilters[`/microdimensions`]
            ?.search) ||
        null;

      const filters = {
        ...(globalFilters?.direction && {
          direction: globalFilters.direction.value,
        }),
        ...(globalFilters?.type && {
          type: globalFilters.type.value,
        }),
        ...(globalSearch && {
          search: globalSearch,
        }),
        ...(globalFilters?.sort && {
          sort: globalFilters.sort.value,
        }),
      };

      const response = await fetchMicrodimensions({ page: page, ...filters });
      return response.data;
    } catch (err: any) {
      return err.response.data;
    }
  }
);

export const createMicrodimension = createAsyncThunk(
  "microdimensionsLibrary/createMicrodimension",
  async (
    { name, description, icon, type }: microdimensionWithTestsType,
    { dispatch }
  ) => {
    try {
      const response = await http.post("/microdimensions", {
        name,
        description,
        type,
      });
      dispatch(
        setToast({
          message: "Successfully created a microdimension.",
          type: "success",
        })
      );
      return response.data;
    } catch (err: any) {
      dispatch(
        setToast({
          type: "error",
          message: "An error occured. Please try again later.",
        })
      );
      return err.response.data.message;
    }
  }
);

export const updateMicrodimension = createAsyncThunk(
  "microdimensionsLibrary/updateMicrodimension",
  async (
    { _id, name, description, icon, type }: microdimensionWithTestsType,
    { dispatch }
  ) => {
    try {
      const response = await http.patch(`/microdimensions/${_id}`, {
        name,
        description,
        icon,
        type,
      });
      dispatch(
        setToast({
          message: "Successfully updated a microdimension.",
          type: "success",
        })
      );
      return response.data;
    } catch (err: any) {
      dispatch(
        setToast({
          type: "error",
          message: err.response.data.message,
        })
      );
      return err.response.data.message;
    }
  }
);

export const deleteMicrodimension = createAsyncThunk<
  any,
  { id: string | null },
  { state: RootState }
>(
  "microdimensionsLibrary/deleteMicrodimension",
  async ({ id }, { dispatch, getState }) => {
    try {
      const response = await http.delete(`/microdimensions/${id}`);
      const state = getState();
      const values = state.microdimensions.microdimensionsList.values;
      const filters = state.microdimensions.microdimensionsList.filters;

      const page =
        values?.page && values?.total && values?.limit
          ? values.total > values.page * values.limit - values.limit
            ? values.page
            : values.page - 1
          : 1;
      const microdimensions = await fetchMicrodimensions({
        page: page,
        ...filters,
      });
      const newData = {
        ...microdimensions.data,
        data: values ? values.data.filter((v) => v._id !== id) : [],
      };

      dispatch(setToast({ message: response.data.message, type: "success" }));
      return newData;
    } catch (err: any) {
      dispatch(
        setToast({
          type: "error",
          message: err.response.data.message,
        })
      );
      return err.response.data;
    }
  }
);
