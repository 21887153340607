import { http } from "common/api/http";
import { applicantParamsType } from "../applicants.types";
export async function fetchApplicants({
  page,
  state, // state = "" (all) | "clients" | "admins" <- passed thru router
  limit,
  filters,
  company,
}: applicantParamsType) {
  const params = {
    limit: limit ? limit : 10,
    page: page ? page : 1,
    ...(state && {
      state: state,
    }),
    ...(filters.search && {
      search: filters.search,
    }),
    ...(filters.sort && {
      sort: filters.sort,
    }),
    ...(filters.status && {
      status: filters.status,
    }),
    ...(filters.company && {
      company: filters.company,
    }),
    ...(filters.microdimensions && {
      microdimensions: filters.microdimensions,
    }),
    ...(filters.positions && {
      positions: filters.positions,
    }),
    ...(filters.tests && {
      tests: filters.tests,
    }),
    ...(company && {
      company: company,
    }),
  };

  return await http.get(`/applicant`, { params });
}
