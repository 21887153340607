import { errorRequestType } from "common/types/data.types";

export const employmentTypeOptions = [
  {
    label: "Full-Time",
    value: "Full-Time",
  },
  {
    label: "Part-Time",
    value: "Part-Time",
  },
  {
    label: "Contract",
    value: "Contract",
  },
  {
    label: "Consulting",
    value: "Consulting",
  },
];
export const workFromOptions = [
  {
    label: "Office",
    value: "Office",
  },
  {
    label: "Remote",
    value: "Remote",
  },
  {
    label: "Hybrid",
    value: "Hybrid",
  },
];

// export const videoTypes = ["webm", "mp4", "ogg", "x-matroska"];
export const supportedMimeTypesChrome = [
  "video/webm;codecs=vp9",
  "video/webm;codecs=VP9",
  "video/webm;codecs=vp8,opus",
  "video/WEBM;codecs=VP8,OPUS",
  "video/mp4;codecs=avc1",
  "video/mp4;codecs=AVC1",
];
export const supportedMimeTypesMozilla = [
  "video/webm;codecs=vp8,opus",
  "video/WEBM;codecs=VP8,OPUS",
  "video/webm;codecs=opus",
  "video/webm;codecs=OPUS",
];
export const supportedMimeTypesSafari = [
  "video/mp4;codecs=avc1",
  "video/mp4;codecs=AVC1",
];
export const supportedMimeTypesEdge = [
  "video/webm;codecs=vp9",
  "video/webm;codecs=VP9",
  "video/webm;codecs=opus",
  "video/webm;codecs=OPUS",
];

export const videoTypes = ["webm", "mp4"];
export const codecs = [
  "vp9",
  "vp8",
  "avc1",
  "h264",
  "h.264",
  "opus",
  "vp8.0",
  "should-not-be-supported",
];
export const audioTypes = ["webm", "mp3"];
// export const codecs = [
//   "should-not-be-supported",
//   "vp9",
//   "vp8",
//   "avc1",
//   "vp9.0",
//   "vp8.0",
//   "av1",
//   "h265",
//   "h.265",
//   "h264",
//   "h.264",
//   "opus",
//   "pcm",
//   "aac",
//   "mpeg",
//   "mp4a",
// ];

export const errorRequestConstant: errorRequestType = {
  isError: false,
  message: "",
  type: "",
};
