// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";
import {
  activateCompany,
  createCompany,
  getCompanies,
  suspendCompany, updateCompany,
} from "./companies.actions";
import {
  companiesFormType,
  companiesListType,
  companiesTemplatesListType, companyWizardFormType,
} from "../companies.type";
import { createPosition } from "../../positions/reducer/positions.actions";
interface companiesStateType {
  companiesTemplatesList: companiesTemplatesListType;
  companiesList: companiesListType;
  companiesForm: companiesFormType;
  companyWizardInformation: companyWizardFormType
  companyPublishedTest: {processing:boolean}
}

const initialState: companiesStateType = {
  companiesTemplatesList: {
    values: null,
    error: false,
    loading: false,
  },
  companiesForm: {
    navigate: null,
    processing: false,
    template: null,
    error: false,
  },
  companiesList: {
    filters: {
      search: "",
      type: "",
      sort: "",
      status: "",
    },
    values: null,
    loading: false,
    error: {
      isError: false,
      message: "",
      type: "",
    },
  },
  companyWizardInformation: {
    basic_info: null,
    assigned_tests: null
  },
  companyPublishedTest: {
    processing: false
  }
};
export const companies = createSlice({
  name: "companies",
  initialState,
  reducers: {
    clearCompaniesList: (state) => {
      state.companiesList = initialState.companiesList;
    },
    clearCompanyLibraryForm: (state) => {
      state.companiesForm = initialState.companiesForm;
    },
    addCompanyWizardInformation: (state,action) => {
      state.companyWizardInformation.basic_info = action.payload;
    },
    addCompanyWizardTests: (state,action) => {
      state.companyWizardInformation.assigned_tests = action.payload;
    },
    clearCompanyWizardInformation: (state) => {
      state.companyWizardInformation = initialState.companyWizardInformation
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCompanies.pending, (state) => {
        state.companiesList.loading = true;
      })
      .addCase(getCompanies.fulfilled, (state, { payload }) => {
        state.companiesList.values = payload;
        state.companiesList.loading = false;
      })
      .addCase(getCompanies.rejected, (state) => {
        state.companiesList.error = { isError: true, message: "" };
        state.companiesList.loading = false;
      })
      .addCase(createCompany.pending, (state) => {
        state.companiesForm.processing = true;
      })
      .addCase(createCompany.fulfilled, (state) => {
        state.companiesForm.processing = false;
      })
      .addCase(createCompany.rejected, (state) => {
        state.companiesForm.error = true;
        state.companiesForm.processing = false;
      })
      .addCase(suspendCompany.pending, (state) => {
        state.companiesForm.processing = true;
      })
      .addCase(suspendCompany.fulfilled, (state) => {
        state.companiesForm.processing = false;
      })
      .addCase(suspendCompany.rejected, (state) => {
        state.companiesForm.error = true;
        state.companiesForm.processing = false;
      })
      .addCase(activateCompany.pending, (state) => {
        state.companiesForm.processing = true;
      })
      .addCase(activateCompany.fulfilled, (state) => {
        state.companiesForm.processing = false;
      })
      .addCase(activateCompany.rejected, (state) => {
        state.companiesForm.error = true;
        state.companiesForm.processing = false;
      })
      .addCase(updateCompany.pending, (state) => {
        state.companyPublishedTest.processing = true;
      })
      .addCase(updateCompany.fulfilled, (state, {payload}) => {
        state.companiesList.values = {
          ...state.companiesList.values,
          data: payload
        };
        state.companyPublishedTest.processing = false;
      })
      .addCase(updateCompany.rejected, (state) => {
        state.companyPublishedTest.processing = false;
      });
  },
});

export const {
  clearCompaniesList,
  clearCompanyLibraryForm,
  addCompanyWizardInformation,
  addCompanyWizardTests,
  clearCompanyWizardInformation
} =
  companies.actions;
export default companies.reducer;
