import React, { FunctionComponent, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router";
import { useAuthorization } from "../../../../../common/hooks/useAuthorization";

type typeProps = {
  children?: React.ReactChild;
  permissions?: string | string[];
  allPermissions?: string[];
};
export const ProtectedRoute: FunctionComponent<typeProps> = (props) => {
  const { children, permissions, allPermissions } = props;
  const navigate = useNavigate();
  const { checkPermissions, allPermissionsCheck } = useAuthorization();

  useEffect(() => {
    if (permissions && !checkPermissions(permissions))
      return navigate(`/not-authorized`);
    else if (allPermissions && !allPermissionsCheck(allPermissions)) {
      return navigate(`/not-authorized`);
    }
  }, [permissions, allPermissions]);

  return <>{children ? children : <Outlet />}</>;
};
