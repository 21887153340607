// ** React Imports
import {Suspense} from "react";
import {Navigate, useLocation} from "react-router";
import RoleMiddleware from "router/middlewares/RoleMiddleware";
import {type routerWrapperType} from "./types";
import {useAppSelector} from "redux/reduxTypes";
import LoadingPageCounter from "../../views/components/LoadingPageCounter/LoadingPageCounter";

const PrivateRoute = ({children, route}: routerWrapperType) => {

    const auth = useAppSelector((state) => state.authentication);
    const location = useLocation();

    if (!auth.loggedIn) {
        return <Navigate
            to={`/login${location?.pathname && !auth.loggedOut && location?.pathname !== "/" ? `?redirectTo=${location.pathname}` : ""}`}/>;
    } else if (!auth?.userData?.isPasswordChangedAfterInitialLogin) {
        return <Navigate to="/change-password"/>
    }

    // else if (!auth.userData?.verified) {
    //   return <Navigate to="/verify-email" />
    // } else
    //   return <Navigate to={getRouteForLoggedInUser(auth.selectedRole)} />
    // }

    return (
        <RoleMiddleware route={route}>
            <Suspense fallback={<LoadingPageCounter/>}>{children}</Suspense>
        </RoleMiddleware>
    );
};

export default PrivateRoute;
