import { useState, type ReactNode, useEffect } from "react";
import "react-perfect-scrollbar/dist/css/styles.css";
import ScrollBar from "react-perfect-scrollbar";
const Modal = (props: {
  children: ReactNode;
  close: () => void;
  overflow?: "unset";
  childrenClassName?: string;
  preventAutoScroll?: boolean;
  removePadding?: boolean;
  positionModal?: string
}) => {
  const [isMouseEnter, setIsMouseEnter] = useState(false);

  useEffect(() => {
    if (!props.preventAutoScroll) {
      const modalScrollbarContainerBox = document.getElementById(
        "modalScrollbarContainerBox"
      );
      modalScrollbarContainerBox?.scroll({ top: 1 });
    }
  }, [isMouseEnter]);

  useEffect(() => {
    return () => {
      document.body.className = "";
    };
  }, []);

  return (
    <div
      className="fixed top-0 left-0 w-full h-screen z-50 bg-modal-black overflow-hidden show-animation"
      onMouseEnter={() => {
        setIsMouseEnter((prevMouseState) => !prevMouseState);
      }}
    >
      <div className="w-full h-screen" onClick={() => props.close()}></div>
      {props.overflow ? (
        <div
          className={`
          ${props.positionModal === "RIGHT" ? "right-0 h-full " : "top-[50%] left-[50%] w-fit translate-y-[-50%] translate-x-[-50%] max-h-[80vh] z2xl:max-h-[90%] max-w-[80%] rounded-2xl zmd:w-[95%] zsm:max-w-[94%] zsm:w-full "}
          fixed z-10 bg-white shadow-lg p-10 overflow-auto zsm:p-3.5 ${
            props.childrenClassName ? props.childrenClassName : ""
          }`}
          style={props.overflow ? { overflow: "unset" } : undefined}
        >
          {props.children}
        </div>
      ) : (
        <ScrollBar
          id="modalScrollbarContainerBox"
          component="div"
          className={`
           ${props.positionModal === "RIGHT" ? "right-0 h-full top-0 w-auto" : "top-[50%] left-[50%] w-fit translate-y-[-50%] translate-x-[-50%] h-auto max-h-[80vh] z2xl:max-h-[90%] max-w-[80%] rounded-2xl zmd:w-[95%] zsm:max-w-[94%] zsm:w-full"}
          fixed z-10 bg-white shadow-lg ${
            props.removePadding ? "p-0" : "p-10"
          } overflow-auto zsm:p-3.5 ${
            props.childrenClassName ? props.childrenClassName : ""
          }`}
          options={{
            wheelPropagation: true,
          }}
        >
          {props.children}
        </ScrollBar>
      )}
    </div>
  );
};

export default Modal;
