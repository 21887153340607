import { RefObject, useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import zenhire from "assets/icons/zenhire-ico.png";
import notificationIco from "assets/icons/notification-ico.svg";
import { ReactComponent as ArrowDown } from "assets/icons/arrow-green-down.svg";
import ProfileDropdown from "../ProfileDropdown/ProfileDropdown";
import { useAppDispatch, useAppSelector } from "redux/reduxTypes";
import Avatar from "../Avatar/Avatar";
import Spinner from "../spinners/Spinner";
import { useOutsideClickHandler } from "../../../common/hooks/useOutsideClickHandler";
import { setScrollDirection } from "redux/global";
import { useAuthorization } from "../../../common/hooks/useAuthorization";

const AdminNavbar = () => {
  const dispatch = useAppDispatch();

  const scrollDirection = useAppSelector(
    (state) => state.global.scrollDirection
  );
  const userData = useAppSelector((state) => state.authentication.userData);

  const [isProfileDropdownOpen, setIsProfileDropdownOpen] = useState(false);

  const { checkPermissions } = useAuthorization();

  const ref: RefObject<HTMLDivElement> = useRef(null);
  const closeDropdownHandler = () => {
    setIsProfileDropdownOpen(false);
  };

  function closeMenu() {
    setTimeout(() => {
      closeDropdownHandler();
    }, 50);
  }

  const handleClickOutside = (event?: any) => {
    closeMenu();
  };

  useOutsideClickHandler(ref, handleClickOutside);

  const displayStyle = () => {
    if (scrollDirection === "DOWN") {
      closeMenu();
      return "-mt-[57px] transition-all";
    } else {
      if (scrollDirection === "UP") {
        return "mt-0 transition-all";
      }
      setTimeout(() => {
        return "relative z-50 block";
      }, 102);
    }

    return "relative z-50 block bg-black shadow-lg";
  };

  useEffect(() => {
    displayStyle();
  }, [scrollDirection]);

  // useEffect(() => {
  //   const eventFunction = (e: WheelEvent) => {
  //     if (
  //       e.deltaY < 0 &&
  //       document.body.scrollTop === 0 &&
  //       scrollDirection !== "UP"
  //     ) {
  //       dispatch(setScrollDirection("UP"));
  //     }
  //   };

  //   document.body.addEventListener("wheel", eventFunction);

  //   return () => {
  //     document.body.removeEventListener("wheel", eventFunction);
  //   };
  // }, []);

  const handleTestRedirect = () => {
    if (checkPermissions("GET_TESTS_PUBLISHED")) {
      return "/test-library";
    } else if (checkPermissions("GET_TESTS_INCUBATION")) {
      return "/test-library/incubator";
    } else if (checkPermissions("GET_TESTS_DRAFT")) {
      return "/test-library/drafts";
    }
  };

  useEffect(() => {
    if (scrollDirection === "UP") {
      setTimeout(() => {
        dispatch(setScrollDirection(null));
      }, 100);
    }
  }, [scrollDirection]);

  // console.log("GET_POSITIONS", checkPermissions("GET_POSITIONS"));
  // console.log("UPDATE_POSITION", checkPermissions("UPDATE_POSITION"));
  // console.log("EMAIL_TEMPLATE_GET", checkPermissions("EMAIL_TEMPLATE_GET"));
  // console.log(
  //   "EMAIL_TEMPLATE_UPDATE",
  //   checkPermissions("EMAIL_TEMPLATE_UPDATE")
  // );

  return (
    <>
      {/* floating hover div */}
      {scrollDirection === "DOWN" ? (
        <>
          <div
            className="fixed w-full h-[17px] bg-transparent z-[999] cursor-pointer"
            onMouseEnter={() => {
              scrollDirection === "DOWN" && dispatch(setScrollDirection("UP"));
            }}
          >
            <div className="text-green fixed z-[888] top-2 fade-in-top w-full flex items-center justify-center">
              <ArrowDown className={"w-[12px]"} />
            </div>
          </div>
        </>
      ) : null}
      <div
        className={`${displayStyle()} bg-black ${
          scrollDirection !== "DOWN" ? "shadow-lg" : ""
        }`}
        ref={ref}
        id="adminNavbarID"
      >
        <div className="flex items-center m-auto h-[57px] px-[30px] max-w-[1440px]">
          <NavLink
            to={"/"}
            className="flex items-center cursor-pointer gap-2 mr-auto"
            onClick={() => {
              setIsProfileDropdownOpen(false);
            }}
          >
            <img
              src={zenhire}
              className="w-[30px] h-auto"
              alt="test-library-ico"
            />
            <p className="text-white text-[17px]">ZenHire Admin</p>
          </NavLink>

          <nav className="space-x-6 text-dimmed-white font-light text-[15px]">
            <NavLink
              to={"/"}
              end
              className={({ isActive }) =>
                `hover:text-white select-none py-[15px]  hover:border-b-[2px] ${
                  isActive ? "text-white border-b-[2px] font-medium" : ""
                }`
              }
              onClick={() => {
                setIsProfileDropdownOpen(false);
              }}
            >
              Dashboard
            </NavLink>
            {checkPermissions([
              "GET_TESTS",
              "GET_TESTS_PUBLISHED",
              "GET_TESTS_DRAFT",
              "GET_TESTS_INCUBATION",
            ]) ? (
              <NavLink
                to={handleTestRedirect() || "#"}
                className={({ isActive }) =>
                  `hover:text-white select-none py-[15px] ${
                    isActive ? "text-white border-b-[2px] font-medium" : ""
                  }`
                }
                onClick={() => {
                  setIsProfileDropdownOpen(false);
                }}
              >
                <span>Test Library</span>
              </NavLink>
            ) : null}
            {checkPermissions("GET_USERS") ? (
              <NavLink
                to={"/users"}
                className={({ isActive }) =>
                  `hover:text-white select-none py-[15px] ${
                    isActive ? "text-white border-b-[2px] font-medium" : ""
                  }`
                }
                onClick={() => {
                  setIsProfileDropdownOpen(false);
                }}
              >
                <span>Users</span>
              </NavLink>
            ) : null}
            {checkPermissions("GET_APPLICANTS") ? (
              <NavLink
                to={"/applicants"}
                className={({ isActive }) =>
                  `hover:text-white select-none py-[15px] ${
                    isActive ? "text-white border-b-[2px] font-medium" : ""
                  }`
                }
                onClick={() => {
                  setIsProfileDropdownOpen(false);
                }}
              >
                Applicants
              </NavLink>
            ) : null}
            {checkPermissions("GET_MICRODIMENSIONS") ? (
              <NavLink
                to={"/microdimensions"}
                className={({ isActive }) =>
                  `hover:text-white select-none py-[15px] ${
                    isActive ? "text-white border-b-[2px] font-medium" : ""
                  }`
                }
                onClick={() => {
                  setIsProfileDropdownOpen(false);
                }}
              >
                <span>Microdimensions</span>
              </NavLink>
            ) : null}
            {checkPermissions("GET_POSITIONS") ? (
              <NavLink
                to={"/positions"}
                className={({ isActive }) =>
                  `hover:text-white select-none py-[15px] ${
                    isActive ? "text-white border-b-[2px] font-medium" : ""
                  }`
                }
                onClick={() => {
                  setIsProfileDropdownOpen(false);
                }}
              >
                Positions
              </NavLink>
            ) : null}
            {checkPermissions("GET_COMPANY") ? (
              <NavLink
                to={"/companies"}
                className={({ isActive }) =>
                  `hover:text-white select-none py-[15px] ${
                    isActive ? "text-white border-b-[2px] font-medium" : ""
                  }`
                }
                onClick={() => {
                  setIsProfileDropdownOpen(false);
                }}
              >
                Companies
              </NavLink>
            ) : null}
            {true ? (
              <NavLink
                to={"/system-emails"}
                className={({ isActive }) =>
                  `hover:text-white select-none py-[15px] ${
                    isActive ? "text-white border-b-[2px] font-medium" : ""
                  }`
                }
                onClick={() => {
                  setIsProfileDropdownOpen(false);
                }}
              >
                Emails
              </NavLink>
            ) : null}
          </nav>

          <div className="relative flex items-center gap-5 ml-12">
            <img
              src={notificationIco}
              className="cursor-pointer select-none w-[25px] h-auto"
              alt="notificaton-ico"
            />
            {userData.loading || userData.processing || !userData.value ? (
              <Spinner classIcon={"w-[30px] h-[30px]"} />
            ) : (
              <>
                <div
                  className="cursor-pointer w-[30px] h-[30px]"
                  onClick={() => {
                    setIsProfileDropdownOpen((prevState) => !prevState);
                  }}
                >
                  <Avatar
                    content={`${userData.value.name} ${userData.value.surname}`}
                    avatar={userData.value.avatar}
                    size={30}
                  />
                </div>

                {isProfileDropdownOpen && (
                  <div
                    className="absolute top-12 right-0 z-50"
                    onMouseLeave={closeDropdownHandler}
                  >
                    <ProfileDropdown
                      img={userData.value.avatar}
                      closeDropdown={closeDropdownHandler}
                      contentName={`${userData.value.name} ${userData.value.surname}`}
                      role={userData.value.role.description}
                      fullName={`${userData.value.name} ${userData.value.middleName} ${userData.value.surname}`}
                      settingsLink={"/settings/general"}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminNavbar;
