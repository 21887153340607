import { type ReactNode, useEffect } from "react";
import AdminNavbar from "views/components/AdminNavbar/AdminNavbar";
import ClientNavbar from "../../components/ClientNavbar/ClientNavbar";
import { useAppDispatch, useAppSelector } from "../../../redux/reduxTypes";
import { useNavigate, useParams } from "react-router";
import { getCompanyOverview } from "../../features/company/reducer/company.actions";
import { useAuthorization } from "../../../common/hooks/useAuthorization";
import { clearCompanyOverview } from "../../features/company/reducer/company.reducer";

interface appLayoutType {
  children: ReactNode;
}

const AppLayout = ({ children }: appLayoutType) => {
  const key = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const companyOverview = useAppSelector(
    (state) => state.company.companyOverview
  );
  const { checkRoles } = useAuthorization();

  useEffect(() => {
    if (key.companyId && !companyOverview.value) {
      dispatch(
        getCompanyOverview({
          id: key.companyId,
          roleAdmin: checkRoles("zenhire"),
        })
      );
    }
  }, [key.companyId]);

  useEffect(() => {
    if (companyOverview?.value?.state === "SUSPENDED") {
      navigate(`/${key.companyId}/suspended`);
    }
  }, [companyOverview?.value]);

  useEffect(() => {
    if (!key.companyId && companyOverview) {
      dispatch(clearCompanyOverview());
    }
  }, [key, companyOverview]);

  return (
    <div className="app-layout flex flex-col h-screen">
      {checkRoles("zenhire") ? <AdminNavbar /> : null}

      {(companyOverview.value && key.companyId) || checkRoles("company") ? (
        <ClientNavbar
          companyData={companyOverview.value}
          idCompany={key.companyId as string}
        />
      ) : null}
      {children}
    </div>
  );
};

export default AppLayout;
