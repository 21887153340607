// ** Redux Imports
// import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import { createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import { http } from "common/api/http";
import { loginTypes, userDataType } from "../auth.types";
import { setToast } from "redux/alert";
import { fetchMe } from "./auth.services";
import { tokenTypes } from "common/types/api.types";
import { removeKey, storeKey } from "common/api/token";
import { publicHttp } from "common/api/public-http";
import {changeAuthPassword} from "./authentication";

export const loginUser = createAsyncThunk<userDataType, loginTypes, {}>(
  "authentication/login",
  async ({ email, password }, { dispatch, rejectWithValue }) => {
    try {
      const response: { data: tokenTypes } = await publicHttp.post(
        "/auth/login",
        {
          email,
          password,
        }
      );
      if (response.data?.access_token && response.data?.refresh_token)
        storeKey({
          access_token: response.data.access_token,
          refresh_token: response.data.refresh_token,
        });

      const response1 = await fetchMe();

      if(response?.data?.isPasswordChangedAfterInitialLogin) {
          dispatch(
              setToast({
                  message: "Welcome back!",
                  type: "success",
              })
          );
      }

      return response1.data as userDataType;
    } catch (err: any) {
      console.log(err);
      // dispatch(
      //   setToast({
      //     type: "error",
      //     message: "An error occured. Please try again later.",
      //   })
      // );
      return rejectWithValue(err.data.message);
    }
  }
);

export const forgotPassword = createAsyncThunk(
    "authentication/forgotPassword",
    async ({ email,password,verificationToken }:{email:string,password?:string,verificationToken?:string}, { dispatch, rejectWithValue }) => {
        try {
            const response = await publicHttp.post(
                password && verificationToken ? `/auth/reset-password?email=${email}&verificationToken=${verificationToken}` :
                    "/auth/forgot-password",
                password && verificationToken ? {
                    newPassword:password,
                } : {
                    email
                }
            );
            return response.data
        } catch (err: any) {
            return rejectWithValue(err.data.message);
        }
    }
);

export const getMe = createAsyncThunk("authentication/me", async () => {
  try {
    const response = await fetchMe();
    return response.data;
  } catch (err: any) {
    console.log("error", err);
    return err.response.data.message;
  }
});

export const updateMe = createAsyncThunk(
  "authentication/updateMe",
  async (
    {
      name,
      surname,
      middleName,
      avatar,
    }: {
      name?: string;
      surname?: string;
      middleName?: string;
      avatar?: string;
    },
    { dispatch }
  ) => {
    try {
      const response = await http.patch("/users/admins", {
        name,
        surname,
        middleName,
        avatar,
      });
      dispatch(
        setToast({
          message: "Successfully updated profile info.",
          type: "success",
        })
      );
      return response.data as any;
    } catch (err: any) {
      console.log(err);
      dispatch(
        setToast({
          message: "Error updating profile info.",
          type: "error",
        })
      );
      return err.data.message as any;
    }
  }
);

export const handleLogout = createAsyncThunk(
  "authentication/logout",
  async () => {
    try {
      const response = await http.post("/auth/logout");
      removeKey("zen_access_token");
      removeKey("zen_refresh_token");
      return response.data;
    } catch (err: any) {
      return err.response.data.message;
    }
  }
);

export const resetPassword = createAsyncThunk(
  "authentication/resetPassword",
  async (
    {
      oldPassword,
      newPassword,
      firstChange
    }: {
      oldPassword: string;
      newPassword: string;
      firstChange?: boolean
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await http.patch("/users/admins/password", {
        oldPassword:oldPassword,
        newPassword,
      });

      if(firstChange) {
          dispatch(changeAuthPassword())
          dispatch(
              setToast({
                  message: "Welcome!",
                  type: "success",
              })
          );
      } else {
          dispatch(
              setToast({
                  message: "You have successfully reset your password.",
                  type: "success",
              })
          );
      }

      return response.data as any;
    } catch (err: any) {
      dispatch(
        setToast({
          message: "Error reseting password.",
          type: "error",
        })
      );
      return rejectWithValue(err.data.message);
    }
  }
);

// export const registerUser = async (body) => {
//   return await http.post("/register", body)
// }

// export const refreshUser = createAsyncThunk(
//   "authentication/refreshUser",
//   async (id, { dispatch }) => {
//     try {
//       let response = await getMe()
//       let activeRole = response.data?.active_role?.role_name
//         ? response.data.active_role.role_name
//         : null

//       return { userData: response.data, selectedRole: activeRole }
//     } catch (err) {
//       console.log(err)
//       return err.response.data
//     }
//   }
// )

// export const updateUser = async (body) => {
//   return await http.post("/me", body)
// }

// export const resendEmail = async () => {

//   return await http.post("/email/verify/resend")
// }
// export const verifyEmail = async (token) => {
//   return await http.get(`/email/verify/${token}`)
// }
// export const validateOauth = async (type, token) => {

//   return await http.get(`/validate/${type}${token}`)
// }
// export const sendResetEmail = async (email) => {

//   return await http.post("/password/email", { email: email })
// }

// export const passwordReset = async (token, password, confirmPassword) => {

//   return await http.post("/password/reset", {
//     token: token,
//     password: password,
//     password_confirmation: confirmPassword
//   })
// }
