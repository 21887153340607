// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";
import { getRoutesPatternStructure } from "router/helpers/router.functions";
import { routes } from "router/routes";
import { routePatternType } from "router/routes/types";

interface alertType {
  structure: routePatternType[];
  currentPath: string;
  tree: {
    id: string;
    fullPattern: string;
    breadcrumbs: {
      path: string;
      title: string;
      level: number;
      loaded: boolean;
      pattern: string;
    }[];
  };
  history: { path: string }[];
  customHistory: {
    path: string;
    treeId: string;
    level: number;
    pattern: string;
  }[];
}

const initialState: alertType = {
  structure: getRoutesPatternStructure(routes),
  currentPath: window.location.pathname,
  tree: {
    id: "",
    fullPattern: "",
    breadcrumbs: [],
  },
  history: [],
  customHistory: [],
};

// export function handleToast(message) {
//   dispatch(setSystemAlert(message))
// }

export const globalSlice = createSlice({
  name: "navigation",
  initialState,

  reducers: {
    setCurrentPath: (state, { payload }) => {
      state.currentPath = payload;
    },
    pushNavigationHistoryRoute: (state, { payload }) => {
      state.history = [...state.history, payload];
    },

    pushCustomNavigationHistoryRoute: (state, { payload }) => {
      let treeId = state.tree.id;
      let history = state.customHistory;
      let lastRoute = history.length ? history[history.length - 1] : null;
      let routeBeforeLast =
        history.length >= 2 ? history[history.length - 2] : null;

      // handle going back
      if (routeBeforeLast && routeBeforeLast.path == payload.path) {
        history = history.slice(0, -2);
        console.log("history after back");
      } else if (lastRoute && payload.treeId === treeId) {
        // when a user climbs on a higher level in the same navigation tree
        // lower level routes from the same tree in the history will be removed
        if (payload.level < lastRoute.level) {
          for (let i = history.length - 1; i >= 0; i--) {
            if (payload.treeId !== history[i].treeId) {
              break;
            }
            if (payload.level <= history[i].level) {
              history.pop();
            }
          }
        }
        if (
          lastRoute.pattern === payload.pattern ||
          lastRoute.level === payload.level
        ) {
          // remove last route if new one is on the same level
          // or if it has the same pattern
          history = history.slice(0, -1);
        }
      }
      state.customHistory = [...history, payload];
    },
    setNavigationTree: (state, { payload }) => {
      state.tree = payload;
    },
    pushLoadedBreadcrumb: (state, { payload }) => {
      let breadcrumbs = state.tree.breadcrumbs;
      for (let i = 0; i < breadcrumbs.length; i++) {
        if (breadcrumbs[i].pattern === payload.pattern) {
          state.tree.breadcrumbs[i].loaded = true;
          break;
        }
      }
    },
    updateBreadcrumbs: (state, { payload }) => {
      state.tree.breadcrumbs = payload;
    },
    clearNavigation: (state) => {
      state = initialState;
    },
  },
  extraReducers: (builder) => {},
});

export const {
  pushNavigationHistoryRoute,
  pushCustomNavigationHistoryRoute,
  setNavigationTree,
  pushLoadedBreadcrumb,
  setCurrentPath,
  clearNavigation,
} = globalSlice.actions;

export default globalSlice.reducer;
