// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";
import {
  createTestQuestion,
  deleteTestQuestion,
  getTestContents,
  getTestOverview,
  updateTestOverview,
  updateTestQuestion,
  publishTest,
  incubateTest,
  draftTest,
  refreshTestOverview,
  getTestApplicants,
  deleteTest,
  archiveTest,
} from "./testSingle.actions";
import {
  type testSingleOverviewType,
  type testSingleQuestionsType,
  type testSingleQuestionsDeleteType,
  type testSingleQuestionType,
  testSingleApplicantsType,
} from "../testSingle.types";
import { getEmptyInput } from "../hooks/useQuestionEditorSlice";

interface TestSingleType {
  testSingleOverview: testSingleOverviewType;
  testSingleQuestions: testSingleQuestionsType;
  testSingleQuestionsDelete: testSingleQuestionsDeleteType;
  testSingleQuestion: testSingleQuestionType;
  applicants: testSingleApplicantsType;
}

const initialState: TestSingleType = {
  testSingleOverview: {
    value: null,
    processing: false,
    loading: false,
    error: false,
  },

  testSingleQuestions: {
    value: null,
    processing: false,
    loading: false,
    error: false,
  },
  testSingleQuestionsDelete: {
    value: null,
    processing: false,
    error: false,
  },
  testSingleQuestion: {
    value: null,
    index: null,
    state: null,
    processing: false,
    loading: false,
    error: false,
  },
  applicants: {
    value: null,
    processing: false,
    loading: false,
    error: false,
  },
};

export const testSingle = createSlice({
  name: "testSingle",
  initialState,
  reducers: {
    addTestQuestion: (state) => {
      state.testSingleQuestion = {
        ...initialState.testSingleQuestion,
        value: getEmptyInput(
          state.testSingleOverview.value?.template?.enum
            ? state.testSingleOverview.value?.template?.enum
            : ""
        ),
        state: "new",
      };
    },
    selectTestQuestion: (state, action) => {
      state.testSingleQuestion = {
        ...initialState.testSingleQuestion,
        value: action.payload.value,
        index: action.payload.index,
        state: "edit",
      };
    },
    duplicateTestQuestion: (state) => {
      state.testSingleQuestion = {
        ...state.testSingleQuestion,
        state: "duplicate",
      };
    },
    setTestQuestionDelete: (state, action) => {
      state.testSingleQuestionsDelete.value = action.payload;
    },
    clearTestQuestion: (state) => {
      state.testSingleQuestion = initialState.testSingleQuestion;
    },

    clearTestQuestionsDelete: (state) => {
      state.testSingleQuestionsDelete = initialState.testSingleQuestionsDelete;
    },
    clearTestSingleOverview: (state) => {
      state.testSingleOverview = initialState.testSingleOverview;
    },
    clearTestSingleContents: (state) => {
      state.testSingleQuestions = initialState.testSingleQuestions;
      state.testSingleQuestion = initialState.testSingleQuestion;
    },
    clearTestApplicants: (state) => {
      state.applicants = initialState.applicants;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTestOverview.pending, (state) => {
        state.testSingleOverview.loading = true;
      })
      .addCase(getTestOverview.fulfilled, (state, { payload }) => {
        state.testSingleOverview.value = payload;
        state.testSingleOverview.loading = false;
      })
      .addCase(refreshTestOverview.fulfilled, (state, { payload }) => {
        state.testSingleOverview.value = payload;
      })
      .addCase(getTestOverview.rejected, (state,payload) => {
        // @ts-ignore
        state.testSingleOverview.error = payload.payload?.message ? payload.payload?.message  : true;
        state.testSingleOverview.loading = false;
      })
      .addCase(updateTestOverview.pending, (state) => {
        state.testSingleOverview.processing = true;
      })
      .addCase(updateTestOverview.fulfilled, (state, { payload }) => {
        state.testSingleOverview.value = payload;
        state.testSingleOverview.processing = false;
      })
      .addCase(updateTestOverview.rejected, (state) => {
        state.testSingleOverview.error = true;
        state.testSingleOverview.processing = false;
      })
      .addCase(getTestContents.pending, (state) => {
        state.testSingleQuestions.processing = true;
      })
      .addCase(getTestContents.fulfilled, (state, { payload }) => {
        state.testSingleQuestions.value = payload;
        state.testSingleQuestions.processing = false;
      })
      .addCase(getTestContents.rejected, (state) => {
        state.testSingleQuestions.error = true;
        state.testSingleQuestions.processing = false;
      })
      .addCase(deleteTestQuestion.pending, (state) => {
        state.testSingleQuestionsDelete.processing = true;
      })
      .addCase(deleteTestQuestion.fulfilled, (state, { payload }) => {
        state.testSingleQuestionsDelete.processing = false;
        state.testSingleQuestionsDelete.value = null;
      })
      .addCase(deleteTestQuestion.rejected, (state) => {
        state.testSingleQuestionsDelete.error = true;
        state.testSingleQuestionsDelete.processing = false;
      })
      .addCase(createTestQuestion.pending, (state) => {
        state.testSingleQuestion.processing = true;
      })
      .addCase(createTestQuestion.fulfilled, (state, { payload }) => {
        state.testSingleQuestion.processing = false;
        state.testSingleQuestion = {
          ...initialState.testSingleQuestion,
          value: payload.value,
          index: payload.index,
          state: "edit",
        };
      })
      .addCase(createTestQuestion.rejected, (state) => {
        state.testSingleQuestion.error = true;
        state.testSingleQuestion.processing = false;
      })
      .addCase(updateTestQuestion.pending, (state) => {
        state.testSingleQuestion.processing = true;
      })
      .addCase(updateTestQuestion.fulfilled, (state, action) => {
        state.testSingleQuestion = {
          ...state.testSingleQuestion,
          processing: false,
          error: false,
          value: { ...state.testSingleQuestion.value, ...action.payload },
        };
      })
      .addCase(updateTestQuestion.rejected, (state) => {
        state.testSingleQuestion.error = true;
        state.testSingleQuestion.processing = false;
      })
      .addCase(publishTest.pending, (state) => {
        state.testSingleOverview.processing = true;
      })
      .addCase(publishTest.fulfilled, (state, action) => {
        state.testSingleOverview.processing = false;
      })
      .addCase(publishTest.rejected, (state) => {
        state.testSingleOverview.error = true;
        state.testSingleOverview.processing = false;
      })
      .addCase(incubateTest.pending, (state) => {
        state.testSingleOverview.processing = true;
      })
      .addCase(incubateTest.fulfilled, (state) => {
        state.testSingleOverview.processing = false;
      })
      .addCase(incubateTest.rejected, (state) => {
        state.testSingleOverview.error = true;
        state.testSingleOverview.processing = false;
      })
      .addCase(draftTest.pending, (state) => {
        state.testSingleOverview.processing = true;
      })
      .addCase(draftTest.fulfilled, (state) => {
        state.testSingleOverview.processing = false;
      })
      .addCase(draftTest.rejected, (state) => {
        state.testSingleOverview.error = true;
        state.testSingleOverview.processing = false;
      })
      .addCase(deleteTest.pending, (state) => {
        state.testSingleOverview.processing = true;
      })
      .addCase(deleteTest.fulfilled, (state) => {
        state.testSingleOverview.processing = false;
      })
      .addCase(deleteTest.rejected, (state) => {
        state.testSingleOverview.error = true;
        state.testSingleOverview.processing = false;
      })
      .addCase(archiveTest.pending, (state) => {
        state.testSingleOverview.processing = true;
      })
      .addCase(archiveTest.fulfilled, (state) => {
        state.testSingleOverview.processing = false;
      })
      .addCase(archiveTest.rejected, (state) => {
        state.testSingleOverview.error = true;
        state.testSingleOverview.processing = false;
      })
      .addCase(getTestApplicants.pending, (state) => {
        state.applicants.loading = true;
      })
      .addCase(getTestApplicants.fulfilled, (state, action) => {
        state.applicants.loading = false;
        state.applicants.value = action.payload;
      })
      .addCase(getTestApplicants.rejected, (state, { payload }) => {
        state.applicants.error = true;
        state.applicants.loading = false;
      });
  },
});

export const {
  clearTestSingleOverview,
  selectTestQuestion,
  addTestQuestion,
  duplicateTestQuestion,
  clearTestQuestion,
  clearTestQuestionsDelete,
  clearTestSingleContents,
  clearTestApplicants,
  setTestQuestionDelete,
} = testSingle.actions;

export default testSingle.reducer;
