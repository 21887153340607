import { type FC, lazy } from "react";
import { lazyRetry } from "common/utility/Utils";
import { type routeType } from "router/routes/types";
import { Navigate } from "react-router";

const TestSimulationApplicationWrapper: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () =>
        await import(
          "../wrappers/TestSimulationApplicationWrapper/TestSimulationApplicationWrapper"
        )
    )
);
const PositionSimulationApplicationWrapper: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () =>
        await import(
          "../wrappers/PositionSimulationApplicationWrapper/PositionSimulationApplicationWrapper"
        )
    )
);
const ApplicationWrapper: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () =>
        await import("../wrappers/ApplicationWrapper/ApplicationWrapper")
    )
);

const ApplicationController: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () =>
        await import("../pages/ApplicationController/ApplicationController")
    )
);

const ApplicationError: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () => await import("../pages/ApplicationError/ApplicationError")
    )
);
const ApplicationSuccess: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () => await import("../pages/ApplicationSuccess/ApplicationSuccess")
    )
);

const TestSimulationDemoWrapper: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () =>
        await import(
          "../wrappers/TestSimulationDemoWrapper/TestSimulationDemoWrapper"
        )
    )
);
const TestSimulationApplication: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () =>
        await import(
          "../pages/TestSimulationApplication/TestSimulationApplication"
        )
    )
);
const ApplicationModulePreview: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () =>
        await import(
          "views/features/position/moduleSpecificComponents/ApplicationModulePreview/ApplicationModulePreview"
        )
    )
);
const ApplicationModuleSimulation: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () =>
        await import(
          "views/features/position/moduleSpecificComponents/ApplicationModuleSimulation/ApplicationModuleSimulation"
        )
    )
);
const TestSimulationApplicationVerify: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () =>
        await import(
          "../pages/TestSimulationApplicationVerify/TestSimulationApplicationVerify"
        )
    )
);
const SessionExpired: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () => await import("../pages/SessionExpired/SessionExpired")
    )
);
const ApplicationSimulationVerifyController: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () =>
        await import(
          "../pages/ApplicationSimulationVerifyController/ApplicationSimulationVerifyController"
        )
    )
);
const PreinterviewReportPage: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () =>
        await import("../pages/PreinterviewReportPage/PreinterviewReportPage")
    )
);
const QualifyingQuestionsReportPage: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () =>
        await import(
          "../pages/QualifyingQuestionsReportPage/QualifyingQuestionsReportPage"
        )
    )
);
const PrivacyPolicy: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () => await import("../pages/PrivacyPolicy/PrivacyPolicy")
    )
);

const ApplicationFeedback: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () =>
        await import("../pages/ApplicationFeedback/ApplicationFeedback")
    )
);

const applicationRoutes: routeType[] = [
  // SINGLE TEST APPLICATION AND DEMO ROUTES
  {
    path: "/test/:id/simulation",
    element: <TestSimulationApplicationWrapper />,
    meta: {
      layout: "blank",
      type: "page",
      public: true,
    },
    children: [
      {
        path: "",
        element: <Navigate to="demo" />,
        meta: {
          layout: "blank",
          type: "page",
          public: true,
        },
      },
      {
        path: "demo",
        element: <TestSimulationDemoWrapper isDemo={true} />,
        meta: {
          title: "Test demo",
          layout: "blank",
          type: "page",
          public: true,
        },
      },
      {
        path: "apply",
        element: <TestSimulationApplication />,
        meta: {
          title: "Apply",
          layout: "blank",
          type: "page",
          public: true,
        },
      },
      {
        path: "verify",
        element: <TestSimulationApplicationVerify />,
        meta: {
          title: "Verify",
          layout: "blank",
          type: "page",
          public: true,
        },
      },
    ],
  },

  // POSITION APPLICATION AND DEMO ROUTES
  {
    path: "/position/:id/simulation",
    element: <PositionSimulationApplicationWrapper />,
    meta: {
      layout: "blank",
      type: "page",
      public: true,
    },
    children: [
      {
        path: "",
        element: <Navigate to="apply" />,
        meta: {
          layout: "blank",
          type: "page",
          public: true,
        },
      },
      {
        path: "apply",
        element: <ApplicationModulePreview editable={false} preview={false} />,
        meta: {
          title: "Apply",
          layout: "blank",
          type: "page",
          public: true,
        },
      },
      {
        path: "apply/edit",
        element: <ApplicationModulePreview editable={true} preview={true} />,
        permissions: "UPDATE_POSITION",
        meta: {
          title: "Application Content Edit",
          layout: "blank",
          type: "page",
          private: true,
        },
      },
      {
        path: "apply/preview",
        element: <ApplicationModulePreview editable={false} preview={true} />,
        meta: {
          title: "Job Post Preview",
          layout: "blank",
          type: "page",
          private: true,
        },
      },
      {
        path: "apply/form",
        element: <ApplicationModuleSimulation />,
        meta: {
          title: "Form",
          layout: "blank",
          type: "page",
          public: true,
        },
      },
      {
        path: "apply/success",
        element: <ApplicationSuccess />,
        meta: {
          layout: "blank",
          type: "page",
          title: "Application Success",
          public: true,
        },
      },
      {
        path: "apply/error",
        element: <ApplicationError />,
        meta: {
          layout: "blank",
          type: "page",
          title: "Application Error",
          public: true,
        },
      },
    ],
  },

  // APPLICATION ROUTES
  {
    path: "/application/:id/verify",
    element: <ApplicationSimulationVerifyController />,
    meta: {
      title: "Verification",
      layout: "blank",
      type: "page",
      public: true,
    },
  },
  {
    path: "/application/:id",
    element: <ApplicationWrapper />,
    meta: {
      layout: "blank",
      type: "page",
      public: true,
    },
    children: [
      {
        path: "",
        element: <ApplicationController />,
        meta: {
          title: "Simulation",
          layout: "blank",
          type: "page",
          public: true,
        },
      },
    ],
  },

  // APPLICATION FEEDBACK
  {
    path: "/application/:applicationId/feedback",
    element: <ApplicationFeedback />,
    meta: {
      title: "Rejected Feedback",
      layout: "blank",
      type: "page",
      public: true,
    },
  },

  // RESULTS ROUTES
  {
    path: "/application/:applicationId/hiringFlow/:hiringFlowId/step/:stepId/test/:testId/results",
    element: <PreinterviewReportPage />,
    meta: {
      layout: "blank",
      title: "Results page",
      type: "page",
      public: true,
    },
  },
  {
    path: "/application/:applicationId/hiringFlow/:hiringFlowId/step/:stepId/test/:testId/qa-test/results/public",
    element: <QualifyingQuestionsReportPage />,
    meta: {
      layout: "blank",
      title: "Results page",
      type: "page",
      public: true,
    },
  },

  // EXCEPTION ROUTES
  {
    path: "/expired",
    element: <SessionExpired />,
    meta: {
      layout: "blank",
      title: "Session expired",
      type: "page",
      public: true,
    },
  },

  // PRIVACY POLICY
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
    meta: {
      layout: "blank",
      title: "Privacy policy",
      type: "page",
      public: true,
    },
  },
];

export default applicationRoutes;
