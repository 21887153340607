import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchCompany } from "./company.services";
import { http } from "../../../../common/api/http";
import { setToast } from "../../../../redux/alert";
import { RootState } from "../../../../redux/store";
import axios from "axios";
export const getCompanyOverview = createAsyncThunk<
  any,
  { id: string; roleAdmin?: boolean },
  { state: RootState }
>("company/getCompanyOverview", async ({ id, roleAdmin }, { getState }) => {
  try {
    const state = getState();
    if (!roleAdmin) {
      return state?.authentication?.userData?.value?.company;
    } else {
      const response = await fetchCompany(id);
      return response.data;
    }
  } catch (err: any) {
    return err.response.data;
  }
});

export const refreshCompanyOverview = createAsyncThunk(
  "company/refreshCompanyOverview",
  async ({ id }: { id: string }) => {
    try {
      const response = await fetchCompany(id);
      return response.data;
    } catch (err: any) {
      return err.response.data;
    }
  }
);

type companyDataType = {
  id: string;
  name?: string;
  address?: string;
  websiteUrl?: string;
  description?: string;
  ourVisionDescription?: string;
  ourMissionDescription?: string;
  color?: string;
  headerColor?: string;
  enableHeaderColor?: boolean;
  linkColor?: string;
  logo?: string;
  headerLogo?: string;
  enableHeaderLogo?: boolean;
  privacyPolicy?: string;
};
export const updateCompanyInfo = createAsyncThunk(
  "companySingle/updateCompanyInfoData",
  async (data: companyDataType, { dispatch }) => {
    try {
      const response = await http.patch(`/companies/${data?.id}`, data);
      dispatch(refreshCompanyOverview({ id: data?.id }));
      dispatch(
        setToast({
          message: "Successfully updated the company.",
          type: "success",
        })
      );
      return response.data;
    } catch (err: any) {
      dispatch(
        setToast({
          message: "Error updating the company. Please try again later.",
          type: "error",
        })
      );
      return err.response.data;
    }
  }
);

export const initiateEmailAddress = createAsyncThunk(
  "company/initiateEmailAddress",
  async (
    {
      id,
      data,
    }: {
      id: string;
      data: {
        newEmail: string;
        address: string;
        state: string;
        city: string;
        country: string;
        zip: string;
      };
    },
    { dispatch }
  ) => {
    try {
      const response = await http.post(
        `/companies/${id}/verified-sender`,
        data
      );
      dispatch(
        setToast({
          message: "Successfully submited request",
          type: "success",
        })
      );

      return response.data;
    } catch (err: any) {
      dispatch(
        setToast({
          message: "Email address already exists",
          type: "error",
        })
      );

      return err.response.data;
    }
  }
);

export const resendEmailAddressChangeRequest = createAsyncThunk(
  "company/resendEmailAddressChangeRequest",
  async (
    {
      id,
    }: {
      id: string;
    },
    { dispatch }
  ) => {
    try {
      const response = await http.post(
        `/companies/${id}/verified-sender/resend`
      );
      dispatch(
        setToast({
          message: "Successfully resended request",
          type: "success",
        })
      );

      return response.data;
    } catch (err: any) {
      dispatch(
        setToast({
          message: "Error resending request",
          type: "error",
        })
      );

      return err.response.data;
    }
  }
);

export const handleCustomEmailAddressState = createAsyncThunk(
  "company/handleCustomEmailAddressState",
  async (
    {
      id,
      state,
    }: {
      id: string;
      state: boolean;
    },
    { dispatch }
  ) => {
    try {
      const response = await http.patch(
        `/companies/${id}/verified-sender/${state ? "enable" : "disable"}`
      );
      dispatch(refreshCompanyOverview({ id: id }));

      dispatch(
        setToast({
          message: `Custom company email turned ${state ? "on" : "off"}`,
          type: "success",
        })
      );

      return response.data;
    } catch (err: any) {
      dispatch(
        setToast({
          message: err.message,
          type: "error",
        })
      );

      return err.response.data;
    }
  }
);

export const getLocation = createAsyncThunk(
  "company/getLocation",
  async ({ value }: { value: string }) => {
    try {
      // mifflintown
      let url = `https://api.radar.io/v1/search/autocomplete?query=${value}&limit=6`;

      let options = {
        headers: {
          Authorization: "prj_test_pk_edd1c8544f2c36a10b5d96225ab4b63bd47af8b9",
          Accept: "application/json",
        },
      };

      const response = await axios.get(
        // production link
        url,
        options
      );

      return response.data.addresses;
    } catch (err: any) {
      return err.response.data;
    }
  }
);
