// ** Redux Imports
import { createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import { http } from "common/api/http";
import { setToast } from "redux/alert";
import { RootState } from "redux/store";
import { errorRequestType } from "../../../../common/types/data.types";
import { updateSystemEmailTemplateDesign } from "./systemEmails.reducer";

export const getSystemEmailTemplate = createAsyncThunk<
  any,
  { emailType: string },
  { state: RootState }
>("position/getSystemEmailTemplate", async ({ emailType }, { dispatch }) => {
  try {
    const response = await http.get(`system/email-template/${emailType}`);

    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
});

export const saveSystemEmailTemplate = createAsyncThunk<
  any,
  { emailType: string; body: { html: string; json: any } },
  { state: RootState }
>(
  "position/saveSystemEmailTemplate",
  async ({ emailType, body }, { dispatch }) => {
    try {
      const response = await http.put(
        `system/email-template/${emailType}`,
        body
      );

      dispatch(updateSystemEmailTemplateDesign(body));

      dispatch(
        setToast({
          message: `Successfully updated email template`,
          type: "success",
        })
      );

      return response.data;
    } catch (err: any) {
      return err.response.data;
    }
  }
);
