import { useEffect, useMemo, useState } from "react";
import {
  closedQuestionInput,
  openQuestionInput,
  preintrviewQuestionInput,
  defaultQuestionInput,
  initClosedConfig,
  typingSpeedInput,
  statementScaleInput,
} from "../testSingle.constants";
import { useAppDispatch, useAppSelector } from "redux/reduxTypes";
import { testQuestionType } from "../testSingle.types";
import { isStringEmpty } from "common/utility/Utils";
import {
  createTestQuestion,
  updateTestQuestion,
} from "../reducer/testSingle.actions";
import { emptyTextEditorState } from "views/components/TextEditor/sampleText";
import { toast } from "react-hot-toast";
import { checkTextEditorContent } from "views/components/TextEditor/TextEditor";
import { ReactComponent as CloseIco } from "assets/icons/close-ico-green.svg";
import { setToast } from "redux/alert";

export function getEmptyInput(type: string) {
  switch (type) {
    case "OPEN_QUESTIONS":
      return openQuestionInput;
    case "CLOSED_QUESTIONS":
      return closedQuestionInput;
    case "PRE_INTERVIEW": // preinterview
      return preintrviewQuestionInput;
    case "TYPING_SPEED": // typing speed
      return typingSpeedInput;
    case "STATEMENT_SCALE":
      return statementScaleInput;
    case "QUALIFYING_QUESTIONS":
      return closedQuestionInput;
    default:
      return defaultQuestionInput;
  }
}

function renderSpecificConfig(type: string, questionInput: testQuestionType) {
  switch (type) {
    case "OPEN_QUESTIONS":
      return {
        openConfig: {
          charLimit: questionInput.openConfig?.charLimit || 0,
        },
      };
    case "CLOSED_QUESTIONS":
      return {
        closedConfig: questionInput?.closedConfig
          ? {
              type: questionInput.closedConfig?.type || "text",
              hasMultipleAnswers:
                questionInput.closedConfig?.hasMultipleAnswers,
              displayStyle:
                questionInput.closedConfig?.displayStyle || "vertical",
              negativePoints: questionInput.closedConfig?.negativePoints || 0,
              options:
                questionInput.closedConfig?.options.map((o) => {
                  return {
                    ...o,
                    defaultText:
                      questionInput.closedConfig?.type === "text"
                        ? o.text
                        : o.imageUrl || "",
                  };
                }) || initClosedConfig.options,
            }
          : initClosedConfig,
      };

    case "PRE_INTERVIEW":
      return {
        preinterviewConfig: {
          preparationTime:
            questionInput.preinterviewConfig?.preparationTime || 0,
        },
      };

    case "STATEMENT_SCALE":
      return {
        statementScaleTestConfig: {
          isScaleReversed: false,
        },
      };

    case "QUALIFYING_QUESTIONS":
      return {
        closedConfig: questionInput?.closedConfig
          ? {
              type: questionInput.closedConfig?.type || "text",
              hasMultipleAnswers:
                questionInput.closedConfig?.hasMultipleAnswers,
              displayStyle:
                questionInput.closedConfig?.displayStyle || "vertical",
              negativePoints: 0,
              options:
                questionInput.closedConfig?.options.map((o) => {
                  return {
                    ...o,
                    defaultText:
                      questionInput.closedConfig?.type === "text"
                        ? o.text
                        : o.imageUrl || "",
                  };
                }) || initClosedConfig.options,
            }
          : initClosedConfig,
      };
    default:
      return {};
  }
}

function validateQuestionInput(
  type: string,
  questionInput: testQuestionType,
  timerType?: string
) {
  switch (type) {
    case "OPEN_QUESTIONS":
      var checkQuestionInput = checkTextEditorContent(questionInput.question);
      return (
        !isStringEmpty(questionInput.question) &&
        questionInput.question !== emptyTextEditorState &&
        (checkQuestionInput.hasText || checkQuestionInput.hasImage) &&
        questionInput.importance &&
        questionInput.microDims.length &&
        questionInput.openConfig?.charLimit &&
        (timerType === "per_test" ||
          (timerType === "per_question" && questionInput.timeLimit))
      );
    case "CLOSED_QUESTIONS":
      var checkQuestionInput = checkTextEditorContent(questionInput.question);

      return (
        !isStringEmpty(questionInput.question) &&
        questionInput.question !== emptyTextEditorState &&
        (checkQuestionInput.hasText || checkQuestionInput.hasImage) &&
        questionInput.microDims.length &&
        questionInput.importance &&
        (timerType === "per_test" ||
          (timerType === "per_question" && questionInput.timeLimit)) &&
        questionInput.closedConfig?.options.length &&
        !questionInput.closedConfig.options.some((v) => {
          let checkAnswersImage = v.imageUrl?.length;
          let checkAnswersInput = checkTextEditorContent(v.text);

          return questionInput?.closedConfig?.type === "text"
            ? isStringEmpty(v.text) ||
                v.text === emptyTextEditorState ||
                (!checkAnswersInput.hasImage && !checkAnswersInput.hasText)
            : !checkAnswersImage;
        }) &&
        questionInput.closedConfig.options.some((v) => v.isCorrect)
      );
    case "PRE_INTERVIEW": // preinterview
      var checkQuestionInput = checkTextEditorContent(questionInput.question);

      return (
        !isStringEmpty(questionInput.question) &&
        questionInput.question !== emptyTextEditorState &&
        (checkQuestionInput.hasText || checkQuestionInput.hasImage) &&
        // questionInput.microDims.length &&
        questionInput.importance &&
        questionInput.timeLimit
      );
    case "TYPING_SPEED": // typing speed
      return !isStringEmpty(questionInput.question) && questionInput.timeLimit;
    case "STATEMENT_SCALE":
      var checkQuestionInput = checkTextEditorContent(questionInput.question);

      return (
        !isStringEmpty(questionInput.question) &&
        questionInput.question !== emptyTextEditorState &&
        (checkQuestionInput.hasText || checkQuestionInput.hasImage) &&
        questionInput.microDims.length &&
        timerType === "per_test"
      );
    case "QUALIFYING_QUESTIONS":
      var checkQuestionInput = checkTextEditorContent(questionInput.question);

      return (
        !isStringEmpty(questionInput.question) &&
        questionInput.question !== emptyTextEditorState &&
        (checkQuestionInput.hasText || checkQuestionInput.hasImage) &&
        questionInput.closedConfig?.options.length &&
        !questionInput.closedConfig.options.some((v) => {
          let checkAnswersImage = v.imageUrl?.length;
          let checkAnswersInput = checkTextEditorContent(v.text);

          return questionInput?.closedConfig?.type === "text"
            ? isStringEmpty(v.text) ||
                v.text === emptyTextEditorState ||
                (!checkAnswersInput.hasImage && !checkAnswersInput.hasText)
            : !checkAnswersImage;
        }) &&
        questionInput.closedConfig.options.some((v) => v.isCorrect)
      );
    default:
      return false;
  }
}

const useQuestionEditorSlice = ({ type }: { type: string }) => {
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(true);

  const testOverview = useAppSelector(
    (state) => state.testSingle.testSingleOverview
  );
  const testQuestion = useAppSelector(
    (state) => state.testSingle.testSingleQuestion
  );

  const [questionInput, setQuestionInput] = useState(renderEmptyInput());
  const [saveError, setSaveError] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);

  const hasChangesTemp = useMemo(() => {
    // const startInput=
  }, [questionInput]);

  function handleSaveQuestion() {
    if (
      testOverview.value &&
      validateQuestionInput(type, questionInput, testOverview.value?.timerType)
    ) {
      if (testQuestion.state === "edit") {
        dispatch(
          updateTestQuestion({
            ...questionInput,
            microDimIds: questionInput.microDims.map((m) => m._id),
            testId: testOverview.value._id,
            ...(type === "QUALIFYING_QUESTIONS" && {
              importance: 1,
            }),
          })
        );
      } else {
        dispatch(
          createTestQuestion({
            ...questionInput,
            microDimIds: questionInput.microDims.map((m) => m._id),
            testId: testOverview.value._id,
            ...(type === "QUALIFYING_QUESTIONS" && {
              importance: 1,
            }),
          })
        );
      }
    } else {
      dispatch(
        setToast({
          type: "error",
          message:
            "In order to save the question, you need to fill all required fields.",
        })
      );

      setSaveError(true);
    }
  }

  function updateQuestionInput(input: testQuestionType) {
    setHasChanges(true);
    setQuestionInput({ ...questionInput, ...input });
  }

  function renderExistingInput(question: testQuestionType) {
    var { openConfig, closedConfig, preinterviewConfig } = renderSpecificConfig(
      type,
      question
    );
    return {
      ...question,
      _id: question._id,
      question: question.question,
      importance: question.importance,
      timeLimit: question.timeLimit,
      isInDemo: question.isInDemo,
      microDims: question?.microDims?.length
        ? question.microDims
        : question?.microDimIds?.length
        ? question?.microDimIds
        : [],
      openConfig,
      closedConfig,
      preinterviewConfig,
    };
  }

  function renderEmptyInput() {
    var newInput = getEmptyInput(type);

    return {
      ...newInput,
      microDims: testOverview.value?.microDims?.length
        ? testOverview.value.microDims.filter((v: any) => v.required)
        : [],
    };
  }

  function handleInitInput() {
    setLoading(true);
    if (
      (testQuestion.state === "duplicate" || testQuestion.state === "edit") &&
      testQuestion.value?._id
    ) {
      setQuestionInput(renderExistingInput(testQuestion.value));
    } else {
      setTimeout(() => {
        setQuestionInput(renderEmptyInput());
      }, 100);
    }
    setLoading(false);
    setHasChanges(false);
    setSaveError(false);
  }

  function handleDiscardChanges() {
    setLoading(true);
    setTimeout(() => {
      handleInitInput();
    }, 100);
  }

  useEffect(() => {
    handleInitInput();
  }, [testQuestion.value, testQuestion.state]);

  useEffect(() => {
    if (testQuestion.error) {
      setSaveError(true);
    }
  }, [testQuestion.error]);

  return {
    loading,
    questionInput,
    hasChanges,
    saveError,
    handleDiscardChanges,
    updateQuestionInput,
    handleSaveQuestion,
  };
};

export default useQuestionEditorSlice;
