// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";
import {
  getSystemEmailTemplate,
  saveSystemEmailTemplate,
} from "./systemEmails.actions";
import { errorRequestType } from "common/types/data.types";

type systemEmailsType = {
  systemEmailTemplate: {
    value: any;
    loading: boolean;
    processing: boolean;
    error: errorRequestType;
  };
};

const initialState: systemEmailsType = {
  systemEmailTemplate: {
    value: null,
    loading: false,
    processing: false,
    error: {
      isError: false,
      message: "",
      type: "",
    },
  },
};

export const systemEmail = createSlice({
  name: "systemEmail",
  initialState,
  reducers: {
    updateSystemEmailTemplateDesign: (state, { payload }) => {
      state.systemEmailTemplate.value = {
        ...state.systemEmailTemplate.value,
        html: payload.html,
        json: payload.json,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSystemEmailTemplate.pending, (state) => {
        state.systemEmailTemplate.processing = true;
      })
      .addCase(getSystemEmailTemplate.fulfilled, (state, { payload }) => {
        state.systemEmailTemplate.processing = false;
        state.systemEmailTemplate.value = payload;
      })
      .addCase(getSystemEmailTemplate.rejected, (state) => {
        state.systemEmailTemplate.error = { isError: true, message: "" };
        state.systemEmailTemplate.loading = false;
      })
      .addCase(saveSystemEmailTemplate.pending, (state) => {
        state.systemEmailTemplate.processing = true;
      })
      .addCase(saveSystemEmailTemplate.fulfilled, (state, { payload }) => {
        state.systemEmailTemplate.processing = false;
        // state.emailTemplate.value = payload;
      })
      .addCase(saveSystemEmailTemplate.rejected, (state) => {
        state.systemEmailTemplate.error = { isError: true, message: "" };
        state.systemEmailTemplate.processing = false;
      });
  },
});

export const { updateSystemEmailTemplateDesign } = systemEmail.actions;

export default systemEmail.reducer;
