import { createAsyncThunk, nanoid } from "@reduxjs/toolkit";
// ** Axios Imports
import { http } from "common/api/http";
import { setToast } from "redux/alert";

export const downloadPdfZipReports = createAsyncThunk(
  "exception/downloadPdfZipReports",
  async ({ path }: { path: string }, { dispatch }) => {
    try {
      const response = await http.get(`system/pdf-report-zip/${path}`);

      if (response.data.url) {
        const nanoId = nanoid();

        const link = document.createElement("a");
        link.href = response.data.url;
        link.download = `${nanoId}-reports.zip`;

        document.body.appendChild(link);

        link.click();
        link.remove();
      }

      return response.data;
    } catch (err: any) {
      dispatch(
        setToast({
          type: "error",
          message: "Download Error",
        })
      );
      return err.response.data.message;
    }
  }
);
